import { HStack, IconButton, StackProps, Text, VStack } from "@chakra-ui/react";
import { IUnit } from "services/resources/firebase/unit/types.d";
import { RxCross2 } from "react-icons/rx";
import { zubeColors } from "styles/colors";

interface ISelectedUnitsProps extends StackProps {
  selectedUnits: IUnit[];
  handleOnUnitRemove: (unitId: string) => void;
}

export const SelectedUnits: React.FC<ISelectedUnitsProps> = (
  props
): JSX.Element => {
  const { selectedUnits, handleOnUnitRemove, ...rest } = props;

  return (
    <VStack alignItems="flex-start" {...rest}>
      {selectedUnits.map((unit) => (
        <HStack key={unit.id} rounded="full" bg={zubeColors.zubePurple.dark}>
          <Text pl={5} color="white">
            {unit.plates}
          </Text>
          <IconButton
            aria-label="remove-unit"
            icon={<RxCross2 color="white" />}
            onClick={() => handleOnUnitRemove(unit.id)}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
        </HStack>
      ))}
    </VStack>
  );
};
