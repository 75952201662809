import { initializeApp } from "firebase/app";
import {
  type ApplicationVerifier,
  type AuthCredential,
  createUserWithEmailAndPassword,
  initializeAuth,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { type IAxiosResponse } from "services/types.d";

import { appConfig } from "../firebase-service";
import { firebaseInstance } from "../instance";
import { type IUserSignupPayload } from "../user/types.d";
import { type IBaseAuthentication, type IOtpResponse } from "./types.d";

export const auth = initializeAuth(initializeApp(appConfig));

const AUTHENTICATION_API_URL = "/authenticationAPI";

export const authResources = {
  anonymouslyLogIn: () => signInAnonymously(auth),

  deleteAccount: (
    email: string,
    token: string
  ): Promise<IAxiosResponse<{ token: string }>> =>
    firebaseInstance.delete(`${AUTHENTICATION_API_URL}/user`, {
      headers: { Authorization: token },
      params: { email },
    }),

  generateCustomToken: (
    storedUid: string,
    token: string
  ): Promise<IAxiosResponse<{ token: string }>> =>
    firebaseInstance.post(
      `${AUTHENTICATION_API_URL}/custom`,
      {},
      { headers: { Authorization: token }, params: { uid: storedUid } }
    ),

  linkPhoneNumberToUser: (
    uid: string,
    phoneNumber: string,
    token: string
  ): Promise<IAxiosResponse<{ customToken: string }>> =>
    firebaseInstance.patch(
      `${AUTHENTICATION_API_URL}/user/phone-number`,
      { phoneNumber },
      { headers: { Authorization: token }, params: { uid } }
    ),

  logInWithCustomToken: (customToken: string) =>
    signInWithCustomToken(auth, customToken),

  updateEmail: (
    uid: string,
    email: string,
    token: string
  ): Promise<IAxiosResponse<{ customToken: string }>> =>
    firebaseInstance.patch(
      `${AUTHENTICATION_API_URL}/user/email`,
      { email },
      { headers: { Authorization: token }, params: { uid } }
    ),

  updatePassword: (uid: string, password: string, token: string) =>
    firebaseInstance.patch(
      `${AUTHENTICATION_API_URL}/user/password`,
      { password },
      { headers: { Authorization: token }, params: { uid } }
    ),

  getUidFromEmail: (
    email: string,
    token: string
  ): Promise<IAxiosResponse<{ uid: string }>> =>
    firebaseInstance.get(`${AUTHENTICATION_API_URL}/uid-from-email`, {
      headers: { Authorization: token },
      params: { email },
    }),

  logInWithEmailAndPassword: (
    signUpPayload: Partial<IUserSignupPayload> | IBaseAuthentication
  ) =>
    signInWithEmailAndPassword(
      auth,
      signUpPayload.email as string,
      signUpPayload.password as string
    ),

  logInWithEmailAndPasswordCredential: (credential: AuthCredential) =>
    signInWithCredential(auth, credential),

  logInWithPhoneNumber: (phoneNumber: string, verifier: ApplicationVerifier) =>
    signInWithPhoneNumber(auth, `+52${phoneNumber}`, verifier),

  resetPassword: (email: string) => sendPasswordResetEmail(auth, email),

  sendOtpSMS: (
    phoneNumber: string,
    token: string
  ): Promise<IAxiosResponse<IOtpResponse>> =>
    firebaseInstance.post(
      `${AUTHENTICATION_API_URL}/sms/otp`,
      {},
      { headers: { Authorization: token }, params: { phoneNumber } }
    ),

  signOut: () => signOut(auth),

  signUpWithEmail: (
    uid: string,
    email: string,
    token: string
  ): Promise<IAxiosResponse<{ customToken: string }>> =>
    firebaseInstance.patch(
      `${AUTHENTICATION_API_URL}/user/email`,
      { email },
      { headers: { Authorization: token }, params: { uid } }
    ),

  signUpWithEmailAndPassword: (signUpPayload: Partial<IUserSignupPayload>) =>
    createUserWithEmailAndPassword(
      auth,
      signUpPayload.email as string,
      signUpPayload.password as string
    ),

  verifyOtp: (
    phoneNumber: string,
    otpCode: string,
    token: string
  ): Promise<IAxiosResponse<IOtpResponse>> =>
    firebaseInstance.post(
      `${AUTHENTICATION_API_URL}/otp/verify`,
      {},
      { headers: { Authorization: token }, params: { otpCode, phoneNumber } }
    ),
};
