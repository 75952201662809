import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { IZubeModalProps, ZubeModal } from "../ZubeModal";
import { zubeColors } from "styles/colors";

interface IConfirmationModalProps
  extends Omit<IZubeModalProps, "title" | "children"> {
  onAccept: () => void;
  onDecline: () => void;
  label: string;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = (props) => {
  const { onAccept, onDecline, label, ...rest } = props;

  return (
    <ZubeModal title="Confirmación" {...rest}>
      <VStack w="100%" h="100%">
        <Text px={3} textAlign="center" fontWeight="bold">
          {label}
        </Text>
        <HStack w="100%" justifyContent="center" py={3}>
          <Button
            color="white"
            rounded="full"
            onClick={onAccept}
            bg={zubeColors.adminRed}
          >
            Confirmar
          </Button>
          <Button
            color="white"
            rounded="full"
            onClick={onDecline}
            bg={zubeColors.zubeGreen}
          >
            Cancelar
          </Button>
        </HStack>
      </VStack>
    </ZubeModal>
  );
};
