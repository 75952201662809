import { Box, Button, Circle, HStack, Text, VStack } from "@chakra-ui/react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import {
  DEFAULT_MAP_SETTINGS,
  PRIVATE_DRIVERS_PARTNER,
} from "helpers/constants/data-constants";
import { MAPS_API_KEY } from "helpers/constants/string-contants";
import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "redux-service/slices";
import { partnerResources } from "services/resources/firebase/partner";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { IUser, UserTypeEnum } from "services/resources/firebase/user/types.d";
import { zubeColors } from "styles/colors";
import { PartnerSelector } from "./components/PartnerSelector";
import { DashboardTable, LoadingModal } from "components/elements";
import { userResources } from "services/resources/firebase/user";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { ColumnDef } from "@tanstack/react-table";
import { ZubeMarker } from "./components/ZubeMarker";
import { IMapSettings, MapDriverTypeEnum } from "./types.d";
import { journeyResources } from "services/resources/firebase/journey";
import { IJourney } from "services/resources/firebase/journey/types.d";
import { DriverTypeSelector } from "./components/DriverTypeSelector";
import {
  getDriverJourneyStatus,
  getStatusColor,
} from "./helpers/string-helpers";
import { IDriverWithCurrentUnit } from "services/resources-recipes/types.d";
import { getQueriedDriversWithTheirCurrentUnits } from "services/resources-recipes/units";
import {
  getFilteredOutDriversWithTheirCurrentUnits,
  getPartnerActiveDriversWithTheirCurrentUnitsNotInJourney,
  getPartnerDriversWithTheirCurrentUnitsInJourney,
  getPartnerUnactiveDriversWithTheirCurrentUnitsNotInJourney,
} from "./helpers/data-helpers";

export const Map: React.FC = (): JSX.Element => {
  const {
    token,
    type,
    isAdmin: isUserAdminOnDb,
    organization,
  } = useSelector(getUser);
  const navigate = useNavigate();

  const isAdmin = type === UserTypeEnum.ADMIN || isUserAdminOnDb;
  const isPartner = type === UserTypeEnum.PARTNER;

  const STATE_DEFAULT_MAP_SETTINGS = {
    center: {
      latitude: DEFAULT_MAP_SETTINGS.center.lat,
      longitude: DEFAULT_MAP_SETTINGS.center.lng,
    },
    zoom: DEFAULT_MAP_SETTINGS.zoom,
  };

  const [partners, setPartners] = useState<IPartner[]>([
    PRIVATE_DRIVERS_PARTNER,
  ]);
  const [
    partnerDriversWithTheirCurrentUnits,
    setPartnerDriversWithTheirCurrentUnits,
  ] = useState<IDriverWithCurrentUnit[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IPartner | undefined>(
    undefined
  );
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<IUser | undefined>(
    undefined
  );
  const [mapSettings, setMapSettings] = useState<IMapSettings>(
    STATE_DEFAULT_MAP_SETTINGS
  );
  const [journeysInProgress, setJourneysInProgress] = useState<IJourney[]>([]);
  const [selectedMapDriverType, setSelectedMapDriverType] =
    useState<MapDriverTypeEnum>(MapDriverTypeEnum.ALL);

  const handleOnGoBack = () => {
    navigate(-1);
  };

  const handleOnOnProgressJourneys = async () => {
    setLoadingModalVisible(true);
    try {
      const { data } = await journeyResources.queryByStatus(
        "progress",
        token as string
      );
      if (!isNil(data)) {
        setJourneysInProgress(data);
      }
    } catch (e) {
      console.error(
        "handleOnOnProgressJourneys: there was an issue retrieving journeys by status."
      );
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnFetchPartners = async () => {
    setLoadingModalVisible(true);
    try {
      const { data } = await partnerResources.getAll(token as string);
      if (!isNil(data)) {
        setPartners(data.concat([PRIVATE_DRIVERS_PARTNER]));
      }
    } catch (e) {
      console.error("handleOnFetchPartners: there was issue fetching partners");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnFetchPartnerDrivers = async () => {
    setLoadingModalVisible(true);
    let data: IUser[] | undefined = undefined;
    try {
      await handleOnOnProgressJourneys();
      if (isAdmin) {
        const { data: fetchedDriversByAdminData } =
          selectedPartner?.id !== "private"
            ? await userResources.getDriversByOrganization(
                selectedPartner?.id as string,
                token as string
              )
            : await userResources.getPrivateDrivers(token as string);
        data = fetchedDriversByAdminData;
      } else {
        const { data: partnerData } = await partnerResources.getById(
          organization as string,
          token as string
        );
        if (!isNil(partnerData)) {
          setMapSettings({
            ...DEFAULT_MAP_SETTINGS,
            center: partnerData.location,
          });
        }
        const { data: fetchedDriversByAdminData } =
          await userResources.getDriversByOrganization(
            organization as string,
            token as string
          );
        data = fetchedDriversByAdminData;
      }
      if (!isNil(data)) {
        const fetchedQueriedDriversWithTheirUnits =
          await getQueriedDriversWithTheirCurrentUnits(data, token as string);
        setPartnerDriversWithTheirCurrentUnits(
          fetchedQueriedDriversWithTheirUnits
        );
      }
    } catch (e) {
      console.error(
        "handleOnFetchPartnerDrivers: there was an issue fetching drivers for the given partner"
      );
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const columns: ITypedListTableColumn<IDriverWithCurrentUnit>[] = [
    {
      accessorKey: "status",
      header: "",
      cell: ({ row }) => (
        <Circle
          size="20px"
          bg={getStatusColor(
            getDriverJourneyStatus(journeysInProgress, row.original.driver) ===
              "progress",
            row.original.driver.available as boolean
          )}
        />
      ),
    },
    {
      accessorKey: "name",
      header: "Nombre",
      cell: ({ row }) => (
        <Text>{row.original.driver.fullName.split(" ")[0]}</Text>
      ),
    },
    {
      accessorKey: "unit",
      header: "Num. de unidad",
      cell: ({ row }) => <Text>{row.original.unit?.clientUnitId}</Text>,
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <Button
          size="xs"
          bg="blue.300"
          color="white"
          fontSize={12}
          rounded="full"
          onClick={() => setSelectedDriver(row.original.driver)}
        >
          Ver en el mapa
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (isAdmin) {
      handleOnFetchPartners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(selectedPartner) || isPartner) {
      handleOnFetchPartnerDrivers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  useEffect(() => {
    if (!isNil(selectedDriver)) {
      setMapSettings({
        center: !isNil(selectedDriver?.location)
          ? selectedDriver.location
          : STATE_DEFAULT_MAP_SETTINGS.center,
        zoom: 18,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDriver]);

  const partnerDriversWithTheirCurrentUnitsInJourney =
    getPartnerDriversWithTheirCurrentUnitsInJourney(
      partnerDriversWithTheirCurrentUnits,
      journeysInProgress
    );
  const partnerActiveDriversWithTheirUnitsNotInJourney =
    getPartnerActiveDriversWithTheirCurrentUnitsNotInJourney(
      partnerDriversWithTheirCurrentUnits,
      journeysInProgress
    );
  const partnerUnactiveDriversWithTheirUnitsNotInJourney =
    getPartnerUnactiveDriversWithTheirCurrentUnitsNotInJourney(
      partnerDriversWithTheirCurrentUnits,
      journeysInProgress
    );

  const filteredOutDriversWithTheirCurrentUnits =
    getFilteredOutDriversWithTheirCurrentUnits(
      selectedMapDriverType,
      partnerDriversWithTheirCurrentUnits,
      partnerDriversWithTheirCurrentUnitsInJourney,
      partnerActiveDriversWithTheirUnitsNotInJourney,
      partnerUnactiveDriversWithTheirUnitsNotInJourney
    );

  return (
    <HStack w="100vw" h="100vh">
      <LoadingModal
        isOpen={loadingModalVisible}
        onClose={() => {}}
        children={<></>}
      />
      <VStack w="45%" h="100%">
        <HStack w="100%" p={5} justifyContent="space-between">
          <Text fontWeight="bold">Conductores</Text>
          <Button
            onClick={handleOnGoBack}
            color="white"
            bg={zubeColors.zubeOrange.dark}
            rounded="full"
          >
            Regresar
          </Button>
        </HStack>
        {isAdmin ? (
          <PartnerSelector
            partners={partners}
            onChange={(e) =>
              setSelectedPartner(
                partners.find(
                  (partner) => partner.id === e.target.selectedOptions[0].value
                )
              )
            }
            value={selectedPartner?.id}
            w="90%"
            alignSelf="center"
          />
        ) : null}
        {partnerDriversWithTheirCurrentUnits.length > 1 ? (
          <DriverTypeSelector
            handleOnDriverTypeSelect={setSelectedMapDriverType}
            allCount={partnerDriversWithTheirCurrentUnits.length}
            activeCount={partnerActiveDriversWithTheirUnitsNotInJourney.length}
            unactiveCount={
              partnerUnactiveDriversWithTheirUnitsNotInJourney.length
            }
            onJourneyCount={partnerDriversWithTheirCurrentUnitsInJourney.length}
          />
        ) : null}
        <DashboardTable
          data={filteredOutDriversWithTheirCurrentUnits}
          columns={columns as ColumnDef<object>[]}
          w="90%"
          h="90%"
          py={5}
        />
      </VStack>
      <Box w="55%" h="100%">
        <LoadScript googleMapsApiKey={MAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={{
              lat: mapSettings.center.latitude,
              lng: mapSettings.center.longitude,
            }}
            zoom={mapSettings.zoom}
          >
            {filteredOutDriversWithTheirCurrentUnits.map(
              (driverWithCurrentUnit) =>
                !isNil(driverWithCurrentUnit.driver.location) ? (
                  <ZubeMarker
                    available={
                      driverWithCurrentUnit.driver.available as boolean
                    }
                    location={driverWithCurrentUnit.driver.location}
                    key={driverWithCurrentUnit.driver.id}
                    driverName={
                      driverWithCurrentUnit.driver.fullName.split(" ")[0]
                    }
                    unit={driverWithCurrentUnit.unit?.clientUnitId}
                    journeyStatus={getDriverJourneyStatus(
                      journeysInProgress,
                      driverWithCurrentUnit.driver
                    )}
                  />
                ) : null
            )}
          </GoogleMap>
        </LoadScript>
      </Box>
    </HStack>
  );
};
