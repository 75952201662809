import { isNil } from "lodash/fp";
import { unitResources } from "services/resources/firebase/unit";
import { userResources } from "services/resources/firebase/user";
import { IDriverWithCurrentUnit, IUnitWithDriver } from "./types.d";
import { IUser } from "services/resources/firebase/user/types.d";
import { IUnit } from "services/resources/firebase/unit/types.d";
import { isEmpty } from "lodash";

export const getDriversUnitsWithDrivers = async (
  token: string,
  organization: undefined | string = undefined
): Promise<IUnitWithDriver[]> => {
  let retrievedUnitsWithDrivers: IUnitWithDriver[] = [];
  try {
    const { data: unitsData } = isNil(organization)
      ? await unitResources.getAll(token)
      : await unitResources.getByPartnerId(organization, token);

    if (!isNil(unitsData)) {
      await Promise.allSettled(
        unitsData.map(async (unit) => {
          if (!isNil(unit.driver) && !isEmpty(unit.driver)) {
            const { data: driverData } = await userResources.getById(
              unit.driver as string,
              token
            );
            if (!isNil(driverData)) {
              retrievedUnitsWithDrivers.push({ ...unit, driver: driverData });
            }
          } else {
            retrievedUnitsWithDrivers.push({
              ...unit,
              driver: { fullName: "Sin asignar ", id: unit.id } as IUser,
            });
          }
          return;
        })
      );
    }
  } catch (e) {
    console.error(
      "getAllDriversUnitsWithDrivers: there was an issue retrieving drivers / units data."
    );
  }
  return retrievedUnitsWithDrivers;
};

export const getQueriedDriversWithTheirCurrentUnits = async (
  queriedDrivers: IUser[],
  token: string
): Promise<IDriverWithCurrentUnit[]> => {
  let retrievedDriversWithTheirCurrentUnits: IDriverWithCurrentUnit[] = [];
  try {
    await Promise.allSettled(
      queriedDrivers.map(async (driver) => {
        let unitsData: IUnit[] | undefined = undefined;
        try {
          const { data: fetchedUnitsData } = await unitResources.getDriverUnits(
            driver.id,
            token
          );
          unitsData = fetchedUnitsData;
        } catch (e) {
          console.error(
            "getQueriedDriversWithTheirCurrentUnits: there was an issue retrieving units data."
          );
        }
        if (!isNil(unitsData)) {
          const currentUnit = unitsData.find(
            (unit) => unit.id === driver.selectedUnit
          );
          retrievedDriversWithTheirCurrentUnits.push({
            unit: currentUnit,
            driver,
          });
        } else {
          retrievedDriversWithTheirCurrentUnits.push({
            driver,
          });
        }
        return;
      })
    );
  } catch (e) {
    console.error(
      "getUnitsWithQueriedDrivers: there was an issue retrieving units data."
    );
  }
  return retrievedDriversWithTheirCurrentUnits;
};
