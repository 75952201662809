import { Button, HStack, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { isEmpty } from "lodash/fp";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser, setUserData } from "redux-service/slices";
import { userResources } from "services/resources/firebase/user";
import { authResources } from "services/resources/firebase/auth";
import { ProfileForm } from "components/forms";
import { IUserSignupPayload } from "services/resources/firebase/user/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { createBlob } from "helpers/data-helpers/blob-helpers";
import { storageResources } from "services/resources/firebase/storage";

export const Profile: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useSmallScreen();
  const [loadingVisible, setLoadingVisible] = useState<boolean>(false);

  const handleOnEditProfile = async (
    payload: Pick<
      IUserSignupPayload,
      "email" | "fullName" | "img" | "password" | "type"
    >
  ) => {
    setLoadingVisible(true);
    let imageUrl = userData.img;
    try {
      // get user uid for auth methods
      const { data } = await authResources.getUidFromEmail(
        userData.email,
        userData.token as string
      );
      // if email is different now, update auth
      if (payload.email !== userData.email) {
        await authResources.updateEmail(
          data?.uid as string,
          payload.email,
          userData.token as string
        );
        await userResources.patchEmail(
          payload.email,
          userData.id,
          userData.token as string
        );
      }
      // if email passed, check if new password
      if (!isEmpty(payload.password)) {
        await authResources.updatePassword(
          data?.uid as string,
          payload.password,
          userData.token as string
        );
      }
      // if password passed, check if a new image was uploaded
      if (!isEmpty(payload.img) && payload.img !== userData.img) {
        const blob = await createBlob(payload.img);
        const { ref } = await storageResources.createUserPicture(
          payload.email,
          blob
        );
        imageUrl = await storageResources.getPictureURLByRef(ref);
        await userResources.patchProfilePicture(
          imageUrl as string,
          userData.id,
          userData.token as string
        );
      }
      // update user's name
      await userResources.patchName(
        payload.fullName,
        userData.id,
        userData.token as string
      );
      // update global state
      dispatch(
        setUserData({
          ...userData,
          email: payload.email,
          fullName: payload.fullName,
          img: imageUrl,
        })
      );
    } catch (e) {
      console.error("handleOnEditProfile", e);
      window.alert(
        "Ocurrió un error al actualizar tu perfil. Inténtalo más tarde o contacta a tu administrador."
      );
    } finally {
      setLoadingVisible(false);
    }
  };

  return (
    <PageContainer pageHeader="Perfil" loadingModalVisible={loadingVisible}>
      <HStack
        justifyContent="flex-end"
        mt={isSmallScreen ? 5 : undefined}
        mb={3}
      >
        <Button
          bg="#ffa755"
          borderRadius={20}
          onClick={() => navigate("/users")}
        >
          Regresar
        </Button>
      </HStack>
      <VStack
        bg="white"
        borderRadius={12}
        m={isSmallScreen ? 5 : undefined}
        pt={5}
        w="50%"
      >
        <ProfileForm
          handleOnSubmit={handleOnEditProfile}
          previousValues={{
            email: userData.email,
            fullName: userData.fullName,
            img: userData.img,
            password: "",
            type: userData.type,
          }}
          w="90%"
        />
      </VStack>
    </PageContainer>
  );
};
