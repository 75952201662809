import { Button, Text, VStack, StackProps } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { type IBaseAuthentication } from "services/resources/firebase/auth/types.d";

import {
  INITIAL_VALUES,
  LoginFormValidationSchema,
} from "./helpers/form-helpers";
import { EmailField } from "components/inputs";
import { zubeColors } from "styles/colors";
import { isEmpty } from "lodash/fp";

interface IResetPasswordFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (
    payload: Pick<IBaseAuthentication, "email">
  ) => Promise<void>;
}

export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = (props) => {
  const { handleOnSubmit, ...rest } = props;
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={LoginFormValidationSchema}
    >
      {({ errors, handleChange, isValid, handleSubmit, values }) => (
        <VStack {...rest}>
          <Text fontWeight="bold" mb={12} textAlign="center">
            ¿Olvidaste tu contraseña?
          </Text>
          <Text mb={22} px={2} textAlign="center">
            Por favor, para reiniciar tu contraseña, ingresa el correo asociado
            a tu cuenta y revisa tu bandeja de entrada para continuar.
          </Text>
          <EmailField
            error={errors.email}
            onChangeText={handleChange("email")}
            label="Correo electrónico:"
            w="100%"
          />
          <Button
            bg={zubeColors.zubePurple.light}
            _hover={{ bg: zubeColors.zubePurple.dark }}
            isDisabled={!isEmpty(errors) || isEmpty(values.email)}
            justifyContent="center"
            onClick={() => {
              if (isValid) handleSubmit();
            }}
            w="100%"
            color="white"
          >
            Enviar enlace para restablecer contraseña
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

ResetPasswordForm.defaultProps = {
  h: "100%",
  justifyContent: "center",
  w: "80%",
};
