import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { useEffect, useState } from "react";
import { DriverRequestsStats } from "./components/DriverRequestsStats";
import { JourneysStats } from "./components/JourneysStats";
import { FinanceStats } from "./components/FinanceStats";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { isNil } from "lodash/fp";
import {
  IDriverRequest,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { IJourney } from "services/resources/firebase/journey/types.d";
import { journeyResources } from "services/resources/firebase/journey";
import { userResources } from "services/resources/firebase/user";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { getDriversWithMostJourneysWithTheirNames } from "services/resources-recipes/journeys";
import {
  IDriverWithMostJourneys,
  IRequestWithDriver,
} from "services/resources-recipes/types.d";
import { DriversWithMostJourneys } from "./components/DriversWithMostJourneys";
import { Appointments } from "./components/Appointments";

export const Home: React.FC = (): JSX.Element => {
  const { organization, token, type } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [requests, setRequests] = useState<IDriverRequest[]>([]);
  const [journeys, setJourneys] = useState<IJourney[]>([]);
  const [driversWithMostJourneys, setDriversWithMostJourneys] = useState<
    IDriverWithMostJourneys[]
  >([]);
  const [requestsWithDrivers, setRequestsWithDrivers] = useState<
    IRequestWithDriver[]
  >([]);

  // requests with pending or submitted status
  const pendingRequestsAmount = requests.filter(
    (req) =>
      req.status === VerificationStatusEnum.PENDING ||
      req.status === VerificationStatusEnum.SUBMITTED
  ).length;
  // requests with completed status
  const acceptedRequestsAmount = requests.filter(
    (req) => req.status === VerificationStatusEnum.COMPLETED
  ).length;
  // requests with appointment status
  const appointmentRequestsAmount = requests.filter(
    (req) => req.status === VerificationStatusEnum.APPOINTMENT
  ).length;
  // journeys with cancelled status
  const cancelledJourneys = journeys.filter(
    (journey) => journey.status === "cancelled"
  );
  // journeys with created status
  const expiredJourneys = journeys.filter(
    (journey) => journey.status === "created"
  );
  // journeys with completed status
  const completedJourneys = journeys.filter(
    (journey) => journey.status === "completed"
  );
  // sum fares of completed journeys with a payment intent id and confirmed payment
  const cardIncomes = completedJourneys
    .filter(
      (journey) =>
        journey.paymentMethod.includes("pi") && !journey.hasPendingPayment
    )
    .reduce((total, journey) => total + journey.fare, 0);
  // sum fares of completed journeys with cash method
  const cashIncomes = completedJourneys
    .filter((journey) => journey.paymentMethod === "cash")
    .reduce((total, journey) => total + journey.fare, 0);
  // sum fares of cancelled journeys
  const losses = cancelledJourneys.reduce(
    (total, journey) => total + journey.fare,
    0
  );

  const retrieveDriverRequests = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data: requestsData } = await driverRequestResources.getAll(
        token as string
      );
      if (!isNil(requestsData)) {
        const requestsToBeUsed =
          type !== UserTypeEnum.PARTNER
            ? requestsData
            : requestsData.filter(
                (req) => req.organization?.field === organization
              );
        setRequests(requestsToBeUsed);
        await retrieveRequestsWithDrivers(requestsToBeUsed);
      }
    } catch (e) {
      console.error("Error-retrieveDriverRequests: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const retrieveJourneys = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      if (type !== UserTypeEnum.PARTNER) {
        const { data: journeysData } = await journeyResources.getAll(
          token as string
        );
        if (!isNil(journeysData)) {
          setJourneys(journeysData);
        }
      } else {
        // fetch fleet
        const partnerDrivers = await userResources.getPartnerDrivers(
          organization as string,
          token as string
        );
        if (!isNil(partnerDrivers)) {
          const { data: journeysData } = await journeyResources.queryByDrivers(
            partnerDrivers?.map((driver) => driver.id),
            token as string
          );
          if (!isNil(journeysData)) {
            setJourneys(journeysData);
          }
        }
      }
    } catch (e) {
      console.error("Error-retrieveJourneys: ", e);
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const retrieveDriversWithMostJourneysWithTheirNames =
    async (): Promise<void> => {
      setLoadingModalVisible(true);
      const retrievedData = await getDriversWithMostJourneysWithTheirNames(
        token as string,
        organization
      );
      setLoadingModalVisible(false);
      setDriversWithMostJourneys(retrievedData);
    };

  const retrieveRequestsWithDrivers = async (
    fetchedRequests: IDriverRequest[]
  ): Promise<void> => {
    setLoadingModalVisible(true);
    let fetchedRequetsWithDrivers: IRequestWithDriver[] = [];
    await Promise.allSettled(
      fetchedRequests.map(async (request) => {
        const { data } = await userResources.getById(
          request.driverId,
          token as string
        );
        if (!isNil(data) && !isNil(request.appointmentDate)) {
          fetchedRequetsWithDrivers.push({ request, driver: data });
        }
        return;
      })
    );
    setRequestsWithDrivers(fetchedRequetsWithDrivers);
    setLoadingModalVisible(false);
  };

  useEffect(() => {
    retrieveDriverRequests();
    retrieveJourneys();
    retrieveDriversWithMostJourneysWithTheirNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Dashboard"
      loadingModalVisible={loadingModalVisible}
    >
      <VStack
        h={isSmallScreen ? undefined : "100%"}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          templateColumns={isSmallScreen ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
          gap={3}
          my={4}
          w="100%"
          overflowY="scroll"
          p={3}
        >
          <GridItem>
            <DriverRequestsStats
              pendingRequests={pendingRequestsAmount}
              acceptedRequests={acceptedRequestsAmount}
              requestsWithAppointments={appointmentRequestsAmount}
            />
          </GridItem>
          <GridItem>
            <Appointments
              minH="30vh"
              maxH="30vh"
              overflowY="scroll"
              driversWithRequests={requestsWithDrivers}
            />
          </GridItem>
          <GridItem>
            <DriversWithMostJourneys
              driversWithJourneyCountsAndProfits={driversWithMostJourneys}
            />
          </GridItem>
          <GridItem>
            <JourneysStats
              allJourneysCount={journeys.length}
              cancelled={cancelledJourneys.length}
              cancelledBecauseOfLongWait={expiredJourneys.length}
              finished={completedJourneys.length}
            />
          </GridItem>
          <GridItem>
            <FinanceStats
              cardIncomes={cardIncomes}
              cashIncomes={cashIncomes}
              loses={losses}
            />
          </GridItem>
        </Grid>
      </VStack>
    </PageContainer>
  );
};
