/* eslint-disable no-useless-escape */
export const alphaSpaceRegex = /^[a-zA-ZÀ-ÿ ]+$/;

export const stringNumRegex = /^[0-9]+$/;

// Regex retrieved from https://www.codeproject.com/Questions/461957/Regular-expression-for-email-validation (modified)
export const emailRegex =
  /^([0-9a-z]([-.\w]*[0-9a-z])*@([0-9a-z][-\w]*[0-9a-z]\.)+[a-z]{2,5})$/;

export const numberRegex = /^[1-9][0-9]*$/;

export const integerOrDecimalNumberRegex = /^\d*\.?\d+$/;

// Regex retrieved from https://www.regexlib.com/REDetails.aspx?regexp_id=3455
export const RFCregex =
  /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
