import axios from "axios";

const prodEnvironment: boolean = process.env.REACT_APP_ENVIRONMENT === "prod";

export const firebaseInstance = axios.create({
  baseURL: prodEnvironment
    ? process.env.REACT_APP_URL_BASE_PROD
    : process.env.REACT_APP_URL_BASE_DEV,
});

export const localInstance = axios.create({
  baseURL: "http://127.0.0.1:5001/zube-rm-tests/us-central1",
});
