import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";
import { type IReport } from "./types.d";

const REPORTS_API_URL = "/reportsAPI";

export const reportResources = {
  delete: async (
    id: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> => {
    return await firebaseInstance.delete(`${REPORTS_API_URL}/`, {
      headers: { Authorization: token },
      params: { id },
    });
  },

  getAll: async (token: string): Promise<IAxiosResponse<IReport[]>> => {
    return await firebaseInstance.get(`${REPORTS_API_URL}/`, {
      headers: { Authorization: token },
    });
  },
};
