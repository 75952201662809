import { object, string } from "yup";

export const PartnerFormValidationSchema = () => {
  return object().shape({
    name: string().required("Este campo es requerido."),
    planId: string().required("Este campo es requerido."),
    paymentStatus: string(),
  });
};

export const INITIAL_VALUES = {
  name: "",
  planId: "",
  paymentStatus: "active",
  // phoneNumber: "",
};
