import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import { type ICoupon, type CouponPayloadType } from "./types.d";

const COUPONS_API_URL = "/couponsAPI";

export const couponResources = {
  create: async (
    coupon: CouponPayloadType,
    token: string
  ): Promise<IAxiosResponse<ICoupon>> =>
    firebaseInstance.post(
      `${COUPONS_API_URL}/`,
      { coupon },
      {
        headers: { Authorization: token },
      }
    ),

  delete: async (
    id: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.delete(`${COUPONS_API_URL}`, {
      headers: { Authorization: token },
      params: { id },
    }),

  getAll: async (token: string): Promise<IAxiosResponse<ICoupon[]>> =>
    firebaseInstance.get(`${COUPONS_API_URL}`, {
      headers: { Authorization: token },
    }),

  getById: async (
    id: string,
    token: string
  ): Promise<IAxiosResponse<ICoupon[]>> =>
    firebaseInstance.get(`${COUPONS_API_URL}`, {
      headers: { Authorization: token },
      params: { id },
    }),

  patch: async (
    coupon: CouponPayloadType,
    id: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${COUPONS_API_URL}`,
      { coupon },
      {
        headers: { Authorization: token },
        params: { id },
      }
    ),
};
