import {
  HStack,
  Image,
  Link,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { USER_FALLBACK_URL } from "helpers/constants/url-constants";
import { IRequestWithDriver } from "services/resources-recipes/types.d";
import { CustomTimestamp } from "services/resources/firebase/types.d";
import { DriverLineWithCircle } from "./DriverLineWithCircle";
import { zubeColors } from "styles/colors";
import { Link as RichLink } from "react-router-dom";

interface IDriverAppointmentProps extends StackProps {
  driverWithRequest: IRequestWithDriver;
}

export const DriverAppointment: React.FC<IDriverAppointmentProps> = (
  props
): JSX.Element => {
  const { driverWithRequest, ...rest } = props;

  return (
    <HStack alignItems="center" w="100%" {...rest}>
      <DriverLineWithCircle
        h="100px"
        circleColor={zubeColors.adminRed}
        w="10%"
      />
      <Image
        src={driverWithRequest.driver.img}
        fallbackSrc={USER_FALLBACK_URL}
        w="60px"
      />
      <VStack pl={3} alignItems="flex-start">
        <Link
          as={RichLink}
          to={`/review-request/${driverWithRequest.request.id}`}
        >
          <Text>{driverWithRequest.driver.fullName}</Text>
        </Link>
        <Text>{`Fecha de cita: ${format(
          new Date(
            (driverWithRequest.request.appointmentDate as CustomTimestamp)
              ._seconds * 1000
          ),
          "dd-MM-yyyy HH:mm"
        )}`}</Text>
      </VStack>
    </HStack>
  );
};
