import { Image, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { FirebaseError } from "firebase/app";
import { handleFirebaseError } from "helpers/error-helpers/auth-error-helpers";
import { useState } from "react";
import { authResources } from "services/resources/firebase/auth";
import { type IBaseAuthentication } from "services/resources/firebase/auth/types.d";
import { ZUBE_LOGO_URL } from "helpers/constants/url-constants";
import { useToast } from "@chakra-ui/react";
import { ResetPasswordForm } from "components/forms";

export const ResetPassword: React.FC = (): JSX.Element => {
  const toast = useToast();

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);

  const handleResetPassword = async (
    payload: Pick<IBaseAuthentication, "email">
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await authResources.resetPassword(payload.email);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error-handleResetPassword: ", e);
      if (e instanceof FirebaseError) {
        toast({
          title: "Error de autenticación",
          description: handleFirebaseError(e.code),
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "Error de autenticación",
          description: "Error inesperado",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
    setLoadingModalVisible(false);
  };

  return (
    <PageContainer
      showNavbar={false}
      bg="#e6edf7"
      justifyContent="center"
      useLeftPanel={false}
      loadingModalVisible={loadingModalVisible}
    >
      <VStack
        alignItems="center"
        h="100%"
        bg="white"
        borderRadius={5}
        p={7}
        w="35%"
        mb={7}
      >
        <Image src={ZUBE_LOGO_URL} my={9} />
        <ResetPasswordForm handleOnSubmit={handleResetPassword} />
      </VStack>
    </PageContainer>
  );
};
