import { initializeApp } from "firebase/app";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  type StorageReference,
  uploadBytes,
  uploadBytesResumable,
  type UploadResult,
} from "firebase/storage";

import { appConfig } from "../firebase-service";

const storage = getStorage(initializeApp(appConfig));

export const storageResources = {
  createPictureToPath: (path: string, imageBlob: Blob): Promise<UploadResult> =>
    uploadBytes(ref(storage, `images/${path}`), imageBlob),

  createUserPicture: (
    userEmail: string,
    imageBlob: Blob
  ): Promise<UploadResult> =>
    uploadBytes(
      ref(storage, `users/${userEmail}/images/profile-picture/${Date.now()}`),
      imageBlob
    ),

  createUnitPicture: async (
    unitId: string,
    imageBlob: Blob
  ): Promise<StorageReference> => {
    const currentTime = new Date().getTime();
    const response = await uploadBytesResumable(
      ref(storage, `${unitId}/units/${currentTime}`),
      imageBlob
    );
    return response.ref;
  },

  deletePicture: (userEmail: string, path: string): Promise<void> =>
    deleteObject(ref(storage, `images/${userEmail}/${path}`)),

  getPictureURL: (userEmail: string, path: string): Promise<string> =>
    getDownloadURL(ref(storage, `images/${userEmail}/${path}`)),

  getPictureURLByRef: (ref: StorageReference): Promise<string> =>
    getDownloadURL(ref),

  createDriverDocument: (
    userEmail: string,
    documentName: string,
    imageBlob: Blob
  ): Promise<UploadResult> =>
    uploadBytes(
      ref(
        storage,
        `users/${userEmail}/request/documents/${Date.now()}-${documentName}`
      ),
      imageBlob
    ),
};
