import {
  VStack,
  Grid,
  GridItem,
  Divider,
  HStack,
  Button,
  Center,
  Text,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { DashboardPanel, DashboardTable } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { format } from "date-fns";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { Dispatch, SetStateAction } from "react";
import { IJourneyWithUsers } from "services/resources-recipes/types.d";
import { zubeColors } from "styles/colors";

interface IJourneysUIProps {
  columns: ITypedListTableColumn<IJourneyWithUsers>[];
  getStatus: (status: string) => { color: string; label: string };
  journeys: IJourneyWithUsers[];
  setSelectedJourney: Dispatch<SetStateAction<IJourneyWithUsers | undefined>>;
}

export const JourneysUI: React.FC<IJourneysUIProps> = (props): JSX.Element => {
  const { columns, getStatus, journeys, setSelectedJourney } = props;

  const isSmallScreen = useSmallScreen();
  return isSmallScreen ? (
    <VStack alignItems="center" justifyContent="center" my={5}>
      <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
        {journeys.map(({ journey, passenger, driver }, index) => (
          <GridItem key={index}>
            <DashboardPanel label={`Viaje ${journey.id}`}>
              <VStack w="100%">
                <HStack fontSize={12} spacing="auto" my={3} w="90%">
                  <Text fontWeight="bold" w="45%">
                    Fecha
                  </Text>
                  <Text>{format(journey.created._seconds * 1000, "p")}</Text>
                </HStack>
                <Divider />
                <HStack fontSize={12} spacing="auto" my={3} w="90%">
                  <Text fontWeight="bold" minW="45%">
                    Origen
                  </Text>
                  <Text>{journey.roundTripAddresses.origin}</Text>
                </HStack>
                <Divider />
                <HStack fontSize={12} spacing="auto" my={3} w="90%">
                  <Text fontWeight="bold" minW="45%">
                    Destino
                  </Text>
                  <Text>{journey.roundTripAddresses.destination}</Text>
                </HStack>
                <Divider />
                <HStack fontSize={12} spacing="auto" my={3} w="90%">
                  <Text fontWeight="bold" w="45%">
                    Conductor
                  </Text>
                  <Text>{driver.fullName}</Text>
                </HStack>
                <Divider />
                <HStack fontSize={12} spacing="auto" my={3} w="90%">
                  <Text fontWeight="bold" w="45%">
                    Pasajero
                  </Text>
                  <Text>{passenger.fullName}</Text>
                </HStack>
                <Divider />
                <HStack fontSize={12} spacing="auto" my={3} w="90%">
                  <Text fontWeight="bold" w="45%">
                    Estatus
                  </Text>
                  <Text
                    bg={getStatus(journey.status).color}
                    borderRadius={16}
                    color="white"
                    textAlign="center"
                    px={2}
                    py={2}
                  >
                    {getStatus(journey.status).label}
                  </Text>
                </HStack>
                <Divider />
                <HStack>
                  <Button
                    color="white"
                    bg={zubeColors.zubeGray.dark}
                    rounded="full"
                    fontSize={12}
                    onClick={() =>
                      setSelectedJourney({ journey, passenger, driver })
                    }
                  >
                    Detalles
                  </Button>
                </HStack>
              </VStack>
            </DashboardPanel>
          </GridItem>
        ))}
      </Grid>
    </VStack>
  ) : (
    <Center bg="transparent" w="100%" h="100%" alignSelf="center">
      <Center bg="white" w="95%" h="90%" borderRadius={5}>
        {columns.length > 0 ? (
          <DashboardTable
            data={journeys}
            columns={columns as ColumnDef<object>[]}
            w="95%"
            h="95%"
            py={5}
          />
        ) : null}
      </Center>
    </Center>
  );
};
