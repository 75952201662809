import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import { type IPartner, type PartnerPayloadType } from "./types.d";

const PARTNERS_API_URL = "/partnersAPI";

export const partnerResources = {
  create: async (
    partner: PartnerPayloadType,
    token: string
  ): Promise<IAxiosResponse<IPartner>> =>
    firebaseInstance.post(
      `${PARTNERS_API_URL}/partner`,
      { partner },
      {
        headers: { Authorization: token },
      }
    ),

  delete: async (
    partnerId: string,
    token: string
  ): Promise<IAxiosResponse<any>> =>
    firebaseInstance.delete(`${PARTNERS_API_URL}/partner`, {
      headers: { Authorization: token },
      params: { partnerId },
    }),

  getAll: async (token: string): Promise<IAxiosResponse<IPartner[]>> =>
    firebaseInstance.get(`${PARTNERS_API_URL}/partners`, {
      headers: { Authorization: token },
    }),

  getById: async (
    partnerId: string,
    token: string
  ): Promise<IAxiosResponse<IPartner>> =>
    firebaseInstance.get(`${PARTNERS_API_URL}/partner-by-id`, {
      headers: { Authorization: token },
      params: { partnerId },
    }),

  patch: async (
    partner: PartnerPayloadType,
    partnerId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${PARTNERS_API_URL}/partner`,
      { partner },
      {
        headers: { Authorization: token },
        params: { partnerId },
      }
    ),
};
