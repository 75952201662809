import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import { type IUnit, type UnitPayloadType } from "./types.d";

const UNITS_API_URL = "/unitsAPI";

export const unitResources = {
  create: async (
    unit: UnitPayloadType,
    token: string
  ): Promise<IAxiosResponse<IUnit>> =>
    firebaseInstance.post(
      `${UNITS_API_URL}`,
      { unit },
      {
        headers: { Authorization: token },
      }
    ),

  delete: async (unitId: string, token: string): Promise<IAxiosResponse<any>> =>
    firebaseInstance.delete(`${UNITS_API_URL}`, {
      headers: { Authorization: token },
      params: { unitId },
    }),

  getDriverUnits: async (
    driverId: string,
    token: string
  ): Promise<IAxiosResponse<IUnit[]>> =>
    firebaseInstance.get(`${UNITS_API_URL}/by-driver`, {
      headers: { Authorization: token },
      params: { driverId },
    }),

  getAll: async (token: string): Promise<IAxiosResponse<IUnit[]>> =>
    firebaseInstance.get(`${UNITS_API_URL}`, {
      headers: { Authorization: token },
    }),

  getByPartnerId: async (
    partnerId: string,
    token: string
  ): Promise<IAxiosResponse<IUnit[]>> =>
    firebaseInstance.get(`${UNITS_API_URL}/by-partner-id`, {
      headers: { Authorization: token },
      params: { partnerId },
    }),

  getByUnitId: async (
    unitId: string,
    token: string
  ): Promise<IAxiosResponse<IUnit>> =>
    firebaseInstance.get(`${UNITS_API_URL}/by-unit-id`, {
      headers: { Authorization: token },
      params: { unitId },
    }),

  patchUnit: async (
    unitId: string,
    unit: UnitPayloadType,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${UNITS_API_URL}`,
      { unit },
      {
        headers: { Authorization: token },
        params: { unitId },
      }
    ),

  patchUnitDriver: async (
    unitId: string,
    driver: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${UNITS_API_URL}/driver`,
      { driver },
      {
        headers: { Authorization: token },
        params: { unitId },
      }
    ),

  clearUnitDriver: async (
    unitId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${UNITS_API_URL}/driver-clear`,
      {},
      {
        headers: { Authorization: token },
        params: { unitId },
      }
    ),
};
