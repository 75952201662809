import { Select, SelectProps } from "@chakra-ui/react";
import { IPartner } from "services/resources/firebase/partner/types.d";

interface IPartnerSelectorProps extends Partial<SelectProps> {
  partners: IPartner[];
}

export const PartnerSelector: React.FC<IPartnerSelectorProps> = (props) => {
  const { partners, ...rest } = props;

  return (
    <Select mb={4} placeholder="Selecciona un partner" {...rest}>
      {partners.map((partner) => (
        <option key={partner.id} value={partner.id}>
          {partner.name}
        </option>
      ))}
    </Select>
  );
};
