import { StackProps, VStack } from "@chakra-ui/react";
import { UserCard } from "../UserCard";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { DisplayType } from "../types.d";
import { SessionMenu } from "../SessionMenu";
import { useState } from "react";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { AdminLinks } from "./components/AdminLinks";
import { PartnerLinks } from "./components/PartnerLinks";

interface IAdminPanelProps extends StackProps {
  displayType: DisplayType;
}

export const AdminPanel: React.FC<IAdminPanelProps> = (props) => {
  const { displayType, ...rest } = props;

  const userData = useSelector(getUser);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <VStack spacing={displayType === "full" ? 1 : 3} {...rest}>
      <SessionMenu
        w="100%"
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        _hover={{ cursor: "pointer" }}
      >
        <UserCard
          user={userData}
          showRole={userData.type !== UserTypeEnum.PARTNER}
          displayType={displayType}
          showRightIcon
          isHovered={isHovered}
          flex={1}
        />
      </SessionMenu>
      {userData.type === UserTypeEnum.ADMIN || userData.isAdmin ? (
        <AdminLinks displayType={displayType} />
      ) : (
        <PartnerLinks displayType={displayType} />
      )}
    </VStack>
  );
};
