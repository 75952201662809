import {
  Coupons,
  Drivers,
  Fares,
  Home,
  Journeys,
  Login,
  Map,
  Partners,
  Plans,
  Profile,
  Reports,
  Requests,
  ResetPassword,
  ReviewRequest,
  Units,
  Users,
  Passengers,
} from "../pages";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSession } from "hooks/session/session-hooks";
import { LoadingModal } from "components/elements";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";

export const Router: React.FC = (): JSX.Element => {
  const { logged, sessionResolved, sessionExists, userType, isAdmin } =
    useSession();

  const shouldLogIn =
    !logged &&
    !window.location.pathname.includes("login") &&
    sessionResolved &&
    !sessionExists;

  const shouldGoToDashboard =
    !window.location.pathname.includes("dashboard") &&
    logged &&
    sessionResolved &&
    sessionExists;

  useEffect(() => {
    if (shouldLogIn) {
      // Redirect to the login page
      window.location.href = "/admin/login";
    } else if (shouldGoToDashboard) {
      window.location.href = "/admin/dashboard";
    }
    const root = window.document.getElementById("root");
    const timeout = setTimeout(() => {
      if (
        root?.children.length === 1 &&
        window.location.pathname.includes("dashboard")
      ) {
        window.location.reload();
      }
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    logged,
    sessionResolved,
    sessionExists,
    shouldLogIn,
    shouldGoToDashboard,
    userType,
    isAdmin,
  ]);

  return (
    <>
      <LoadingModal
        isOpen={!sessionResolved}
        onClose={() => {}}
        children={<></>}
      />
      <BrowserRouter basename="admin">
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<ResetPassword />} path="/password-reset" />
          {logged && (userType === UserTypeEnum.ADMIN || isAdmin) ? (
            <>
              <Route element={<Home />} path="/dashboard" />
              <Route element={<Users />} path="/users" />
              <Route element={<Drivers />} path="/drivers/:driverId?" />
              <Route
                element={<Passengers />}
                path="/passengers/:passengerId?"
              />
              <Route element={<Units />} path="/units/:unitId?" />
              <Route element={<Journeys />} path="/journeys/:journeyId?" />
              <Route element={<Fares />} path="/fares" />
              <Route element={<Requests />} path="/requests" />
              <Route
                element={<ReviewRequest />}
                path="/review-request/:requestId"
              />
              <Route element={<Plans />} path="/plans" />
              <Route element={<Partners />} path="/partners" />
              <Route element={<Coupons />} path="/coupons" />
              <Route element={<Map />} path="/map" />
              <Route element={<Profile />} path="/profile" />
              <Route element={<Reports />} path="/reports" />
            </>
          ) : null}
          {logged && userType === UserTypeEnum.PARTNER ? (
            <>
              <Route element={<Home />} path="/dashboard" />
              <Route element={<Users />} path="/users" />
              <Route element={<Drivers />} path="/drivers/:driverId?" />
              <Route element={<Units />} path="/units/:unitId?" />
              <Route element={<Journeys />} path="/journeys/:journeyId?" />
              <Route element={<Requests />} path="/requests" />
              <Route
                element={<ReviewRequest />}
                path="/review-request/:requestId"
              />
              <Route element={<Map />} path="/map" />
              <Route element={<Profile />} path="/profile" />
            </>
          ) : null}
        </Routes>
      </BrowserRouter>
    </>
  );
};
