import { IDriverWithCurrentUnit } from "services/resources-recipes/types.d";
import { IJourney } from "services/resources/firebase/journey/types.d";
import { MapDriverTypeEnum } from "../types.d";

export const getPartnerDriversWithTheirCurrentUnitsInJourney = (
  partnerDriversWithTheirCurrentUnits: IDriverWithCurrentUnit[],
  journeysInProgress: IJourney[]
): IDriverWithCurrentUnit[] => {
  return partnerDriversWithTheirCurrentUnits.filter((driverWithCurrentUnit) => {
    const driverPresentInOnProgressJourney = journeysInProgress.filter(
      (journey) =>
        journey.driver === driverWithCurrentUnit.driver.id &&
        journey.status === "progress"
    );
    if (driverPresentInOnProgressJourney.length > 0) {
      return driverWithCurrentUnit;
    } else return null;
  });
};

export const getPartnerActiveDriversWithTheirCurrentUnitsNotInJourney = (
  partnerDriversWithTheirCurrentUnits: IDriverWithCurrentUnit[],
  journeysInProgress: IJourney[]
): IDriverWithCurrentUnit[] => {
  return partnerDriversWithTheirCurrentUnits.filter((driverWithCurrentUnit) => {
    const driverPresentInOnProgressJourney = journeysInProgress.filter(
      (journey) =>
        journey.driver === driverWithCurrentUnit.driver.id &&
        journey.status === "progress"
    );
    if (
      driverPresentInOnProgressJourney.length === 0 &&
      driverWithCurrentUnit.driver.available
    ) {
      return driverWithCurrentUnit;
    } else return null;
  });
};

export const getPartnerUnactiveDriversWithTheirCurrentUnitsNotInJourney = (
  partnerDriversWithTheirCurrentUnits: IDriverWithCurrentUnit[],
  journeysInProgress: IJourney[]
): IDriverWithCurrentUnit[] => {
  return partnerDriversWithTheirCurrentUnits.filter((driverWithCurrentUnit) => {
    const driverPresentInOnProgressJourney = journeysInProgress.filter(
      (journey) =>
        journey.driver === driverWithCurrentUnit.driver.id &&
        journey.status === "progress"
    );
    if (
      driverPresentInOnProgressJourney.length === 0 &&
      !driverWithCurrentUnit.driver.available
    ) {
      return driverWithCurrentUnit;
    } else return null;
  });
};

export const getFilteredOutDriversWithTheirCurrentUnits = (
  selectedMapDriverType: MapDriverTypeEnum,
  partnerDriversWithTheirCurrentUnits: IDriverWithCurrentUnit[],
  partnerDriversWithTheirCurrentUnitsInJourney: IDriverWithCurrentUnit[],
  partnerActiveDriversWithTheirUnitsNotInJourney: IDriverWithCurrentUnit[],
  partnerUnactiveDriversWithTheirUnitsNotInJourney: IDriverWithCurrentUnit[]
) => {
  switch (selectedMapDriverType) {
    case MapDriverTypeEnum.ALL:
      return partnerDriversWithTheirCurrentUnits;
    case MapDriverTypeEnum.IN_JOURNEY:
      return partnerDriversWithTheirCurrentUnitsInJourney;
    case MapDriverTypeEnum.ACTIVE:
      return partnerActiveDriversWithTheirUnitsNotInJourney;
    case MapDriverTypeEnum.UNACTIVE:
      return partnerUnactiveDriversWithTheirUnitsNotInJourney;
    default:
      return partnerDriversWithTheirCurrentUnits;
  }
};
