import { IJourney } from "services/resources/firebase/journey/types.d";
import { IUser } from "services/resources/firebase/user/types.d";

export const getStatusColor = (
  shouldShowJourneyStatus: boolean,
  available: boolean
): string => {
  if (shouldShowJourneyStatus) return "yellow.500";
  if (!shouldShowJourneyStatus && available) return "green";
  return "red";
};

export const getDriverJourneyStatus = (
  journeysInProgress: IJourney[],
  driver: IUser
): string => {
  return (
    journeysInProgress.find((journey) => journey.driver === driver.id)
      ?.status || "completed"
  );
};
