import { journeyResources } from "services/resources/firebase/journey";
import { IDriverWithMostJourneys, IJourneyWithUsers } from "./types.d";
import { isNil } from "lodash/fp";
import { userResources } from "services/resources/firebase/user";
import { IUser } from "services/resources/firebase/user/types.d";

export const getDriversWithMostJourneysWithTheirNames = async (
  token: string,
  partnerId?: string
): Promise<IDriverWithMostJourneys[]> => {
  let driversWithMostJourneys: IDriverWithMostJourneys[] = [];
  try {
    const { data: driversWithMostJourneysData } =
      await journeyResources.getDriversWithMostJourneys(token, partnerId);
    if (!isNil(driversWithMostJourneysData)) {
      driversWithMostJourneys = await Promise.all(
        driversWithMostJourneysData.map(async (driverWithMostJourneys) => {
          const { data: driverData } = await userResources.getById(
            driverWithMostJourneys.driver,
            token
          );
          return {
            ...driverWithMostJourneys,
            driverFullName: driverData?.fullName as string,
            driverImg: driverData?.img as string,
          };
        })
      );
    }
  } catch (e) {
    console.error(
      "getDriversWithMostJourneysWithTheirNames: there was an issue getting data."
    );
  }

  return driversWithMostJourneys;
};

export const getJourneysWithUsers = async (
  token: string
): Promise<IJourneyWithUsers[]> => {
  let journeysWithUsers: IJourneyWithUsers[] = [];
  try {
    const { data: journeysData } = await journeyResources.getAll(token);
    if (!isNil(journeysData)) {
      journeysWithUsers = await Promise.all(
        journeysData.map(async (journey) => {
          const { data: passengerData } = await userResources.getById(
            journey.passenger,
            token
          );
          if (!isNil(journey.driver)) {
            const { data: driverData } = await userResources.getById(
              journey.driver,
              token
            );
            return {
              journey: journey,
              passenger: passengerData as IUser,
              driver: driverData as IUser,
            };
          } else
            return {
              journey: journey,
              passenger: passengerData as IUser,
              driver: {} as IUser,
            };
        })
      );
    }
  } catch (e) {
    console.error("getJourneysWithUsers: there was an issue getting data.");
  }

  return journeysWithUsers;
};
