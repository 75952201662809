import { FarePayloadType } from "services/resources/firebase/fare/types.d";
import { object, string, boolean } from "yup";

export const FareFormValidationSchema = object().shape({
  baseFarePerMinute: string().required("Este campo no puede estar vacío."),
  appUsageFare: string().required("Este campo no puede estar vacío."),
  baseFare: string().required("Este campo no puede estar vacío."),
  foreignKilometerFare: string().required("Este campo no puede estar vacío."),
  city: object().shape({
    kilometersRange: string().required("Este campo no puede estar vacío."),
    latitude: string().required("Este campo no puede estar vacío."),
    foreignKilometerRange: string().required(
      "Este campo no puede estar vacío."
    ),
    stateId: string().required("Este campo no puede estar vacío."),
    name: string().required("Este campo no puede estar vacío."),
    enabled: boolean(),
    longitude: string().required("Este campo no puede estar vacío."),
  }),
  maximumCostPerDistance: string().required("Este campo no puede estar vacío."),
  minimumFare: string().required("Este campo no puede estar vacío."),
  maximumRangeForSendingNotifications: string().required(
    "Este campo no puede estar vacío."
  ),
  vehicle: object().shape({
    vehicleImage: string().required("Este campo no puede estar vacío.").url(),
    type: string().required("Este campo no puede estar vacío."),
    enabled: boolean(),
  }),
  maximumPeopleAmount: string().required("Este campo no puede estar vacío."),
  rangePerDistance: string().required("Este campo no puede estar vacío."),
  cancellationFare: string().required("Este campo no puede estar vacío."),
  timeRate: string().required("Este campo no puede estar vacío."),
  distanceRate: string().required("Este campo no puede estar vacío."),
  minimumCostPerDistance: string().required("Este campo no puede estar vacío."),
  demand: string().required("Este campo no puede estar vacío."),
  minimumRangeForSendingNotifications: string().required(
    "Este campo no puede estar vacío."
  ),
  companyId: string().required("Este campo no puede estar vacío."),
  farePerMinute: string().required("Este campo no puede estar vacío."),
  fareType: string().required("Este campo no puede estar vacío."),
  searchDriversRange: string().required("Este campo no puede estar vacío."),
  amountOfDriversToBeNotified: string().required(
    "Este campo no puede estar vacío."
  ),
  newSearchTime: string().required("Este campo no puede estar vacío."),
});

export const INITIAL_VALUES: Partial<FarePayloadType> = {
  baseFarePerMinute: 0,
  appUsageFare: 0,
  baseFare: 28,
  foreignKilometerFare: 10,
  city: {
    kilometersRange: 15,
    latitude: 19.701570104869308,
    foreignKilometerRange: 9,
    stateId: "a69a0737-5b60-4f8e-97b3-30f00f33a781",
    name: "morelia",
    enabled: true,
    longitude: -101.19231,
  },
  maximumCostPerDistance: 0,
  minimumFare: 40,
  maximumRangeForSendingNotifications: 8,
  vehicle: {
    vehicleImage:
      "https://en.wikipedia.org/wiki/Sedan_(automobile)#/media/File:1992_Toyota_Corolla_1.6_sedan_Automatic_rear.jpg",
    type: "standard",
    enabled: true,
  },
  maximumPeopleAmount: 4,
  rangePerDistance: 250,
  cancellationFare: 0,
  timeRate: 0.65,
  distanceRate: 0.85,
  minimumCostPerDistance: 0.87,
  demand: 1,
  minimumRangeForSendingNotifications: 10,
  companyId: "f5c716e0-4d3f-4e09-bc4a-7cc97c3bb5c3",
  farePerMinute: 1.5,
  fareType: "fixed",
  searchDriversRange: 2,
  amountOfDriversToBeNotified: 10,
  newSearchTime: 8,
};
