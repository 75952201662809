import { HStack, Image, StackProps, Text, VStack } from "@chakra-ui/react";
import { USER_FALLBACK_URL } from "helpers/constants/url-constants";
import { IDriverWithMostJourneys } from "services/resources-recipes/types.d";
import { DriverLineWithCircle } from "./DriverLineWithCircle";

interface IDriverWithMostJourneysProps extends StackProps {
  driverWithMostJourneys: IDriverWithMostJourneys;
  index: number;
}

export const DriverWithMostJourneys: React.FC<IDriverWithMostJourneysProps> = (
  props
): JSX.Element => {
  const { driverWithMostJourneys, index, ...rest } = props;

  return (
    <HStack w="100%" {...rest}>
      <DriverLineWithCircle
        h="100px"
        circleColor={index !== 0 ? "gray.200" : "blue.500"}
        w="10%"
      />
      <Image
        w="60px"
        src={driverWithMostJourneys.driverImg}
        fallbackSrc={USER_FALLBACK_URL}
      />
      <VStack alignItems="flex-start" pl={3} spacing="2px">
        <Text>{driverWithMostJourneys.driverFullName}</Text>
        <Text color="gray.500">{`Viajes: ${driverWithMostJourneys.journeyCount}`}</Text>
        <Text color="gray.500">{`Ganancias: $${driverWithMostJourneys.totalProfit.toFixed(
          2
        )}`}</Text>
      </VStack>
    </HStack>
  );
};
