import { Image, StackProps, Text, VStack } from "@chakra-ui/react";
import { FileField } from "components/inputs";
import { DOCUMENT_FALLBACK_URL } from "helpers/constants/url-constants";
import { isNil } from "lodash/fp";

interface IDriverDataPictureProps extends StackProps {
  pictureURL?: string;
  label?: string;
  handleOnImageSelect: (uri: string) => void;
  id: string;
}

export const DriverDataPicture: React.FC<IDriverDataPictureProps> = (
  props
): JSX.Element => {
  const {
    label = "",
    pictureURL = undefined,
    handleOnImageSelect,
    id,
    ...rest
  } = props;

  return (
    <VStack alignItems="flex-start" w="50%" h="100%" p={5} {...rest}>
      <Text fontWeight="bold" py={1}>
        {label}
      </Text>
      <FileField id={id} onFileSelect={handleOnImageSelect} />
      <Image
        mt={3}
        alignSelf="flex-start"
        borderRadius={10}
        src={pictureURL}
        h={!isNil(pictureURL) ? "350px" : "200px"}
        objectFit="contain"
        fallback={
          <Image
            mt={3}
            alignSelf="flex-start"
            borderRadius={10}
            src={DOCUMENT_FALLBACK_URL}
            h={"200px"}
            objectFit="contain"
          />
        }
      />
    </VStack>
  );
};
