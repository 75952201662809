export enum CarBrandEnum {
  BMW = 'BMW',
  AUDI = 'Audi',
  HONDA = 'Honda',
  PROTON = 'Proton',
  TOYOTA = 'Toyota',
  ACURA = 'Acura',
  ALFA_ROMEO = 'Alfa Romeo',
  GENERAL_MOTORS = 'General Motors',
  ASTON_MARTIN = 'Aston Martin',
  BENTLEY = 'Bentley',
  BUGATTI = 'Bugatti',
  BUICK = 'Buick',
  CADILLAC = 'Cadillac',
  CHEVROLET = 'Chevrolet',
  CHRYSLER = 'Chrysler',
  DAEWOO = 'Daewoo',
  DODGE = 'Dodge',
  EAGLE = 'Eagle',
  FIAT = 'FIAT',
  FERRARI = 'Ferrari',
  FISKER = 'Fisker',
  FORD = 'Ford',
  GMC = 'GMC',
  GEO = 'Geo',
  HUMMER = 'HUMMER',
  HYUNDAI = 'Hyundai',
  INFINITI = 'Infiniti',
  ISUZU = 'Isuzu',
  JAGUAR = 'Jaguar',
  JEEP = 'Jeep',
  KIA = 'Kia',
  LAMBORGHINI = 'Lamborghini',
  LAND_ROVER = 'Land Rover',
  LEXUS = 'Lexus',
  LINCOLN = 'Lincoln',
  LOTUS = 'Lotus',
  MINI = 'MINI',
  MAZDA = 'Mazda',
  MCLAREN = 'McLaren',
  MASERATI = 'Maserati',
  MERCEDES_BENZ = 'Mercedes Benz',
  MERCURY = 'Mercury',
  MITSUBISHI = 'Mitsubishi',
  NISSAN = 'Nissan',
  OLDSMOBILE = 'Oldsmobile',
  PANOZ = 'Panoz',
  PLYMOUTH = 'Plymouth',
  PONTIAC = 'Pontiac',
  PORSCHE = 'Porsche',
  RAM = 'Ram',
  ROLLS_ROYCE = 'Rolls Royce',
  SAAB = 'Saab',
  SATURN = 'Saturn',
  SCION = 'Scion',
  SPYKER = 'Spyker',
  SUBARU = 'Subaru',
  SUZUKI = 'Suzuki',
  TESLA = 'Tesla',
  VOLKSWAGEN = 'Volkswagen',
  VOLVO = 'Volvo',
  SMART = 'smart',
  FH = 'fh',
  VOKSVOGAN = 'Voksvogan',
}

const bmwModels = [
  'BMW M sedan',
  'X5',
  'ROLLING SUV',
  'ROLLING BLACK',
  '3 Series',
  '3 Series Gran Turismo',
  '4 Series Gran Coupe',
  '5 Series',
  '5 Series Gran Turismo',
  '6 Series Gran Coupe',
  'BMW 7 Series',
  'ALPINA B6 Gran Coupe',
  'ALPINA B7 LWB',
  'Active Hybrid 5 Base',
  'Active Hybrid 7',
  'ActiveHybrid X6',
  'M2',
  'M5',
  'M6 Gran Coupe',
  'X3',
  'X4',
  'X5 M',
  'X5 eDrive',
  'X6',
  'X6 M',
  'dfhdh',
  'TKD',
];

const audiModels = [
  'Vfff',
  'Audi X4',
  'Audi X7',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'Q3',
  'Q5',
  'Q7',
  'RS 4',
  'RS 7',
  'S3',
  'S4',
  'S7',
  'S6',
  'S8',
  'SQ5',
  'allroad',
  'allroad quattro',
];

const hondaModels = [
  'Accord',
  'CR-V',
  'Civic',
  'Crosstour',
  'Element',
  'Fit',
  'HR V',
  'Odessey',
  'Passport',
  'Insight',
  'Pilot',
  'Prelude',
  'Ridgeline',
];

const toyotaModels = [
  'Avalon',
  '4Runner',
  'Camry',
  'Celica',
  'Corolla',
  'Cressida',
  'ECHO',
  'FJ Cruiser',
  'Highlander',
  'Land  Cruiser',
  'Matrix',
  'Prius',
  'Prius V',
  'Prius C',
  'RAV 4',
  'Sequoia',
  'Sienna',
  'Tacoma',
  'Tundra',
  'Tercel',
  'Venza',
  'Yaris',
];

const acuraModels = [
  'ILX',
  'Integra',
  'Legend',
  'MDX',
  'RDX',
  'RL',
  'RLX',
  'TL',
  'TLX',
  'TSX',
  'TSX Sport Wagon',
];

const protonModels = ['Exora', 'Persona', 'Saga', 'Iswara', 'Waja', 'Wira'];

const alfaRomeoModels = ['4C'];

const generalMotorsModels = ['Hummer'];

const bentleyModels = [
  'Arnage',
  'Azure',
  'Continental Flying Spur',
  'Bentley Flying Spur W12',
  'Mulsanne Base',
];

const buickModels = [
  'Century',
  'Electra',
  'Enclave',
  'Envision',
  'Estate Wagon',
  'LaCrosse',
  'LeSabre',
  'Lucerne',
  'Park Avenue',
  'Rainier',
  'Regal',
  'Rendez vous',
  'Roadmaster',
  'Terraza CXL',
  'Verano',
];

const chevroletModels = [
  'Astro',
  'Avalanche',
  'Aveo',
  'Beretta',
  'Black Diamond Avalanche',
  'Blazer',
  'Camaro',
  'Caprice',
  '2500 Series',
  'Captiva Sport',
  'Cavalier',
  'Celebrity',
  'Chevy Van',
  'City Express',
  'Classic',
  'Cobalt',
  'Colorado 4dr',
  'Cruze',
  'Equinox',
  'Express',
  'HHR',
  'Impala',
  'Lumina',
  'Lumina Minivan',
  'Malibu',
  'Monte Carlo',
  'SS',
  'Silverado 1500',
  'Silverado 2500',
  'Silverado 3500',
  'Sonic LT',
  'Sportvan',
  'Tahoe',
  'TrailBlazer',
  'Traverse',
  'Uplander',
  'Trax',
  'Venture',
  'Volt',
  'Optra',
  'Spark',
  'Chevy',
  'Corsa',
  'Astra',
];

const chryslerModels = [
  '200',
  '300',
  'Aspen',
  '300M',
  'Grand Voyager',
  'Pacifica',
  'Sebring',
  'Voyager',
  'Town Country',
  'Imperial',
  'Concorde',
  'PT Cruiser',
];

const daewooModels = ['Lenos', 'Leganza'];

const dodgeModels = [
  'Grand Caravan',
  'Caravan',
  'Challenger',
  'Charger',
  'Colt',
  'Dakota',
  'Dart',
  'Durango',
  'Intrepid',
  'Journey',
  'Magnum',
  'Neon',
  'Nitro',
  'RAM Pickup',
  'RAM 150',
  'Stratus',
  'Sprinter',
  'Atos',
  'Atitude',
  'i 10',
];

const eagleModels = ['Premier', 'Summit', 'Talon', 'Vision'];

const fiatModels = ['500L', '500X'];

const fordModels = [
  'Aerostar',
  'Edge',
  'Escape',
  'Escort',
  'Excursion',
  'Expedition',
  'Explorer',
  'Explorer Sport',
  'F 150',
  'Explorer Sport Trac',
  'Contour',
  'C Max',
  'Crown Victoria',
  'F 350',
  'E Series Van',
  'Econoline',
  'F 250',
  'Fiesta',
  'Five Hundred',
  'Flex',
  'Focus',
  'Focus RS',
  'Focus ST',
  'Freestar',
  'Festiva',
  'Freestyle',
  'Fusion',
  'Fusion Energi',
  'Fusion Hybrid',
  'Taurus',
  'Taurus X',
  'Windstar',
  'Transit Connect',
  'Transit Van',
  'Monaco',
];

const gmcModels = [
  'Acadia',
  'Canyon',
  'Envoy',
  'Envoy XL',
  'Envoy XUV',
  'Jimmy',
  'R/V 3500 Series',
  'S 15',
  'Savana',
  'Suburban',
  'Yukon',
  'Yukon Denali',
  'Yukon XL',
  'Terrain',
  'Vandura',
  'Sierra',
  'Sierra Classic',
];

const geoModels = ['Metro', 'Prizm', 'Storm', 'Tracker'];

const hummerModels = ['H1', 'H2', 'H2 SUT', 'H1 Alpha', 'H3', 'H3T'];

const hyundaiModels = [
  'Accent',
  'Azera',
  'Elantra',
  'Elantra Coupe',
  'Elantra GT',
  'Elantra Touring',
  'Entourage',
  'Sonata',
  'Santa Fe',
  'Genesis',
  'Tucson',
  'Equus',
  'Veracruz',
  'XG350',
  'Grand i 10',
];

const infinitiModels = [
  'EX',
  'EX35',
  'FX',
  'FX45',
  'FX50',
  'G Sedan',
  'G20',
  'G35',
  'G37',
  'I30',
  'I35',
  'J30',
  'JX',
  'M',
  'M30',
  'M35',
  'M37',
  'M45',
  'M56',
  'Q40',
  'Q45',
  'Q50',
  'Q70',
  'QX',
  'QX4',
  'QX50',
  'QX56',
  'QX60',
  'QX70',
  'QX80',
];

const isuzuModels = [
  'Amigo',
  'Ascender',
  'Rodeo',
  'Trooper',
  'Impulse',
  'i-Series',
  'Pickup 4 drs',
];

const jaguarModels = ['appi'];

const kiaModels = [
  'Amanti',
  'Borrego',
  'Cadenza',
  'Forte',
  'K900 Luxury',
  'Optima',
  'Rio',
  'Rondo',
  'Sedona',
  'Sephia',
  'Sorento',
  'Soul',
  'Soul EV',
  'Spectra',
  'Sportage',
];

const landRoverModels = [
  'Discovery',
  'Discovery Series II',
  'Discovery Sport',
  'Freelander',
  'LR2',
  'LR3',
  'LR4',
  'Range Rover',
  'Range Rover Evoque',
  'Range Rover Sport',
];

const lexusModels = [
  'CT 200h',
  'ES 250',
  'ES 300',
  'ES 300h',
  'ES 330',
  'ES 350',
  'GS 200T',
  'GS 300',
  'GS 350',
  'GS 400',
  'GS 430',
  'GS 450H',
  'GS 460',
  'GS F',
  'GX 460',
  'GX 470',
  'HS 250h',
  'IS 200t',
  'IS 250',
  'IS F',
  'IS 300',
  'IS 350',
  'LS 400',
  'LS 430',
  'LS 460',
  'LS 600h L',
  'LX 450',
  'LX 470',
  'LX 570',
  'NX 200t',
  'NX 300h',
  'RX 300',
  'RX 330',
  'RX 350',
  'RX 400h',
  'RX 450h',
  'SC 300',
  'SC 400',
  'SC 430',
];

const lincolnModels = [
  'Aviator',
  'Navigator',
  'Town Car',
  'Continenta',
  'LS',
  'MKC',
  'MKS',
  'MKT',
  'MKZ',
  'Mark LT',
  'MKZ Hybrid',
  'Navigator L',
];

const mazdaModels = [
  '2',
  '3',
  '5',
  '6',
  '626',
  '929',
  'CX-3',
  'CX-5',
  'CX-7',
  'CX-9',
  'MPV',
  'Mazdaspeed 3',
  'Mazdaspeed Protege',
  'Millenia',
  'Tribute',
  'Protege',
  'Protege 5',
  'Tribute Hybrid',
];

const maseratiModels = ['Ghibli', 'Quattroporte'];

const mercedesBenzModels = ['C-Class', 'E-Class'];

const nissanModels = ['Tiida', 'Tsuru', 'Versa', 'March', 'Sentra', 'Platina'];

const volkswagenModels = ['Gol', 'Jetta', 'Polo', 'Vento', 'Pointer'];

const volvoModels = ['astar'];

export const getBrandModels = (brand: CarBrandEnum): string[] => {
  switch (brand) {
    case CarBrandEnum.BMW:
      return bmwModels;
    case CarBrandEnum.AUDI:
      return audiModels;
    case CarBrandEnum.HONDA:
      return hondaModels;
    case CarBrandEnum.PROTON:
      return protonModels;
    case CarBrandEnum.TOYOTA:
      return toyotaModels;
    case CarBrandEnum.ACURA:
      return acuraModels;
    case CarBrandEnum.ALFA_ROMEO:
      return alfaRomeoModels;
    case CarBrandEnum.GENERAL_MOTORS:
      return generalMotorsModels;
    case CarBrandEnum.BENTLEY:
      return bentleyModels;
    case CarBrandEnum.BUICK:
      return buickModels;
    case CarBrandEnum.CHEVROLET:
      return chevroletModels;
    case CarBrandEnum.CHRYSLER:
      return chryslerModels;
    case CarBrandEnum.DAEWOO:
      return daewooModels;
    case CarBrandEnum.DODGE:
      return dodgeModels;
    case CarBrandEnum.EAGLE:
      return eagleModels;
    case CarBrandEnum.FIAT:
      return fiatModels;
    case CarBrandEnum.FORD:
      return fordModels;
    case CarBrandEnum.GMC:
      return gmcModels;
    case CarBrandEnum.GEO:
      return geoModels;
    case CarBrandEnum.HUMMER:
      return hummerModels;
    case CarBrandEnum.HYUNDAI:
      return hyundaiModels;
    case CarBrandEnum.INFINITI:
      return infinitiModels;
    case CarBrandEnum.ISUZU:
      return isuzuModels;
    case CarBrandEnum.JAGUAR:
      return jaguarModels;
    case CarBrandEnum.KIA:
      return kiaModels;
    case CarBrandEnum.LAND_ROVER:
      return landRoverModels;
    case CarBrandEnum.LEXUS:
      return lexusModels;
    case CarBrandEnum.LINCOLN:
      return lincolnModels;
    case CarBrandEnum.MAZDA:
      return mazdaModels;
    case CarBrandEnum.MASERATI:
      return maseratiModels;
    case CarBrandEnum.MERCEDES_BENZ:
      return mercedesBenzModels;
    case CarBrandEnum.NISSAN:
      return nissanModels;
    case CarBrandEnum.VOLKSWAGEN:
      return volkswagenModels;
    case CarBrandEnum.VOLVO:
      return volvoModels;
    default:
      return [];
  }
};
