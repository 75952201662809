import {
  Center,
  HStack,
  IconButton,
  Image,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { ZUBE_LOGO_URL } from "helpers/constants/url-constants";
import { HiMenuAlt2 } from "react-icons/hi";
import { SessionMenu } from "../SessionMenu";
import { UserCard } from "../UserCard";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { DisplayType } from "../types.d";
import { FaArrowRightLong } from "react-icons/fa6";
import { zubeColors } from "styles/colors";
import { getHeaderName } from "helpers/data-helpers/string-helpers";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { NavMenu } from "../NavMenu";
import { NotificationsPopover } from "./components/NotificationsPopover";
import { useEffect, useState } from "react";
import { minutesToMilliseconds } from "date-fns";
import { isNil } from "lodash/fp";
import { IAdminNotification } from "services/resources/firebase/admin-notification/types.d";
import { adminNotificationsResources } from "services/resources/firebase/admin-notification";
import { markAllAdminNotificationsAsRead } from "services/resources-recipes/admin-notifications";
import { AxiosError } from "axios";

interface INavbarProps extends StackProps {
  onDisplayToggle: () => void;
  onTop: boolean;
  displayType: DisplayType;
  pageHeader?: string;
}

export const Navbar: React.FC<INavbarProps> = (props): JSX.Element => {
  const { pageHeader, onDisplayToggle, displayType, onTop, ...rest } = props;
  const isSmallScreen = useSmallScreen();
  const userData = useSelector(getUser);

  const [adminNotifications, setAdminNotifications] = useState<
    IAdminNotification[]
  >([]);

  const fetchAdminNotifications = async () => {
    try {
      const { data } = await adminNotificationsResources.getAllPending(
        userData.token as string,
        userData.organization
      );
      if (!isNil(data)) {
        setAdminNotifications((previousValues) => {
          // Only update if retrieved data is different from
          // existing state.
          if (JSON.stringify(previousValues) !== JSON.stringify(data)) {
            return data;
          } else return previousValues;
        });
      }
    } catch (e) {
      console.error(
        "fetchAdminNotifications: there was an issue fetching pending notifications"
      );
      if (
        !isNil((e as AxiosError).response?.status) &&
        (e as AxiosError).response?.status === 404
      ) {
        setAdminNotifications([]);
      }
    }
  };

  const handleOnMarkAllAsRead = async (): Promise<void> => {
    await markAllAdminNotificationsAsRead(
      adminNotifications,
      userData.token as string
    );
    await fetchAdminNotifications();
  };

  const handleOnMarkAsRead = async (
    adminNotificationId: string
  ): Promise<void> => {
    try {
      await adminNotificationsResources.updateReadStatus(
        adminNotificationId,
        userData.token as string
      );
      await fetchAdminNotifications();
    } catch (e) {
      console.error(
        "handleOnMarkAsRead: there was an issue marking the notification as read"
      );
    }
  };

  useEffect(() => {
    if (adminNotifications.length === 0) {
      fetchAdminNotifications();
    }

    const intervalId = setInterval(() => {
      fetchAdminNotifications();
    }, minutesToMilliseconds(3)); // Every 3 minutes

    return () => clearInterval(intervalId); // Clean up on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HStack h={onTop && !isSmallScreen ? "100px" : "80px"} {...rest}>
      {!isSmallScreen ? (
        <Center minW="200px">
          <Image src={ZUBE_LOGO_URL} h="40px" alignSelf="center" />
        </Center>
      ) : null}
      {!isSmallScreen ? (
        <IconButton
          bg="transparent"
          onClick={onDisplayToggle}
          aria-label="change-display"
          _hover={{ bg: "transparent" }}
        >
          {displayType === "full" ? (
            <HiMenuAlt2 size={30} />
          ) : (
            <FaArrowRightLong color={zubeColors.zubePurple.dark} size={30} />
          )}
        </IconButton>
      ) : (
        <NavMenu>
          <HiMenuAlt2 color="black" size={30} />
        </NavMenu>
      )}
      <Text
        fontSize={24}
        fontWeight="bold"
        minW={!isSmallScreen ? "40%" : "60%"}
      >
        {!isNil(pageHeader) ? pageHeader : getHeaderName()}
      </Text>
      <NotificationsPopover
        onMarkAllAsRead={handleOnMarkAllAsRead}
        adminNotifications={adminNotifications}
        onMarkAsRead={handleOnMarkAsRead}
      />
      <SessionMenu mr={3}>
        <UserCard
          user={userData}
          displayType={isSmallScreen ? "shrink" : "full"}
        />
      </SessionMenu>
    </HStack>
  );
};

Navbar.defaultProps = {
  w: "100%",
  justifyContent: "space-between",
  pb: 2,
  position: "sticky",
  bg: "white",
  top: 0,
  zIndex: 1,
};
