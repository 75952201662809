import { type IFirebaseResource } from "services/resources/firebase/types.d";
import { type ILocation } from "services/resources/google-apis/types.d";

export enum UserTypeEnum {
  PASSENGER = "passenger",
  UNVERIFIED_DRIVER = "unverifiedDriver",
  PUBLIC_DRIVER = "publicDriver",
  PRIVATE_DRIVER = "privateDriver",
  PARTNER = "partner",
  ADMIN = "admin",
}

export enum OrganizationEnum {
  RTM_S = "RTM_S",
  RTM_M = "RTM_M",
  RTM_A = "RTM_A",
  PRIVATE = "PRIVATE",
  ZUBE = "ZUBE",
}

export interface IUserScore {
  /**
   * The total sum calculated by adding all the received
   * scores.
   */
  cumulativeSum: number;
  /**
   * The total amount of times the score has been given for
   * a user.
   */
  ratingCount: number;
}

export interface IPartnerPlan {
  /**
   * Id of the plan the partner belongs to.
   */
  id: string;
  /**
   * Payment status of the partner.
   */
  status: string;
}

export interface IUser extends IFirebaseResource {
  /**
   * Weather this user is available or not (only applicable to drivers).
   */
  available?: boolean;
  /**
   * The user name which includes first, father and/or mother name.
   */
  fullName: string;
  /**
   * Current location of the user. Should not alway be updated.
   */
  location?: ILocation;
  /**
   * The unique email that belongs to this user.
   */
  email: string;
  /**
   * Mobile phone number that belongs to this user.
   */
  mobilePhoneNumber: string;
  /**
   * Expo device id, used to send push notifications to user's device.
   */
  expoDeviceId?: string;
  /**
   * Total score that has been given to the current user.
   */
  score: IUserScore;
  /**
   * Source where the image of the profile picture for the current user is
   * stored.
   */
  img?: string;
  /**
   * Type of user.
   */
  type: UserTypeEnum;
  /**
   * Stored addresses by the user for ease of access in the trip creation process
   */
  addresses?: IFavoriteAddress[];
  /**
   * Flag that indicates if the user has been verified already
   */
  driverVerified?: boolean;
  /**
   * Flag that indicates if a given user is an admin. To be deprecated by
   * using Firebase custom claims.
   */
  isAdmin?: boolean;
  /**
   * Stripe customer id associated to this user.
   */
  customerId?: string;
  /**
   * Organization of the user (only for partners and public drivers).
   */
  organization?: string;
  /**
   * Vehicle chosen to perform journeys (drivers only).
   */
  selectedUnit?: string;
}

export type UserPayloadType = Omit<IUser, "id" | "created" | "updated">;

export type FoundDriverType = Pick<IUser, "location" | "id" | "expoDeviceId">;

export interface IUserSignupPayload extends UserPayloadType {
  /**
   * User password. Not to be stored in our databases.
   */
  password: string;
}

export interface IUserSignupOptionalPasswordPayload extends UserPayloadType {
  /**
   * User password. Not to be stored in our databases.
   */
  password?: string;
}

export interface IUserWithStats extends IUser {
  journeysSum: number;
  cancelledJourneys: number;
}