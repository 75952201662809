import { HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { isNil } from "lodash/fp";
import React from "react";

interface IDashboardPanelProps extends StackProps {
  label?: string;
  rightElement?: React.ReactNode;
}

export const DashboardPanel: React.FC<IDashboardPanelProps> = (props) => {
  const { children, label, rightElement, ...rest } = props;

  return (
    <VStack {...rest}>
      {!isNil(label) ? (
        <HStack alignItems="center" justifyContent="space-between" w="100%">
          <Text fontWeight="bold">{label}</Text>
          {rightElement ? rightElement : null}
        </HStack>
      ) : null}
      {children}
    </VStack>
  );
};

DashboardPanel.defaultProps = {
  spacing: 3,
  bg: "white",
  p: 5,
  borderRadius: 5,
};
