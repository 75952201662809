import {
  Button,
  Divider,
  HStack,
  Image,
  Select,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { InputField } from "components/inputs";
import { format, secondsToMinutes } from "date-fns";
import { es } from "date-fns/locale/es";
import { isNil } from "lodash/fp";
import { ReactNode, useState } from "react";
import { IJourneyWithUsers } from "services/resources-recipes/types.d";
import { zubeColors } from "styles/colors";

interface IJourneyDetailUIProps extends StackProps {
  getStatus: (status: string) => { color: string; label: string };
  handleOnReturn: () => void;
  handleOnSubmit: (
    resolvedStatus: boolean,
    resolvedComment: string
  ) => Promise<void>;
  journeyWithUsers: IJourneyWithUsers;
}

export const JourneyDetailsUI: React.FC<IJourneyDetailUIProps> = (
  props
): JSX.Element => {
  const {
    getStatus,
    handleOnReturn,
    handleOnSubmit,
    journeyWithUsers: { journey, passenger },
    ...rest
  } = props;
  const [resolvedStatus, setResolvedStatus] = useState<boolean>(
    journey.report?.resolved || false
  );
  const [resolvedComment, setResolvedComment] = useState<string>(
    journey.report?.resolvedComments || ""
  );

  const getFinalFare = (): string => {
    let finalFare = 0;
    if (isNil(journey.discount)) {
      finalFare = journey.fare + journey.extraFee;
    } else {
      finalFare = journey.fare + journey.extraFee + journey.discount;
    }
    return `$${finalFare.toFixed(2)}`;
  };

  const getCanceledBy = (): string => {
    switch (journey.status) {
      case "created":
        return "Tiempo";
      case "canceled":
        return journey.comments ? "Pasajero" : "Usuario";
      default:
        return "";
    }
  };

  const getCanceledReason = (): string => {
    switch (journey.status) {
      case "created":
        return "Ningún conductor aceptó el viaje.";
      case "canceled":
        return journey.comments ? journey.comments : "";
      default:
        return "";
    }
  };

  const getReportAttachment = (): ReactNode => {
    if (!journey.report?.fileType) {
      return <Text>El usuario no adjuntó evidencia</Text>;
    } else {
      switch (journey.report.fileType) {
        case "image":
          return <Image src={journey.report.attachment} w="40%" />;
        case "video":
          return <video controls src={journey.report.attachment} width="40%" />;
        default:
          return <></>;
      }
    }
  };

  return (
    <>
      <HStack justifyContent="flex-end" mb={5} w="100%">
        <Button
          bg={zubeColors.locationBlue}
          borderRadius={20}
          onClick={handleOnReturn}
        >
          Regresar
        </Button>
      </HStack>
      <VStack
        p={5}
        alignItems="flex-start"
        borderRadius={10}
        bgColor="white"
        {...rest}
      >
        <HStack mb={5}>
          <Text fontSize={20}>Detalles del Viaje | </Text>
          <Text
            bg={getStatus(journey.status).color}
            borderRadius={16}
            color="white"
            textAlign="center"
            px={2}
            py={2}
          >
            {getStatus(journey.status).label}
          </Text>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" w="50%">
            <Text style={{ fontWeight: "bold" }}>Número de Viaje</Text>
            <Text>{journey.id}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Tarifa</Text>
            <Text>{getFinalFare()}</Text>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" minW="50%">
            <Text style={{ fontWeight: "bold" }}>Punto de partida</Text>
            <Text>{journey.roundTripAddresses.origin}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Destino</Text>
            <Text>{journey.roundTripAddresses.destination}</Text>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" w="50%">
            <Text style={{ fontWeight: "bold" }}>
              Fecha & Hora de solicitud
            </Text>
            <Text>
              {format(journey.created._seconds * 1000, "MMMM d yyyy, h:mm a", {
                locale: es,
              })}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Tipo de pago</Text>
            <Text>
              {journey.paymentMethod === "cash" ? "Efectivo" : "Tarjeta"}
            </Text>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" w="50%">
            <Text style={{ fontWeight: "bold" }}>Duración</Text>
            <Text>{`${secondsToMinutes(journey.durationInSeconds)} Min`}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Distancia</Text>
            <Text>{`${(journey.distanceInMeters / 1000).toFixed(2)} Km`}</Text>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" minW="50%">
            <Text style={{ fontWeight: "bold" }}>Cancelado por:</Text>
            <Text>{getCanceledBy()}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Motivo de cancelación</Text>
            <Text>{getCanceledReason()}</Text>
          </VStack>
        </HStack>
        <Divider
          borderWidth={1.5}
          borderColor={zubeColors.zubeGray.dark}
          mt={5}
        />
        <Text fontSize={20}>Detalles del Pasajero</Text>
        <HStack w="100%">
          <VStack alignItems="flex-start" w="50%">
            <Text style={{ fontWeight: "bold" }}>Nombre</Text>
            <Text>{passenger.fullName}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Teléfono</Text>
            <Text>{passenger.mobilePhoneNumber}</Text>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" w="50%">
            <Text style={{ fontWeight: "bold" }}>Correo</Text>
            <Text>{passenger.email}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Plataforma</Text>
            <Text>Zube Pasajero</Text>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="flex-start" w="50%">
            <Text style={{ fontWeight: "bold" }}>Calificación actual</Text>
            <Text>
              {(
                passenger.score.cumulativeSum / passenger.score.ratingCount
              ).toFixed(2)}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text style={{ fontWeight: "bold" }}>Comentario del pasajero</Text>
            <Text>
              {journey.comments ||
                "El pasajero aún no ha calificado al conductor"}
            </Text>
          </VStack>
        </HStack>
        {!isNil(journey.report) && (
          <>
            <Divider
              borderWidth={1.5}
              borderColor={zubeColors.zubeGray.dark}
              mt={5}
            />
            <Text fontSize={20}>Detalles del Reporte</Text>
            <HStack w="100%">
              <VStack alignItems="flex-start" w="50%">
                <Text style={{ fontWeight: "bold" }}>ID</Text>
                <Text>{journey.report.id}</Text>
              </VStack>
              <VStack alignItems="flex-start">
                <Text style={{ fontWeight: "bold" }}>Fecha</Text>
                <Text>
                  {format(new Date(journey.report.created), "dd MMM yyyy", {
                    locale: es,
                  })}
                </Text>
              </VStack>
            </HStack>
            <HStack alignItems="flex-start" w="100%">
              <VStack alignItems="flex-start" minW="50%">
                <Text style={{ fontWeight: "bold" }}>Descripción</Text>
                <Text>{journey.report.description}</Text>
              </VStack>
              <VStack alignItems="flex-start">
                <Text style={{ fontWeight: "bold" }}>Adjunto</Text>
                {getReportAttachment()}
              </VStack>
            </HStack>
            <HStack alignItems="flex-start" w="100%">
              <VStack alignItems="flex-start" w="50%">
                <Text style={{ fontWeight: "bold" }}>Estatus</Text>
                <Select
                  mb={4}
                  onChange={(e) => {
                    setResolvedStatus(
                      e.target.selectedOptions[0].value === "resolved"
                        ? true
                        : false
                    );
                  }}
                  placeholder="Selecciona un estatus"
                  value={resolvedStatus ? "resolved" : "pending"}
                >
                  <option value="resolved">Resuelto</option>
                  <option value="pending">Pendiente</option>
                </Select>
              </VStack>
              <VStack alignItems="flex-start" w="50%">
                <Text style={{ fontWeight: "bold" }}>
                  Explicación al pasajero
                </Text>
                <InputField
                  onChangeText={(t) => setResolvedComment(t)}
                  type="text"
                  value={resolvedComment}
                />
              </VStack>
            </HStack>
            <HStack justifyContent="flex-end" mb={5} w="100%">
              <Button
                bg={zubeColors.zubeGreen}
                borderRadius={20}
                onClick={() => handleOnSubmit(resolvedStatus, resolvedComment)}
              >
                Guardar
              </Button>
            </HStack>
          </>
        )}
      </VStack>
    </>
  );
};
