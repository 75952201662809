import { BsSpeedometer, BsPersonRaisedHand } from "react-icons/bs";
import { FaBug, FaClipboard, FaRoad } from "react-icons/fa";
import { BiSolidDiscount, BiSolidUserBadge } from "react-icons/bi";
import {
  FaTaxi,
  FaUsers,
  FaMapLocationDot,
  FaHandshakeSimple,
  FaChartLine,
} from "react-icons/fa6";
import { DisplayType } from "components/elements/types.d";
import { PanelLink } from "./PanelLink";
import { MdAttachMoney } from "react-icons/md";

interface IAdminLinksProps {
  displayType: DisplayType;
}

export const AdminLinks: React.FC<IAdminLinksProps> = (props): JSX.Element => {
  const { displayType } = props;

  return (
    <>
      <PanelLink
        icon={<BsSpeedometer size={24} />}
        to="/dashboard"
        displayType={displayType}
      >
        Dashboard
      </PanelLink>
      <PanelLink
        icon={<FaUsers size={24} />}
        to="/users"
        displayType={displayType}
      >
        Usuarios
      </PanelLink>
      <PanelLink
        icon={<FaClipboard size={24} />}
        to="/requests"
        displayType={displayType}
      >
        Solicitudes
      </PanelLink>
      <PanelLink
        icon={<BiSolidUserBadge size={24} />}
        to="/drivers"
        displayType={displayType}
      >
        Conductores
      </PanelLink>
      <PanelLink
        icon={<BsPersonRaisedHand size={24} />}
        to="/passengers"
        displayType={displayType}
      >
        Pasajeros
      </PanelLink>
      <PanelLink
        icon={<FaMapLocationDot size={24} />}
        to="/map"
        displayType={displayType}
      >
        Mapa
      </PanelLink>
      <PanelLink
        icon={<FaTaxi size={24} />}
        to="/units"
        displayType={displayType}
      >
        Vehículos
      </PanelLink>
      <PanelLink
        icon={<FaHandshakeSimple size={24} />}
        to="/partners"
        displayType={displayType}
      >
        Partners
      </PanelLink>
      <PanelLink
        icon={<FaRoad size={24} />}
        to="/journeys"
        displayType={displayType}
      >
        Viajes
      </PanelLink>
      <PanelLink
        icon={<MdAttachMoney size={24} />}
        to="/fares"
        displayType={displayType}
      >
        Tarifas
      </PanelLink>
      <PanelLink
        icon={<FaChartLine size={24} />}
        to="/plans"
        displayType={displayType}
      >
        Planes
      </PanelLink>
      <PanelLink
        icon={<BiSolidDiscount size={24} />}
        to="/coupons"
        displayType={displayType}
      >
        Cupones
      </PanelLink>
      <PanelLink
        icon={<FaBug size={24} />}
        to="/reports"
        displayType={displayType}
      >
        Reportes
      </PanelLink>
    </>
  );
};
