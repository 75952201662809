import { Button, ButtonProps } from "@chakra-ui/react";
import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";

interface IDriverFilterButtonProps extends ButtonProps {
  onSelectDriverStatusType: (
    driverStatusType: VerificationStatusEnum | "ALL"
  ) => void;
  label: string;
  itemCount: number;
  driverStatusType: VerificationStatusEnum | "ALL";
  isFocused: boolean;
}

export const DriverFilterButton: React.FC<IDriverFilterButtonProps> = (
  props
): JSX.Element => {
  const {
    onSelectDriverStatusType,
    label,
    itemCount,
    driverStatusType,
    isFocused,
    ...rest
  } = props;

  return (
    <Button
      bg="transparent"
      fontWeight={isFocused ? "bold" : "normal"}
      color={isFocused ? "gray.700" : "gray.300"}
      {...rest}
      onClick={() => onSelectDriverStatusType(driverStatusType)}
      _hover={{ bg: "transparent" }}
    >{`${label} (${itemCount})`}</Button>
  );
};
