import { StackProps } from "@chakra-ui/react";
import { IRequestWithDriver } from "services/resources-recipes/types.d";
import { DriverAppointment } from "./DriverAppointment";
import { DashboardPanel } from "components/elements";

interface IAppointmentsProps extends StackProps {
  driversWithRequests: IRequestWithDriver[];
}

export const Appointments: React.FC<IAppointmentsProps> = (
  props
): JSX.Element => {
  const { driversWithRequests, ...rest } = props;

  return (
    <DashboardPanel label="Citas" {...rest}>
      {driversWithRequests.map((driverWithRequest) => (
        <DriverAppointment
          driverWithRequest={driverWithRequest}
          key={driverWithRequest.request.id}
        />
      ))}
    </DashboardPanel>
  );
};
