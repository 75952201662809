import { isNil } from "lodash/fp";

import { auth } from "../resources/firebase/auth";

export const getToken = async (): Promise<string> => {
  let tokenResult = "";
  if (!isNil(auth.currentUser)) {
    tokenResult = await auth.currentUser.getIdToken();
  }
  return tokenResult;
};
