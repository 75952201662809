import {
  Circle,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { isEmpty } from "lodash/fp";

import { IInputFieldProps } from "../types.d";

interface IMapRangeInputFieldProps extends IInputFieldProps {
  /**
   * Color to be displayed next to the label.
   */
  colorHelper: string;
}

export const MapRangeInputField: React.FC<IMapRangeInputFieldProps> = (
  props
): JSX.Element => {
  const {
    label = "",
    error = "",
    customPlaceholder = "",
    onChangeText,
    description = "",
    colorHelper,
    ...rest
  } = props;

  return (
    <FormControl minH="45px" isInvalid={!isEmpty(error)} mb={4} w="100%">
      {!isEmpty(label) ? (
        <HStack alignItems="center" pb={3}>
          <Text fontSize={14} fontWeight="bold">
            {label}
          </Text>
          <Circle bg={colorHelper} size={3} />
        </HStack>
      ) : null}
      <Input
        onChange={({ target: { value: v } }) => {
          onChangeText(v);
        }}
        placeholder={customPlaceholder}
        size="md"
        {...rest}
      />
      {!isEmpty(description) ? (
        <Text pt={1} fontSize={12} color="gray">
          {description}
        </Text>
      ) : null}
      {!isEmpty(error) ? (
        <HStack justifyContent="center">
          <FormErrorMessage>
            <FormErrorIcon color="salmon" h={4} ml={3} />
            {error}
          </FormErrorMessage>
        </HStack>
      ) : null}
    </FormControl>
  );
};
