import { userResources } from "services/resources/firebase/user";
import { IDriverWithRequestAndUnit } from "./types.d";
import { isNil } from "lodash/fp";
import { unitResources } from "services/resources/firebase/unit";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import { IUnit } from "services/resources/firebase/unit/types.d";

export const getDriversWithRequestsAndUnits = async (
  token: string,
  organization: undefined | string = undefined
): Promise<IDriverWithRequestAndUnit[]> => {
  let retrievedDriversWithRequestsAndUnits: IDriverWithRequestAndUnit[] = [];
  try {
    const { data: driversData } = isNil(organization)
      ? await userResources.getDrivers(token)
      : await userResources.getDriversByOrganization(organization, token);
    if (!isNil(driversData)) {
      await Promise.allSettled(
        driversData.map(async (driver) => {
          let unitsData: IUnit[] = [];
          try {
            const { data: retrievedUnitsData } =
              await unitResources.getDriverUnits(driver.id, token);
            unitsData = !isNil(retrievedUnitsData) ? retrievedUnitsData : [];
          } catch (e) {
            console.error(
              "getAllDriversWithRequestsAndUnits: there was an issue getting driver units"
            );
          }
          const { data: requestData } = await driverRequestResources.getById(
            driver.id,
            token
          );
          if (!isNil(unitsData) && !isNil(requestData)) {
            retrievedDriversWithRequestsAndUnits.push({
              units: unitsData,
              driver,
              request: requestData,
            });
          }
          return;
        })
      );
    }
  } catch (e) {
    console.error(
      "getAllDriversWithRequestsAndUnits: there was an issue retrieving drivers / units data."
    );
  }
  return retrievedDriversWithRequestsAndUnits;
};

export const getDriverWithRequestAndUnitByDriverId = async (
  driverId: string,
  token: string
): Promise<IDriverWithRequestAndUnit | undefined> => {
  let retrievedDriverWithRequestAndUnit: IDriverWithRequestAndUnit | undefined =
    undefined;
  try {
    const { data: driverData } = await userResources.getById(driverId, token);
    if (!isNil(driverData)) {
      let unitsData: IUnit[] = [];
      try {
        const { data: retrievedUnitsData } = await unitResources.getDriverUnits(
          driverId,
          token
        );
        unitsData = !isNil(retrievedUnitsData) ? retrievedUnitsData : [];
      } catch (e) {
        console.error(
          "getDriversWithRequestAndUnitByDriverId: there was an issue getting driver units"
        );
      }
      const { data: requestData } = await driverRequestResources.getById(
        driverId,
        token
      );
      if (!isNil(unitsData) && !isNil(requestData)) {
        retrievedDriverWithRequestAndUnit = {
          units: unitsData,
          driver: driverData,
          request: requestData,
        };
      }
    }
  } catch (e) {
    console.error(
      "getDriversWithRequestAndUnitByDriverId: there was an issue retrieving drivers / units data."
    );
  }
  return retrievedDriverWithRequestAndUnit;
};
