import { StackProps, VStack } from "@chakra-ui/react";
import { MapDriverTypeEnum } from "../types.d";
import { DriverTypeButton } from "./DriverTypeButton";

interface IDriverTypeSelectorProps extends StackProps {
  handleOnDriverTypeSelect: (selection: MapDriverTypeEnum) => void;
  allCount: number;
  activeCount: number;
  unactiveCount: number;
  onJourneyCount: number;
}

export const DriverTypeSelector: React.FC<IDriverTypeSelectorProps> = (
  props
): JSX.Element => {
  const {
    handleOnDriverTypeSelect,
    allCount,
    activeCount,
    unactiveCount,
    onJourneyCount,
    ...rest
  } = props;

  return (
    <VStack alignItems="flex-start" w="100%" pl={9} {...rest}>
      <DriverTypeButton
        label="Todos"
        circleColor="gray.300"
        count={allCount}
        onClick={() => handleOnDriverTypeSelect(MapDriverTypeEnum.ALL)}
      />
      <DriverTypeButton
        label="Activos"
        circleColor="green"
        count={activeCount}
        onClick={() => handleOnDriverTypeSelect(MapDriverTypeEnum.ACTIVE)}
      />
      <DriverTypeButton
        label="Inactivos"
        circleColor="red"
        count={unactiveCount}
        onClick={() => handleOnDriverTypeSelect(MapDriverTypeEnum.UNACTIVE)}
      />
      <DriverTypeButton
        label="En viaje"
        circleColor="yellow.500"
        count={onJourneyCount}
        onClick={() => handleOnDriverTypeSelect(MapDriverTypeEnum.IN_JOURNEY)}
      />
    </VStack>
  );
};
