import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Circle } from "@react-google-maps/api";
import {
  FarePayloadType,
  IFare,
} from "services/resources/firebase/fare/types.d";
import { fareResources } from "services/resources/firebase/fare";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { isNil } from "lodash/fp";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { FareForm } from "components/forms/FareForm";
import {
  convertPayloadToMatchType,
  getFormDifferences,
} from "helpers/data-helpers/form-helpers";
import { MAPS_API_KEY } from "helpers/constants/string-contants";
import { IFareCircle } from "./types.d";
import {
  DEFAULT_CIRCLE_OPTIONS,
  DEFAULT_MAP_SETTINGS,
} from "helpers/constants/data-constants";
import { zubeColors } from "styles/colors";

export const Fares: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [fare, setFare] = useState<IFare | undefined>(undefined);
  const [fareCircles, setFareCircles] = useState<IFareCircle[]>([]);

  const handleFareCircleSettings = (fare: IFare) => {
    const fareCirclesSnapshot = [...fareCircles];
    fareCirclesSnapshot.push({
      type: "city.foreignKilometersRange",
      options: {
        ...DEFAULT_CIRCLE_OPTIONS,
        fillColor: zubeColors.zubePurple.dark,
        strokeColor: zubeColors.zubePurple.dark,
        fillOpacity: 0.15,
        radius: fare.city.foreignKilometerRange * 1000,
      },
    });
    fareCirclesSnapshot.push({
      type: "rangePerDistance",
      options: {
        ...DEFAULT_CIRCLE_OPTIONS,
        fillColor: zubeColors.zubeGreen,
        strokeColor: zubeColors.zubeGreen,
        fillOpacity: 0.15,
        radius: fare.rangePerDistance,
      },
    });
    fareCirclesSnapshot.push({
      type: "minimumRangeForSendingNotifications",
      options: {
        ...DEFAULT_CIRCLE_OPTIONS,
        fillColor: zubeColors.zubeOrange.dark,
        strokeColor: zubeColors.zubeOrange.dark,
        fillOpacity: 0.15,
        radius: fare.minimumRangeForSendingNotifications * 1000,
      },
    });
    fareCirclesSnapshot.push({
      type: "maximumRangeForSendingNotifications",
      options: {
        ...DEFAULT_CIRCLE_OPTIONS,
        fillColor: zubeColors.zubeOrange.light,
        strokeColor: zubeColors.zubeOrange.light,
        fillOpacity: 0.15,
        radius: fare.maximumRangeForSendingNotifications * 1000,
      },
    });
    fareCirclesSnapshot.push({
      type: "searchDriversRange",
      options: {
        ...DEFAULT_CIRCLE_OPTIONS,
        fillColor: zubeColors.zubePurple.light,
        strokeColor: zubeColors.zubePurple.light,
        radius: fare.searchDriversRange * 1000,
      },
    });
    setFareCircles(fareCirclesSnapshot);
  };

  const handleOnFaresGet = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await fareResources.getByVehicleType(
        "standard",
        token as string
      );
      if (!isNil(data)) {
        setFare(data);
        handleFareCircleSettings(data);
      }
    } catch (e) {
      console.error("Error-handleOnFareGet: ", e);
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnFareEdit = async (payload: Partial<FarePayloadType>) => {
    setLoadingModalVisible(true);
    try {
      await fareResources.patchFare(
        fare?.id as string,
        convertPayloadToMatchType<Partial<FarePayloadType>>(
          getFormDifferences(fare as IFare, payload)
        ),
        token as string
      );
      await handleOnFaresGet();
    } catch (e) {
      console.error(
        "handleOnFareEdit: there was an issue while trying to update the fare"
      );
    } finally {
      setLoadingModalVisible(false);
    }
  };

  useEffect(() => {
    handleOnFaresGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Tarifas"
      loadingModalVisible={loadingModalVisible}
    >
      <VStack
        h="100%"
        alignItems="center"
        justifyContent="center"
        overflowY="scroll"
      >
        {!isNil(fare) ? (
          <Grid
            templateColumns={`repeat(${isSmallScreen ? 1 : 2}, 1fr)`}
            gap={3}
            w="90%"
            h="90%"
          >
            <GridItem>
              <FareForm
                previousValues={fare}
                handleOnSubmit={handleOnFareEdit}
              />
            </GridItem>
            <GridItem w="100%" h="50%" borderRadius="lg">
              <LoadScript googleMapsApiKey={MAPS_API_KEY}>
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  center={
                    isNil(fare)
                      ? DEFAULT_MAP_SETTINGS.center
                      : { lat: fare.city.latitude, lng: fare.city.longitude }
                  }
                  zoom={DEFAULT_MAP_SETTINGS.zoom}
                >
                  {fareCircles.map(({ options, type }) => (
                    <Circle
                      center={{
                        lat: fare.city.latitude,
                        lng: fare.city.longitude,
                      }}
                      options={options}
                      key={type}
                    />
                  ))}
                </GoogleMap>
              </LoadScript>
            </GridItem>
          </Grid>
        ) : null}
      </VStack>
    </PageContainer>
  );
};
