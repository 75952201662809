import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import {
  IUserWithStats,
  UserTypeEnum,
  type IUser,
  type UserPayloadType,
} from "./types.d";

const USERS_API_URL = "/usersAPIv2";

export const userResources = {
  create: (
    user: UserPayloadType,
    token: string
  ): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.post(
      `${USERS_API_URL}`,
      { user },
      {
        headers: { Authorization: token },
      }
    ),

  createAdmin: (
    user: Partial<UserPayloadType>,
    token: string
  ): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.post(
      `${USERS_API_URL}/partner`,
      { user },
      {
        headers: { Authorization: token },
      }
    ),

  delete: (userId: string, token: string): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/delete-account`,
      {},
      {
        headers: { Authorization: token },
        params: { userId },
      }
    ),

  enableDriver: (
    userId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/enable-driver`,
      {},
      {
        headers: { Authorization: token },
        params: { userId },
      }
    ),

  getAdmins: (token: string): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/query-where`, {
      headers: { Authorization: token },
      params: {
        field: "type",
        condition: "in",
        value: [UserTypeEnum.ADMIN, UserTypeEnum.PARTNER],
      },
    }),

  getAll: (token: string): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/`, {
      headers: { Authorization: token },
    }),

  getDriversByOrganization: (
    organization: string,
    token: string
  ): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/drivers-by-organization`, {
      headers: { Authorization: token },
      params: { organization },
    }),

  getPrivateDrivers: (token: string): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/drivers-private`, {
      headers: { Authorization: token },
    }),

  getByEmail: (email: string, token: string): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.get(`${USERS_API_URL}/by-email`, {
      headers: { Authorization: token },
      params: { email },
    }),

  getById: (id: string, token: string): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.get(`${USERS_API_URL}/by-id`, {
      headers: { Authorization: token },
      params: { id },
    }),

  getDrivers: (token: string): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/drivers`, {
      headers: { Authorization: token },
    }),

  getPassengersWithStats: (
    token: string
  ): Promise<IAxiosResponse<IUserWithStats[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/passengers-with-stats`, {
      headers: { Authorization: token },
    }),

  getPartnerAdmins: async (org: string, token: string): Promise<IUser[]> => {
    const { data } = await firebaseInstance.get(
      `${USERS_API_URL}/query-where`,
      {
        headers: { Authorization: token },
        params: { field: "organization", condition: "==", value: org },
      }
    );
    return (data as IUser[]).filter(
      (user) => user.type === UserTypeEnum.PARTNER
    );
  },

  getPartnerDrivers: async (org: string, token: string): Promise<IUser[]> => {
    const { data } = await firebaseInstance.get(
      `${USERS_API_URL}/query-where`,
      {
        headers: { Authorization: token },
        params: { field: "organization", condition: "==", value: org },
      }
    );
    return (data as IUser[]).filter(
      (user) => user.type === UserTypeEnum.PUBLIC_DRIVER
    );
  },

  queryByPage: (
    page: string,
    token: string
  ): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/query`, {
      headers: { Authorization: token },
      params: { page },
    }),

  getByMobilePhoneNumber: (
    mobilePhoneNumber: string,
    token: string
  ): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.get(`${USERS_API_URL}/by-mobile-phone-number`, {
      headers: { Authorization: token },
      params: { mobilePhoneNumber },
    }),

  patchAdminUser: (
    userPayload: Partial<UserPayloadType>,
    userId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/partner`,
      { userPayload },
      {
        headers: { Authorization: token },
        params: { id: userId },
      }
    ),

  patchAvailableStatus: (
    newStatus: boolean,
    userId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/available-status`,
      { status: newStatus },
      {
        headers: { Authorization: token },
        params: { id: userId },
      }
    ),

  patchExpoDeviceId: (
    expoDeviceId: string,
    userId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/expo-device-id`,
      { expoDeviceId },
      {
        headers: { Authorization: token },
        params: { id: userId },
      }
    ),

  patchEmail: (
    email: string,
    userId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/profile-field`,
      { field: "email", value: email, userId },
      {
        headers: { Authorization: token },
      }
    ),

  patchLocation: (
    userId: string,
    location: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/location`,
      { location },
      { headers: { Authorization: token }, params: { id: userId } }
    ),

  patchName: (newName: string, userId: string, token: string): Promise<void> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/profile-field`,
      { field: "fullName", value: newName, userId },
      {
        headers: { Authorization: token },
      }
    ),

  patchScore: (
    targetUserId: string,
    score: number,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/score`,
      { score },
      {
        headers: { Authorization: token },
        params: { id: targetUserId },
      }
    ),

  patchProfilePicture: (
    pictureURL: string,
    userId: string,
    token: string
  ): Promise<void> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/profile-picture`,
      {},
      {
        headers: { authorization: token },
        params: { id: userId, picture: pictureURL },
      }
    ),

  patchDriverUser: async (
    targetUserId: string,
    user: UserPayloadType,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    await firebaseInstance.patch(
      // We are pointing to /partner endpoint, since this allows us
      // to update all required user data. For the moment, this is a
      // workaround
      `${USERS_API_URL}/partner`,
      { userPayload: { ...user } },
      {
        headers: { authorization: token },
        params: { id: targetUserId },
      }
    ),
};
