import {
  VStack,
  Text,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverProps,
  IconButton,
  Button,
  Link,
  Circle,
} from "@chakra-ui/react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { CustomTimestamp } from "services/resources/firebase/types.d";
import { LiaBellSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { zubeColors } from "styles/colors";
import { IAdminNotification } from "services/resources/firebase/admin-notification/types.d";

interface INotificationPopoverProps extends PopoverProps {
  adminNotifications: IAdminNotification[];
  onMarkAllAsRead: () => Promise<void>;
  onMarkAsRead: (adminNotificationId: string) => Promise<void>;
}

export const NotificationsPopover: React.FC<INotificationPopoverProps> = (
  props
): JSX.Element => {
  const { adminNotifications, onMarkAllAsRead, onMarkAsRead, ...rest } = props;

  const navigate = useNavigate();

  const [bellPosition, setBellPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const bellRef = useRef<HTMLButtonElement | null>(null);

  const getDisplayDate = (created: CustomTimestamp): string => {
    const today = new Date();
    const createdDate = new Date(created._seconds * 1000);
    const minutes = differenceInMinutes(today, createdDate);
    const hours = differenceInHours(today, createdDate);
    const days = differenceInDays(today, createdDate);
    const months = differenceInMonths(today, createdDate);
    const years = differenceInYears(today, createdDate);

    if (minutes >= 1 && hours < 1) {
      return `hace ${minutes} minutos`;
    } else if (hours >= 1 && days < 1) {
      return `hace ${hours} horas`;
    } else if (days >= 1 && months < 1) {
      return `hace ${days} días`;
    } else if (months >= 1 && years < 1) {
      return `hace ${months} meses`;
    } else if (years >= 1) {
      return `hace ${years} años`;
    } else {
      return "ahora";
    }
  };

  const handleNavigation = (
    route: string,
    resourceId: string,
    adminNotificationId: string
  ): void => {
    onMarkAsRead(adminNotificationId);
    // Navigate to the desired route
    navigate(`/${route}/${resourceId}`);
  };

  useEffect(() => {
    if (bellRef.current) {
      const { x, y } = bellRef.current.getBoundingClientRect();
      setBellPosition({ x, y });
    }
  }, []);

  return (
    <Popover {...rest}>
      {bellPosition.x !== 0 && bellPosition.y !== 0 ? (
        <Circle
          bg={zubeColors.zubePurple.dark}
          position="absolute"
          left={`${bellPosition.x + 25}px`}
          top={`${bellPosition.y - 10}px`}
          alignItems="center"
          justifyContent="center"
          p={1}
        >
          <Text textAlign="center" w="25px" h="25px" color="white">
            {adminNotifications.length}
          </Text>
        </Circle>
      ) : null}
      <PopoverTrigger>
        <IconButton
          bg="transparent"
          aria-label="notifications"
          _hover={{ bg: "transparent" }}
          marginRight="-80px"
          ref={bellRef}
        >
          <LiaBellSolid size={30} />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent maxW="280px" maxH="80vh" overflowY="scroll">
        <PopoverBody w="100%">
          <VStack alignItems="flex-start" w="100%">
            {adminNotifications.map((notification) => (
              <Link
                key={notification.id}
                as="button"
                onClick={() =>
                  handleNavigation(
                    notification.route,
                    notification.resourceId,
                    notification.id
                  )
                }
              >
                <VStack alignItems="flex-start" w="100%" pt={3}>
                  <Text textAlign="left" fontSize={14}>
                    {notification.content}
                  </Text>
                  <Text color="gray" fontSize={12} pb={3}>
                    {getDisplayDate(notification.created)}
                  </Text>
                  <Divider w="100%" bg="gray" />
                </VStack>
              </Link>
            ))}
            <Button
              fontSize={14}
              w="100%"
              bg="transparent"
              onClick={onMarkAllAsRead}
            >
              Marcar todas como leídas
            </Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
