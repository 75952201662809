import { decode } from "base-64";
import { Buffer } from "buffer";
import { isNil } from "lodash/fp";
import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import {
  IJourneyReport,
  type IJourney,
  type JourneyPayloadType,
} from "./types.d";
import { IDriverWithMostJourneysStats } from "services/resources-recipes/types.d";

const JOURNEY_API_URL = "/journeysAPI";

export const journeyResources = {
  create: async (
    journey: JourneyPayloadType,
    token: string
  ): Promise<{ data: IJourney | undefined }> => {
    let data;
    const { data: axiosData } = await firebaseInstance.post(
      `${JOURNEY_API_URL}`,
      { journey },
      {
        headers: { Authorization: token },
      }
    );
    if (!isNil(axiosData)) data = JSON.parse(decode(axiosData as string));
    return { data };
  },

  getAll: async (token: string): Promise<{ data: IJourney[] }> => {
    const data: IJourney[] = [];
    const { data: axiosData } = await firebaseInstance.get(
      `${JOURNEY_API_URL}/`,
      {
        headers: { Authorization: token },
      }
    );
    if (!isNil(axiosData)) {
      (axiosData as string[]).forEach((journey) => {
        const decodedJourney = Buffer.from(journey, "base64").toString("utf-8");
        data.push(JSON.parse(decodedJourney) as IJourney);
      });
    }
    return { data };
  },

  getById: async (
    id: string,
    token: string
  ): Promise<{ data: IJourney | undefined }> => {
    let data;
    const { data: axiosData } = await firebaseInstance.get(
      `${JOURNEY_API_URL}/by-id`,
      {
        headers: { Authorization: token },
        params: { id },
      }
    );
    if (!isNil(axiosData)) {
      const decoded = Buffer.from(axiosData as string, "base64").toString();
      data = JSON.parse(decoded);
    }
    return { data };
  },

  patchDriver: (
    driver: string,
    journeyId: string,
    token: string
  ): Promise<IAxiosResponse<unknown>> =>
    firebaseInstance.patch(
      `${JOURNEY_API_URL}/driver`,
      { driver },
      {
        headers: { Authorization: token },
        params: { id: journeyId },
      }
    ),

  patchFareOffset: (
    originalFare: string,
    journeyId: string,
    discount: string,
    extra: string,
    token: string
  ): Promise<IAxiosResponse<IJourney>> =>
    firebaseInstance.patch(
      `${JOURNEY_API_URL}/fare-offset`,
      {},
      {
        headers: { Authorization: token },
        params: { discount, extra, id: journeyId, originalFare },
      }
    ),

  patchReport: async (
    journeyId: string,
    report: IJourneyReport,
    token: string
  ): Promise<IAxiosResponse<unknown>> => {
    return await firebaseInstance.patch(
      `${JOURNEY_API_URL}/journey-report`,
      { report },
      {
        headers: { Authorization: token },
        params: { journeyId },
      }
    );
  },

  patchStatus: (
    status: string,
    journeyId: string,
    token: string
  ): Promise<IAxiosResponse<unknown>> =>
    firebaseInstance.patch(
      `${JOURNEY_API_URL}/status`,
      { status },
      {
        headers: { Authorization: token },
        params: { id: journeyId },
      }
    ),

  queryByDriver: async (
    id: string,
    page: string,
    token: string
  ): Promise<{ data: IJourney[] }> => {
    const data: IJourney[] = [];
    const { data: axiosData } = await firebaseInstance.get(
      `${JOURNEY_API_URL}/query-by-driver`,
      {
        headers: { Authorization: token },
        params: { id, page },
      }
    );
    if (!isNil(axiosData)) {
      (axiosData as string[]).forEach((journey) => {
        const decodedJourney = Buffer.from(journey, "base64").toString("utf-8");
        data.push(JSON.parse(decodedJourney) as IJourney);
      });
    }
    return { data };
  },

  getDriversWithMostJourneys: async (
    token: string,
    partnerId?: string
  ): Promise<{ data: IDriverWithMostJourneysStats[] }> =>
    firebaseInstance.get(`${JOURNEY_API_URL}/drivers-with-most-journeys`, {
      headers: { Authorization: token },
      params: { partnerId },
    }),

  queryByStatus: async (
    status: string,
    token: string
  ): Promise<{ data: IJourney[] }> => {
    const data: IJourney[] = [];
    const { data: axiosData } = await firebaseInstance.get(
      `${JOURNEY_API_URL}/query-by-status`,
      {
        headers: { Authorization: token },
        params: { status },
      }
    );
    if (!isNil(axiosData)) {
      (axiosData as string[]).forEach((journey) => {
        const decodedJourney = Buffer.from(journey, "base64").toString("utf-8");
        data.push(JSON.parse(decodedJourney) as IJourney);
      });
    }
    return { data };
  },

  queryByDrivers: async (
    ids: string[],
    token: string
  ): Promise<{ data: IJourney[] }> => {
    const data: IJourney[] = [];
    const { data: axiosData } = await firebaseInstance.get(
      `${JOURNEY_API_URL}/query-by-drivers`,
      {
        headers: { Authorization: token },
        params: { ids },
      }
    );
    if (!isNil(axiosData)) {
      (axiosData as string[]).forEach((journey) => {
        const decodedJourney = Buffer.from(journey, "base64").toString("utf-8");
        data.push(JSON.parse(decodedJourney) as IJourney);
      });
    }
    return { data };
  },
};
