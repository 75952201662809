import {
  Button,
  Center,
  Divider,
  HStack,
  Grid,
  GridItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { format } from "date-fns";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "redux-service/slices";
import { IRequestWithDriver } from "services/resources-recipes/types.d";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";
import { userResources } from "services/resources/firebase/user";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { zubeColors } from "styles/colors";

export const Requests: React.FC = (): JSX.Element => {
  const { token, type, organization } = useSelector(getUser);
  const navigate = useNavigate();
  const isSmallScreen = useSmallScreen();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [requestsWithDrivers, setRequestsWithDrivers] = useState<
    IRequestWithDriver[]
  >([]);

  const getStatus = (
    status: VerificationStatusEnum
  ): { color: string; label: string } => {
    switch (status) {
      case VerificationStatusEnum.PENDING:
        return { color: zubeColors.zubeGray.dark, label: "Pendiente" };
      case VerificationStatusEnum.SUBMITTED:
        return { color: "#ffa755", label: "En revisión" };
      case VerificationStatusEnum.REJECTED:
        return { color: "red", label: "Rechazado" };
      case VerificationStatusEnum.APPROVED:
        return { color: "green", label: "Aprobado" };
      case VerificationStatusEnum.APPOINTMENT:
        return { color: "#D653C1", label: "Cita" };
      case VerificationStatusEnum.COMPLETED:
        return { color: zubeColors.locationBlue, label: "Completado" };
      default:
        return { color: "", label: "" };
    }
  };

  const retrieveDriverRequests = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data: requestsData } =
        type === UserTypeEnum.ADMIN
          ? await driverRequestResources.getAll(token as string)
          : await driverRequestResources.getByOrganizationId(
              organization as string,
              token as string
            );
      if (!isNil(requestsData)) {
        requestsData.forEach(async (request) => {
          // each request doc has the same id as the related driver
          const { data: userData } = await userResources.getById(
            request.id,
            token as string
          );
          if (!isNil(userData)) {
            setRequestsWithDrivers((prev) => [
              ...prev,
              { driver: userData, request },
            ]);
          }
        });
      }
    } catch (e) {
      console.error("Error-retrieveDriverRequests: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeleteRequest = async (requestId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await driverRequestResources.delete(requestId, token as string);
    } catch (e) {
      console.error("Error-handleOnDeleteRequest: ", e);
      window.alert("Ocurrió un error al eliminar la solicitud.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const columns: ITypedListTableColumn<IRequestWithDriver>[] = [
    {
      accessorKey: "date",
      header: "Fecha De Solicitud",
      cell: ({ row }) => (
        <Text>
          {format(
            new Date(row.original.request.created._seconds * 1000),
            "yyyy-MM-dd p"
          )}
        </Text>
      ),
    },
    {
      accessorKey: "fullName",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.driver.fullName}</Text>,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => <Text>{row.original.driver.email}</Text>,
    },
    {
      accessorKey: "phone",
      header: "Teléfono",
      cell: ({ row }) => <Text>{row.original.driver.mobilePhoneNumber}</Text>,
    },
    {
      accessorKey: "status",
      header: "Estatus",
      cell: ({ row }) => (
        <Text
          bg={getStatus(row.original.request.status).color}
          borderRadius={16}
          color="white"
          textAlign="center"
          px={2}
          py={2}
        >
          {getStatus(row.original.request.status).label}
        </Text>
      ),
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            onClick={() =>
              navigate(`/review-request/${row.original.request.id}`)
            }
            rounded="full"
            fontSize={12}
          >
            Editar
          </Button>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeleteRequest(row.original.request.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </VStack>
      ),
    },
  ];

  useEffect(() => {
    retrieveDriverRequests();
    return () => setRequestsWithDrivers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Solicitudes"
      loadingModalVisible={loadingModalVisible}
    >
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {requestsWithDrivers.map((request, index) => (
              <GridItem key={index}>
                <DashboardPanel label={request.driver.fullName}>
                  <VStack w="100%">
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Email
                      </Text>
                      <Text>{request.driver.email}</Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Teléfono
                      </Text>
                      <Text>{request.driver.mobilePhoneNumber}</Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Estatus
                      </Text>
                      <Text
                        bg={getStatus(request.request.status).color}
                        borderRadius={16}
                        color="white"
                        textAlign="center"
                        px={2}
                        py={2}
                      >
                        {getStatus(request.request.status).label}
                      </Text>
                    </HStack>
                    <Divider />
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        onClick={() =>
                          navigate(`/review-request/${request.request.id}`)
                        }
                        rounded="full"
                        fontSize={12}
                      >
                        Editar
                      </Button>
                      <Button
                        bg="red"
                        color="white"
                        fontSize={12}
                        onClick={() =>
                          handleOnDeleteRequest(request.request.id)
                        }
                        rounded="full"
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="100%" alignSelf="center">
          <Center bg="white" w="95%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={requestsWithDrivers}
                columns={columns as ColumnDef<object>[]}
                w="95%"
                h="95%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
