import {
  Button,
  Divider,
  HStack,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { isEmpty, isNil } from "lodash/fp";
import { zubeColors } from "styles/colors";
import {
  INITIAL_VALUES,
  DriverFormValidationSchema,
} from "./helpers/form-helpers";
import { IDriverWithRequestAndUnitsPayload } from "services/resources-recipes/types.d";
import { DriverData } from "./components/DriverData";
import { IUnit } from "services/resources/firebase/unit/types.d";
import { DriverDataPicture } from "./components/DriverDataPicture";
import { DoubleSidedDocument } from "./components/DoubleSidedDocument";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";

interface IPartnerFormProps extends StackProps {
  /**
   * Units that the backoffice user can asign to the driver.
   */
  availableUnits: IUnit[];
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (payload: IDriverWithRequestAndUnitsPayload) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: IDriverWithRequestAndUnitsPayload;
  /**
   * Whether the form is being used for creation or
   * update.
   */
  isCreating?: boolean;
  existingPartners: IPartner[];
}

export const DriverForm: React.FC<IPartnerFormProps> = (props) => {
  const {
    availableUnits,
    handleOnSubmit,
    onScreenChange,
    previousValues,
    isCreating = false,
    existingPartners,
    ...rest
  } = props;

  const { type, isAdmin: isDbAdmin } = useSelector(getUser);

  const isAdmin = type === UserTypeEnum.ADMIN || isDbAdmin;

  const getPartnerUnits = (
    values: IDriverWithRequestAndUnitsPayload
  ): IUnit[] => {
    let outputUnits = [...availableUnits];
    if (
      isAdmin &&
      !isNil(values.driver.organization) &&
      !isEmpty(values.driver.organization)
    ) {
      outputUnits = availableUnits.filter(
        (unit) => unit.partnerId === values.driver.organization
      );
    }
    if (
      isAdmin &&
      !isNil(values.driver.organization) &&
      isEmpty(values.driver.organization)
    ) {
      outputUnits = availableUnits.filter(
        (unit) => isEmpty(unit.partnerId) || isNil(unit.partnerId)
      );
    }
    return outputUnits;
  };

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={DriverFormValidationSchema}
    >
      {({ errors, handleSubmit, isValid, setFieldValue, values }) => (
        <VStack w="100%" {...rest}>
          <VStack
            w="100%"
            bg="white"
            alignItems="flex-start"
            borderRadius="xl"
            p={5}
          >
            <DriverDataPicture
              id="profilePicture"
              label="Imagen de perfil *"
              pictureURL={values.driver.img}
              handleOnImageSelect={(imageUri) =>
                setFieldValue("driver.img", imageUri)
              }
            />
            <Divider w="100%" h={1} />
            <DriverData
              availableUnits={getPartnerUnits(values)}
              driverWithRequestData={values}
              setFieldValue={setFieldValue}
              isCreating={isCreating}
              existingPartners={existingPartners}
            />
          </VStack>
          <HStack w="100%">
            <DoubleSidedDocument
              label="Licencia de manejo"
              document={values.request.driverLicense.document}
              handleOnDocumentUpdate={(updatedDocument) =>
                setFieldValue("request.driverLicense.document", updatedDocument)
              }
              w="50%"
              bg="white"
              alignItems="flex-start"
              borderRadius="xl"
              p={5}
              showExpirationInput
              handleOnDocumentExpiryDateChange={(expirationDate) =>
                setFieldValue(
                  "request.driverLicense.expirationDate",
                  new Date(expirationDate)
                )
              }
            />
            <DoubleSidedDocument
              label="INE"
              document={values.request.idCard.document}
              handleOnDocumentUpdate={(updatedDocument) =>
                setFieldValue("request.idCard.document", updatedDocument)
              }
              w="50%"
              bg="white"
              alignItems="flex-start"
              borderRadius="xl"
              p={5}
            />
          </HStack>
          <Stack
            alignItems="flex-end"
            mt={3}
            w="100%"
            bg="white"
            p={5}
            borderRadius={10}
          >
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={
                !isEmpty(errors) ||
                !isValid ||
                JSON.stringify(previousValues) === JSON.stringify(values)
              }
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="10%"
              color="white"
            >
              Guardar
            </Button>
          </Stack>
        </VStack>
      )}
    </Formik>
  );
};
