import { BsSpeedometer } from "react-icons/bs";
import { FaClipboard, FaRoad } from "react-icons/fa";
import { BiSolidUserBadge } from "react-icons/bi";
import { FaTaxi, FaUsers, FaMapLocationDot } from "react-icons/fa6";
import { DisplayType } from "components/elements/types.d";
import { PanelLink } from "./PanelLink";

interface IPartnerLinksProps {
  displayType: DisplayType;
}

export const PartnerLinks: React.FC<IPartnerLinksProps> = (
  props
): JSX.Element => {
  const { displayType } = props;

  return (
    <>
      <PanelLink
        icon={<BsSpeedometer size={24} />}
        to="/dashboard"
        displayType={displayType}
      >
        Dashboard
      </PanelLink>
      <PanelLink
        icon={<FaUsers size={24} />}
        to="/users"
        displayType={displayType}
      >
        Usuarios
      </PanelLink>
      <PanelLink
        icon={<FaClipboard size={24} />}
        to="/requests"
        displayType={displayType}
      >
        Solicitudes
      </PanelLink>
      <PanelLink
        icon={<BiSolidUserBadge size={24} />}
        to="/drivers"
        displayType={displayType}
      >
        Conductores
      </PanelLink>
      <PanelLink
        icon={<FaMapLocationDot size={24} />}
        to="/map"
        displayType={displayType}
      >
        Mapa
      </PanelLink>
      <PanelLink
        icon={<FaTaxi size={24} />}
        to="/units"
        displayType={displayType}
      >
        Vehículos
      </PanelLink>
      <PanelLink
        icon={<FaRoad size={24} />}
        to="/journeys"
        displayType={displayType}
      >
        Viajes
      </PanelLink>
    </>
  );
};
