import { CustomTimestamp, IFirebaseResource } from "../types.d";

export enum DriverTypeEnum {
  PRIVATE = "private",
  PUBLIC = "public",
  UNSELECTED = "unselected",
}

export enum VerificationStatusEnum {
  PENDING = "pending",
  SUBMITTED = "submitted",
  REJECTED = "rejected",
  APPROVED = "approved",
  APPOINTMENT = "appointment",
  COMPLETED = "completed",
}

export enum TaxiOrgEnum {
  RTM_S = "RTM_S",
  RTM_M = "RTM_M",
  RTM_A = "RTM_A",
  UNSELECTED = "UNSELECTED",
}

export enum DriverRequestDocumentTypeEnum {
  controlCard = "controlCard",
  idCard = "idCard",
  driverLicense = "driverLicense",
  insurance = "insurance",
  circulationCard = "circulationCard",
  vehicleInfo = "vehicleInfo",
}

export interface IVehicleInfo {
  /**
   * The registered id that identifies the vehicle in the Mexican
   * Movility Secretary.
   */
  plates: string;
  /**
   * The brand-specific model that identifies the vehicle.
   */
  model: string;
  /**
   * The manufacturer brand that built the vehicle.
   */
  brand: string;
  /**
   * In which year was the vehicle built at.
   */
  year: string;
}

export interface IDoubledSidedPicture {
  /**
   * The URL that contains the stored document (for the
   * front side).
   */
  front: string;
  /**
   * The URL that contains the stored document (for the
   * back side).
   */
  back: string;
}

export interface IVerifyingStage {
  /**
   * The status of the verification of a given document.
   */
  status: VerificationStatusEnum;
  /**
   * Comments that complement the verification stage of a
   * given document. Can provide extra information of the
   * rejection of a document, for example.
   */
  comment?: string;
}

export interface IVerifyingDocument<DocType> extends IVerifyingStage {
  /**
   * The document type that was uploaded to the request.
   * Sometimes is about a single URL that point to the stored
   * resource, and sometimes is about front and back cases.
   * Applies for most of stages but not for all.
   */
  document: DocType;
  /**
   * Date when the document is set to be expired (not valid anymore).
   */
  expirationDate: CustomTimestamp | Date | string;
}

export interface IVerifyingVehicleForm<DocType>
  extends Omit<IVerifyingDocument<DocType>, "expirationDate"> {
  /**
   * The form that contains vehicle info such as brand, model, etc.
   */
  form: IVehicleInfo;
}

export interface IRequestFieldStage<FieldType>
  extends Omit<IVerifyingStage, "comment"> {
  /**
   * Actual value of the type which is not a form or a
   * document (simple field) that will be stored.
   */
  field: FieldType;
}

export interface IDriverRequest extends IFirebaseResource {
  /**
   * To be documented.
   */
  controlCard?: IVerifyingDocument<string>;
  /**
   * Either if the driver is public or private.
   */
  driverType: IRequestFieldStage<DriverTypeEnum>;
  /**
   * To which taxi organization does the public driver belongs to.
   */
  organization?: IRequestFieldStage<string>;
  /**
   * Mexican Id picture stage.
   */
  idCard: IVerifyingDocument<IDoubledSidedPicture>;
  /**
   * Mexican driver license stage.
   */
  driverLicense: IVerifyingDocument<IDoubledSidedPicture>;
  /**
   * Automovile insurance stage.
   */
  insurance: IVerifyingDocument<string>;
  /**
   * Circulation card stage.
   */
  circulationCard?: IVerifyingDocument<string>;
  /**
   * Driver's vehicle stage.
   */
  vehicleInfo: IVerifyingVehicleForm<string>;
  /**
   * Id that corresponds to the driver which is making the
   * current request.
   */
  driverId: string;
  /**
   * Status that corresponds to the request as a whole.
   */
  status: VerificationStatusEnum;
  /**
   * When did the appointment was scheduled.
   */
  appointmentDate?: CustomTimestamp | Date | string;
  /**
   * Whether the appointment already took place or not.
   */
  appointmentCompleted?: boolean;
}

export type DriverRequestPayload = Omit<
  IDriverRequest,
  "id" | "created" | "updated"
>;

export type DoubleSidedDocumentSide = "front" | "back";
