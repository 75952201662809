import { firebaseInstance } from "services/resources/firebase/instance";
import { type IAxiosResponse } from "services/types.d";

import { getToken } from "../../../resources-recipes/auth";
import {
  IFirebaseNotification,
  type IZubeExpoNotification,
  IZubeNotificationData,
  NotificationPayloadType,
} from "./types";

const NOTIFICATIONS_API_URL = "/notificationsAPI";

export const notificationsResources = {
  create: async (
    payload: NotificationPayloadType
  ): Promise<IAxiosResponse<unknown>> => {
    const latestToken = await getToken();
    return await firebaseInstance.post(
      NOTIFICATIONS_API_URL,
      { notification: payload },
      {
        headers: { authorization: latestToken },
      }
    );
  },

  get: async (
    user: string
  ): Promise<IAxiosResponse<IZubeExpoNotification[]>> => {
    const latestToken = await getToken();
    return await firebaseInstance.get(NOTIFICATIONS_API_URL, {
      headers: { authorization: latestToken },
      params: { user },
    });
  },

  getJourneyNotification: async (
    journeyId: string
  ): Promise<IAxiosResponse<IZubeNotificationData>> => {
    const latestToken = await getToken();
    return await firebaseInstance.get(
      `${NOTIFICATIONS_API_URL}/journey-notification`,
      {
        headers: { authorization: latestToken },
        params: { id: journeyId },
      }
    );
  },

  patchRead: async (notification: string): Promise<void> => {
    const latestToken = await getToken();
    return await firebaseInstance.patch(
      NOTIFICATIONS_API_URL,
      {},
      {
        headers: { authorization: latestToken },
        params: { notification },
      }
    );
  },

  queryByTarget: async (
    id: string,
    page: string
  ): Promise<IAxiosResponse<IFirebaseNotification[]>> => {
    const latestToken = await getToken();
    return await firebaseInstance.get(
      `${NOTIFICATIONS_API_URL}/query-by-target`,
      {
        headers: { Authorization: latestToken },
        params: { id, page },
      }
    );
  },
};
