import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { DashboardTable, PageContainer } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { userResources } from "services/resources/firebase/user";
import { IUserWithStats } from "services/resources/firebase/user/types.d";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { USER_FALLBACK_URL } from "helpers/constants/url-constants";
import { zubeColors } from "styles/colors";
import { saveAs } from "file-saver";
import * as Papa from "papaparse";
import { format } from "date-fns";

export const Passengers: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [passengersWithStats, setPassengersWithStats] = useState<
    IUserWithStats[]
  >([]);

  const handleOnPassengersGet = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await userResources.getPassengersWithStats(
        token as string
      );
      if (!isNil(data)) {
        setPassengersWithStats(data);
      }
    } catch (e) {
      console.error("Error-handleOnPassengersGet: ", e);
    }
    setLoadingModalVisible(false);
  };

  const handleOnExportToCsv = (): void => {
    const dataToBeExported = passengersWithStats.map((passengerWithStats) => {
      const {
        fullName,
        email,
        mobilePhoneNumber,
        journeysSum,
        cancelledJourneys,
      } = passengerWithStats;
      return {
        fullName,
        email,
        mobilePhoneNumber,
        journeysSum,
        cancelledJourneys,
      };
    });
    const csv = Papa.unparse(dataToBeExported, { header: false });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(
      blob,
      `zube-pasajeros-${format(new Date(), "dd-MM-yyyy-HH-mm-ss")}.csv`
    );
  };

  const columns: ITypedListTableColumn<IUserWithStats>[] = [
    {
      accessorKey: "img",
      header: "Imagen",
      cell: ({ row }) => (
        <Box maxH="70px">
          <Image
            src={row.original.img}
            h="70px"
            fallbackSrc={USER_FALLBACK_URL}
          />
        </Box>
      ),
    },
    {
      accessorKey: "fullName",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.fullName}</Text>,
    },
    {
      accessorKey: "contactData",
      header: "Datos de contacto",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <HStack>
            <MdEmail />
            <Text>{row.original.email}</Text>
          </HStack>
          <HStack>
            <FaPhoneAlt />
            <Text>{row.original.mobilePhoneNumber}</Text>
          </HStack>
        </VStack>
      ),
    },
    {
      accessorKey: "status",
      header: "Estatus",
      cell: () => <Text>Activo</Text>,
    },
    {
      accessorKey: "journeysSum",
      header: "Total de viajes",
      cell: ({ row }) => (
        <Text textAlign="center">{row.original.journeysSum}</Text>
      ),
    },
    {
      accessorKey: "cancelledJourneys",
      header: "Viajes cancelados",
      cell: ({ row }) => (
        <Text textAlign="center">{row.original.cancelledJourneys}</Text>
      ),
    },
  ];

  useEffect(() => {
    handleOnPassengersGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Pasajeros"
      loadingModalVisible={loadingModalVisible}
    >
      <HStack justifyContent="flex-end" w="95%" pb={5}>
        <Button
          bg={zubeColors.zubeGreen}
          borderRadius="full"
          color="white"
          mt={5}
          onClick={handleOnExportToCsv}
        >
          Exportar en EXCEL
        </Button>
      </HStack>
      <Center bg="transparent" w="100%" h="90%" alignSelf="center">
        <Center bg="white" w="100%" h="100%" borderRadius={5}>
          {columns.length > 0 ? (
            <DashboardTable
              data={passengersWithStats}
              columns={columns as ColumnDef<object>[]}
              w="90%"
              h="90%"
              py={5}
            />
          ) : null}
        </Center>
      </Center>
    </PageContainer>
  );
};
