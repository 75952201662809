import { adminNotificationsResources } from "services/resources/firebase/admin-notification";
import { IAdminNotification } from "services/resources/firebase/admin-notification/types.d";

export const markAllAdminNotificationsAsRead = async (
  fetchedAdminNotifications: IAdminNotification[],
  token: string
): Promise<void> => {
  try {
    await Promise.allSettled(
      fetchedAdminNotifications.map(async (adminNotification) => {
        try {
          return await adminNotificationsResources.updateReadStatus(
            adminNotification.id,
            token
          );
        } catch (e) {
          console.error(
            "markAllAdminNotificationsAsRead: there was an issue marking notification ",
            adminNotification.id,
            " as read"
          );
        }
      })
    );
  } catch (e) {
    console.error(
      "markAllAdminNotificationsAsRead: there was an issue marking all notifications as read"
    );
  }
};
