import { StorageReference } from "firebase/storage";
import { createBlob } from "helpers/data-helpers/blob-helpers";
import { isEmpty, isNil } from "lodash/fp";
import { DoubleSidedDocumentSide } from "services/resources/firebase/driver-request/types.d";
import { storageResources } from "services/resources/firebase/storage";
import {
  IUnit,
  UnitPayloadType,
} from "services/resources/firebase/unit/types.d";
import {
  IDriverWithRequestAndUnit,
  IDriverWithRequestAndUnitsPayload,
} from "./types.d";

export const handleUploadDriverIdCard = async (
  imageURI: string,
  side: DoubleSidedDocumentSide,
  userEmail: string
): Promise<string> => {
  let imageURL = "";
  try {
    if (!isEmpty(imageURI)) {
      // Create blob from ImagePicker uri result
      const blob = await createBlob(imageURI);
      // Get reference from storage file upload
      const { ref } = await storageResources.createDriverDocument(
        userEmail,
        `driver-id-${side}`,
        blob
      );
      // Get storage file URL
      imageURL = await storageResources.getPictureURLByRef(ref);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error-handleUploadDriverId: ", e);
  }

  return imageURL;
};

export const handleUploadDriverLicense = async (
  imageURI: string,
  side: DoubleSidedDocumentSide,
  userEmail: string
): Promise<string> => {
  let imageURL = "";
  try {
    if (!isEmpty(imageURI)) {
      // Create blob from ImagePicker uri result
      const blob = await createBlob(imageURI);
      // Get reference from storage file upload
      const { ref } = await storageResources.createDriverDocument(
        userEmail,
        `license-${side}`,
        blob
      );
      // Get storage file URL
      imageURL = await storageResources.getPictureURLByRef(ref);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error-handleUploadDriverLicense: ", e);
  }

  return imageURL;
};

export const handleUnitImagesUpload = async (
  unitId: string,
  payload: UnitPayloadType,
  selectedUnit: IUnit
): Promise<
  Pick<
    UnitPayloadType,
    "image" | "insurance" | "circulationCard" | "controlCard"
  >
> => {
  // Extract desired properties
  const { image, insurance, controlCard, circulationCard } = payload;
  // Create an object with target properties
  const imagesUrls: Pick<
    UnitPayloadType,
    "image" | "insurance" | "circulationCard" | "controlCard"
  > = { image, insurance, controlCard, circulationCard };
  // Create variables for references
  let imageRef: StorageReference | undefined = undefined;
  let controlCardRef: StorageReference | undefined = undefined;
  let circulationCardRef: StorageReference | undefined = undefined;
  let insuranceRef: StorageReference | undefined = undefined;
  try {
    // Get references and URLs from storage file upload (only apply operations
    // if the payload changed from the original unit data)
    if (selectedUnit?.image !== image) {
      const imageBlob = await createBlob(image);
      imageRef = await storageResources.createUnitPicture(unitId, imageBlob);
      imagesUrls.image = await storageResources.getPictureURLByRef(imageRef);
    }
    if (selectedUnit?.circulationCard?.document !== circulationCard?.document) {
      const circulationCardBlob = await createBlob(
        circulationCard?.document as string
      );
      circulationCardRef = await storageResources.createUnitPicture(
        unitId,
        circulationCardBlob
      );
      imagesUrls.circulationCard!.document =
        await storageResources.getPictureURLByRef(circulationCardRef);
    }
    if (selectedUnit?.insurance?.document !== insurance?.document) {
      const insuranceBlob = await createBlob(insurance?.document as string);
      insuranceRef = await storageResources.createUnitPicture(
        unitId,
        insuranceBlob
      );
      imagesUrls.insurance.document = await storageResources.getPictureURLByRef(
        insuranceRef
      );
    }
    if (selectedUnit?.controlCard?.document !== controlCard?.document) {
      const controlCardBlob = await createBlob(controlCard?.document as string);
      controlCardRef = await storageResources.createUnitPicture(
        unitId,
        controlCardBlob
      );
      imagesUrls.controlCard!.document =
        await storageResources.getPictureURLByRef(controlCardRef);
    }
  } catch (e) {
    console.error(
      "handleUnitImagesUpload: there was an issue uploading images."
    );
  }
  return imagesUrls;
};

export const handleDriverAndRequestImagesUpload = async (
  payload: IDriverWithRequestAndUnitsPayload,
  selectedDriverWithRequestAndUnit: IDriverWithRequestAndUnit,
  isCreating = false
): Promise<Pick<IDriverWithRequestAndUnitsPayload, "driver" | "request">> => {
  // Extract desired properties
  const { driver, request } = payload;
  // Create an object with target properties
  const imagesUrls: Pick<
    IDriverWithRequestAndUnitsPayload,
    "driver" | "request"
  > = { driver, request };
  try {
    // Get references and URLs from storage file upload (only apply operations
    // if the payload changed from the original unit data)
    if (
      (selectedDriverWithRequestAndUnit?.driver.img !== driver.img &&
        !isNil(driver.img)) ||
      (!isNil(driver.img) && isCreating)
    ) {
      const driverImageBlob = await createBlob(driver.img);
      const { ref } = await storageResources.createUserPicture(
        !isCreating
          ? (selectedDriverWithRequestAndUnit?.driver.email as string)
          : driver.email,
        driverImageBlob
      );
      imagesUrls.driver.img = await storageResources.getPictureURLByRef(ref);
    }
    if (
      selectedDriverWithRequestAndUnit?.request.driverLicense.document !==
        request.driverLicense.document ||
      isCreating
    ) {
      const licenseDriverFrontBlob = await createBlob(
        request.driverLicense.document.front
      );
      const licenseDriverBackBlob = await createBlob(
        request.driverLicense.document.back
      );
      const { ref: licenseDriverFrontRef } =
        await storageResources.createDriverDocument(
          driver.email,
          `license-front`,
          licenseDriverFrontBlob
        );
      const { ref: licenseDriverBackRef } =
        await storageResources.createDriverDocument(
          driver.email,
          `license-back`,
          licenseDriverBackBlob
        );
      imagesUrls.request.driverLicense.document.front =
        await storageResources.getPictureURLByRef(licenseDriverFrontRef);
      imagesUrls.request.driverLicense.document.back =
        await storageResources.getPictureURLByRef(licenseDriverBackRef);
    }
    if (
      selectedDriverWithRequestAndUnit?.request.idCard.document !==
        request.idCard.document ||
      isCreating
    ) {
      const idCardFrontBlob = await createBlob(request.idCard.document.front);
      const idCardBackBlob = await createBlob(request.idCard.document.back);
      const { ref: idCardFrontRef } =
        await storageResources.createDriverDocument(
          driver.email,
          `driver-id-front`,
          idCardFrontBlob
        );
      const { ref: idCardBackRef } =
        await storageResources.createDriverDocument(
          driver.email,
          `driver-id-back`,
          idCardBackBlob
        );
      imagesUrls.request.idCard.document.front =
        await storageResources.getPictureURLByRef(idCardFrontRef);
      imagesUrls.request.idCard.document.back =
        await storageResources.getPictureURLByRef(idCardBackRef);
    }
  } catch (e) {
    console.error(
      "handleDriverAndRequestImagesUpload: there was an issue uploading images."
    );
  }
  return imagesUrls;
};
