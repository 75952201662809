import {
  Stack,
  StackProps,
  VStack,
  Grid,
  GridItem,
  Image,
  Divider,
  HStack,
  Text,
  Center,
  Input,
} from "@chakra-ui/react";
import { isNil } from "lodash/fp";
import { IDoubledSidedPicture } from "services/resources/firebase/driver-request/types.d";
import { LicenseModal } from "./LicenseModal";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { IDriverWithRequestAndUnit } from "services/resources-recipes/types.d";
import { DashboardPanel, DashboardTable } from "components/elements";
import { format } from "date-fns";
import { ColumnDef } from "@tanstack/react-table";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { useState } from "react";

interface IDriversUIProps extends StackProps {
  openedLicenseSources: IDoubledSidedPicture | undefined;
  openedLicense: undefined | string;
  setOpenedLicense: (newValue: undefined | string) => void;
  driversWithRequestsAndUnits: IDriverWithRequestAndUnit[];
  columns: ITypedListTableColumn<IDriverWithRequestAndUnit>[];
}

export const DriversUI: React.FC<IDriversUIProps> = (props) => {
  const {
    driversWithRequestsAndUnits,
    setOpenedLicense,
    openedLicense,
    openedLicenseSources,
    columns,
    ...rest
  } = props;

  const isSmallScreen = useSmallScreen();

  const [search, setSearch] = useState<string>("");

  const filteredDrivers = driversWithRequestsAndUnits.filter(
    (driverWithRequestAndUnit) =>
      driverWithRequestAndUnit.driver.fullName
        .toLowerCase()
        .includes(search.toLowerCase())
  );

  return (
    <Stack bg="white" borderRadius={5} {...rest}>
      <Input
        borderColor="black"
        w="20%"
        placeholder="conductor"
        ml={5}
        my={5}
        onChange={(e) => setSearch(e.target.value)}
      />
      {!isNil(openedLicenseSources) ? (
        <LicenseModal
          isOpen={!isNil(openedLicense)}
          imageSources={openedLicenseSources}
          onClose={() => setOpenedLicense(undefined)}
        />
      ) : null}
      {isSmallScreen ? (
        <VStack w="100%" alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {filteredDrivers.map((driverWithRequestsAndUnit, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={driverWithRequestsAndUnit.driver.fullName}
                  rightElement={
                    <Image
                      src={driverWithRequestsAndUnit.driver.img}
                      h="30px"
                    />
                  }
                >
                  <VStack w="100%">
                    {[
                      {
                        label: "Email",
                        value: driverWithRequestsAndUnit.driver.email,
                      },
                      {
                        label: "Teléfono",
                        value:
                          driverWithRequestsAndUnit.driver.mobilePhoneNumber,
                      },
                      {
                        label: "Registro",
                        value: format(
                          new Date(
                            driverWithRequestsAndUnit.driver.created._seconds *
                              1000
                          ),
                          "yyyy-MM-dd HH:mm a"
                        ),
                      },
                    ].map((item) => (
                      <>
                        <Divider />
                        <HStack fontSize={12} spacing="auto" my={3} w="90%">
                          <Text fontWeight="bold" w="35%">
                            {item.label}
                          </Text>
                          <Text>{item.value}</Text>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="100%" alignSelf="center">
          <Center bg="white" w="100%" h="90%">
            {columns.length > 0 ? (
              <DashboardTable
                data={filteredDrivers}
                columns={columns as ColumnDef<object>[]}
                w="100%"
                h="95%"
                py={5}
                px={3}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </Stack>
  );
};
