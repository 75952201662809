import { useToast } from "@chakra-ui/react";
import { restoreSession } from "helpers/session-helpers/session-storage";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUserData } from "redux-service/slices";
import { authResources } from "services/resources/firebase/auth";
import { userResources } from "services/resources/firebase/user";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";

export const useSession = (): {
  sessionExists: boolean;
  sessionResolved: boolean;
  sessionError: boolean;
  logged: boolean;
  userType: UserTypeEnum;
  isAdmin: boolean;
} => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { logged } = useSelector(getUser);

  const [sessionExists, setSessionExists] = useState(false);
  const [sessionResolved, setSessionResolved] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [userType, setUserType] = useState<UserTypeEnum>(
    UserTypeEnum.UNVERIFIED_DRIVER
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const autoLogIn = async (uid: string): Promise<void> => {
    try {
      // If the data exists, continue the process
      if (!isNil(uid)) {
        // Anonyously log in, as a valid id token will be required for API call
        const { user: anonymousUser } = await authResources.anonymouslyLogIn();
        const anonymousToken = await anonymousUser.getIdToken();
        // Generate custom token from stored uid
        const { data: customTokenData } =
          await authResources.generateCustomToken(uid, anonymousToken);
        // If we had succeeded with the custom token, continue the flow
        if (!isNil(customTokenData)) {
          // Sign in the user with its custom token
          const { user } = await authResources.logInWithCustomToken(
            customTokenData.token
          );
          const token = await user.getIdToken();
          // Get the user from the database, using the authentication user email
          const { data: userData } = await userResources.getByEmail(
            user.email as string,
            token
          );
          if (!isNil(userData)) {
            if (
              (!isNil(userData.isAdmin) && userData.isAdmin) ||
              [UserTypeEnum.ADMIN, UserTypeEnum.PARTNER].includes(userData.type)
            ) {
              setUserType(userData.type);
              setIsAdmin(!isNil(userData.isAdmin) && userData.isAdmin);
              // Store the state in Redux
              dispatch(setUserData({ ...userData, logged: true, token }));
            } else {
              throw new Error(
                "RM: This user is not allowed to use this application"
              );
            }
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error-autoLogIn: ", e);
      if (e instanceof Error && e.message.includes("RM")) {
        toast({
          title: "Error de autenticación",
          description:
            "El usuario guardado en el dispositivo no puede usar esta aplicación. Inicia sesión de nuevo.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setSessionError(true);
      }
    } finally {
      setSessionResolved(true);
    }
  };

  const checkSession = async (): Promise<void> => {
    try {
      // We retrieve the stored data
      const user = restoreSession();
      if (!isEmpty(user)) {
        setSessionExists(true);
        if (!logged) await autoLogIn(user.uid);
      } else {
        setSessionExists(false);
        setSessionResolved(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error-checkSession: ", e);
      setSessionError(true);
    }
  };

  useEffect(() => {
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  return {
    sessionExists,
    sessionResolved,
    sessionError,
    logged,
    userType,
    isAdmin,
  };
};
