import { Button, StackProps, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IUnit } from "services/resources/firebase/unit/types.d";

interface IUnitDataProps extends StackProps {
  unit: IUnit;
}

export const UnitData: React.FC<IUnitDataProps> = (props): JSX.Element => {
  const { unit, ...rest } = props;

  const navigate = useNavigate();

  const handleOnUnitClick = () => {
    navigate(`/units/${unit.id}`);
  };

  return (
    <VStack alignItems="flex-start" {...rest}>
      <Text>{unit.clientUnitId}</Text>
      <Text>{unit.plates}</Text>
      <Text>{`${unit.brand} ${unit.model}`}</Text>
      <Button
        rounded="full"
        borderWidth={1}
        borderColor="green"
        bg="white"
        color="green"
        onClick={handleOnUnitClick}
      >
        Ver vehículo
      </Button>
    </VStack>
  );
};
