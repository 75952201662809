import { Icon, Text, Tooltip, VStack } from "@chakra-ui/react";
import { OverlayView } from "@react-google-maps/api";
import { ReactComponent as ZubeMarkerVector } from "assets/vectors/taxi-location.svg";
import { isEmpty, isNil } from "lodash/fp";
import { ILocation } from "services/resources/firebase/journey/types.d";
import { getStatusColor } from "../helpers/string-helpers";

interface IZubeMarkerProps {
  location: ILocation;
  available: boolean;
  journeyStatus?: string;
  driverName: string;
  unit?: string;
}

export const ZubeMarker: React.FC<IZubeMarkerProps> = (props) => {
  const {
    location,
    available,
    journeyStatus = "",
    driverName,
    unit = "",
  } = props;

  const shouldShowJourneyStatus = journeyStatus === "progress";

  return (
    <OverlayView
      position={{ lat: location.latitude, lng: location.longitude }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Tooltip
        bg="white"
        rounded="lg"
        color="gray.400"
        label={
          <VStack alignItems="flex-start">
            <Text>Nombre: {driverName}</Text>
            {!isNil(unit) && !isEmpty(unit) ? (
              <Text>Unidad: {unit}</Text>
            ) : null}
          </VStack>
        }
        p={3}
        w="250px"
        fontWeight="regular"
      >
        <Icon
          as={ZubeMarkerVector}
          color={getStatusColor(shouldShowJourneyStatus, available)}
          boxSize={7}
        />
      </Tooltip>
    </OverlayView>
  );
};
