import {
  alphaSpaceRegex,
  emailRegex,
} from "helpers/data-helpers/regex-helpers";
import { IDriverWithRequestAndUnitsPayload } from "services/resources-recipes/types.d";
import {
  DriverTypeEnum,
  IDriverRequest,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { number, object, string } from "yup";

export const DriverFormValidationSchema = (isCreating = false) => {
  return object().shape({
    driver: object().shape({
      email: string()
        .matches(emailRegex, "Debes usar un correo válido.")
        .required("Este campo es requerido."),
      fullName: string()
        .matches(
          alphaSpaceRegex,
          "Tu nombre no puede contener caracteres especiales."
        )
        .required("Este campo es requerido."),
      mobilePhoneNumber: number()
        .typeError("No puedes ingresar caracteres especiales.")
        .integer("No puedes ingresar valores decimales.")
        .moreThan(999999999, "Debes ingresar un número de 10 dígitos.")
        .lessThan(10000000000, "Debes ingresar un número de 10 dígitos.")
        .required("Este campo es requerido."),
      password: isCreating
        ? string()
            .required("Este campo es requerido.")
            .min(6, "La contraseña debe tener al menos 6 caracteres.")
        : string().min(6, "La contraseña debe tener al menos 6 caracteres."),
    }),
    request: object().shape({
      driverLicense: object()
        .shape({
          comment: string(),
          document: object().shape({
            back: string().required("Este campo es requerido"),
            front: string().required("Este campo es requerido"),
          }),
          expirationDate: string().required(),
          status: string().required("Este campo es requerido"),
        })
        .required("Este campo es requerido."),
      idCard: object()
        .shape({
          comment: string(),
          document: object().shape({
            back: string().required("Este campo es requerido"),
            front: string().required("Este campo es requerido"),
          }),
          expirationDate: string().required(),
          status: string().required("Este campo es requerido"),
        })
        .required("Este campo es requerido."),
    }),
  });
};

export const INITIAL_VALUES: IDriverWithRequestAndUnitsPayload = {
  request: {
    idCard: {
      comment: "",
      document: { back: "", front: "" },
      expirationDate: new Date(),
      status: VerificationStatusEnum.PENDING,
    },
    driverLicense: {
      comment: "",
      document: { back: "", front: "" },
      expirationDate: new Date(),
      status: VerificationStatusEnum.PENDING,
    },
    driverType: {
      field: DriverTypeEnum.UNSELECTED,
      status: VerificationStatusEnum.PENDING,
    },
    driverId: "",
    status: VerificationStatusEnum.PENDING,
  } as IDriverRequest,
  driver: {
    fullName: "",
    email: "",
    mobilePhoneNumber: "",
    score: {
      cumulativeSum: 5,
      ratingCount: 1,
    },
    type: UserTypeEnum.UNVERIFIED_DRIVER,
  },
  units: [],
};
