import { emailRegex } from "helpers/data-helpers/regex-helpers";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { object, string } from "yup";

export const ProfileFormValidationSchema = (validatePassword: boolean) => {
  return object().shape({
    email: string()
      .matches(emailRegex, "Debes usar un correo válido.")
      .required("Este campo es requerido."),
    fullName: string().required("Este campo es requerido."),
    password: validatePassword
      ? string().min(6, "La contraseña debe contener al menos 6 caracteres.")
      : string().notRequired(),
  });
};

export const INITIAL_VALUES = {
  email: "",
  fullName: "",
  img: "",
  password: "",
  type: UserTypeEnum.ADMIN,
};
