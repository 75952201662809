import { number, object, string } from "yup";

export const CouponFormValidationSchema = object().shape({
  code: string().required("Este campo es requerido."),
  redeems: object().shape({
    total: number()
      .required("Este campo es requerido")
      .min(1, "Ingresa un número válido"),
    used: number(),
  }),
});

export const INITIAL_VALUES = {
  beginDate: new Date(),
  code: "",
  discountInPesos: undefined,
  discountPercentage: undefined,
  endDate: new Date(),
  redeems: {
    total: 0,
    used: 0,
  },
};
