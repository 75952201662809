import { isNil } from "lodash/fp";
import { IPlaceAutocompletePrediction } from "services/resources/firebase/google-maps/types.d";

export const arrangePredictions = (
  predictions: IPlaceAutocompletePrediction[]
): IPlaceAutocompletePrediction[] => {
  return predictions.sort((a, b) => {
    // Move 'a' to the end
    if (isNil(a.distance_meters)) return 1;
    // Move 'b' to the end
    if (isNil(b.distance_meters)) return -1;
    // Sort from shortest to farthest
    return a.distance_meters - b.distance_meters;
  });
};
