import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { IZubeModalProps, ZubeModal } from "components/elements";
import { AppointmentField } from "./AppointmentField";
import { IUser } from "services/resources/firebase/user/types.d";
import { USER_FALLBACK_URL } from "helpers/constants/url-constants";
import { DateField } from "components/inputs";

interface IAppointmentModalProps
  extends Omit<IZubeModalProps, "children" | "title"> {
  handleOnSelectDate: (newDate: Date) => void;
  selectedDriver: IUser;
}

export const AppointmentModal: React.FC<IAppointmentModalProps> = (props) => {
  const { handleOnSelectDate, selectedDriver, ...rest } = props;

  return (
    <ZubeModal
      contentStyle={{ minW: "80vw", maxW: "80vw" }}
      title="Cita: conductor"
      {...rest}
    >
      <VStack w="100%" p={5}>
        <HStack w="100%">
          <Image
            w="20%"
            src={selectedDriver.img}
            fallbackSrc={USER_FALLBACK_URL}
          />
          <Box w="20%" />
          <VStack w="60%" alignItems="flex-start">
            <AppointmentField label="Nombre" value={selectedDriver.fullName} />
            <AppointmentField
              label="Nombre corto"
              value={selectedDriver.fullName.split(" ")[0]}
            />
            <AppointmentField label="Email" value={selectedDriver.email} />
            <AppointmentField
              label="Teléfono"
              value={selectedDriver.mobilePhoneNumber}
            />
            <Text fontWeight="bold">Selecciona decha y hora para la cita:</Text>
            <DateField
              onDateChange={(date) => handleOnSelectDate(date)}
              buttonColor="gray.100"
            />
          </VStack>
        </HStack>
        <Button>Guardar</Button>
      </VStack>
    </ZubeModal>
  );
};
