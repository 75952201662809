/**
 * Function that creates a blob from an image uri.
 * @param imageUri
 * @returns image blob
 */
export const createBlob = async (imageUri: string): Promise<Blob> => {
  // Create a blob with the uri
  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response as Blob);
    };
    xhr.onerror = function (e) {
      reject(
        new TypeError(JSON.stringify({ e, message: "Network request failed" }))
      );
    };
    xhr.responseType = "blob";
    xhr.open("GET", imageUri, true);
    xhr.send(null);
  });
};
