import { HStack, StackProps, Text } from "@chakra-ui/react";
import "./styles.css";
import { useRef, useState } from "react";

interface IFileFieldProps extends StackProps {
  onFileSelect: (uri: string) => void;
  selectedFile?: string;
  id: string;
}

export const FileField: React.FC<IFileFieldProps> = (props): JSX.Element => {
  const { onFileSelect, id, ...rest } = props;

  const [fileName, setFileName] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (): void => {
    const file = fileInputRef.current?.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setFileName(file.name);
      onFileSelect(url);
    } else {
      console.error("No file selected.");
    }
  };

  return (
    <HStack {...rest}>
      <label htmlFor={`file-input-${id}`} className="file-label">
        Seleccionar
      </label>
      <input
        id={`file-input-${id}`}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <Text maxW="50%">
        {!fileName ? "No se ha seleccionado ningún archivo." : fileName}
      </Text>
    </HStack>
  );
};
