import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";
import {
  UnitPayloadType,
  UnitStatusEnum,
} from "services/resources/firebase/unit/types.d";
import { number, object, string } from "yup";

export const UnitFormValidationSchema = () => {
  return object().shape({
    model: string().required("Este campo es requerido."),
    plates: string().required("Este campo es requerido."),
    brand: string().required("Este campo es requerido."),
    status: string()
      .oneOf(Object.values(UnitStatusEnum), "Este estatus no es válido.")
      .required("Este campo es requerido."),
    year: number().required("Este campo es requerido."),
    image: string().required("Este campo es requerido."),
    driver: string().notRequired(),
    circulationCard: object().shape({
      document: string().required("Este campo es requerido."),
    }),
    clientUnidId: string().notRequired(),
    controlCard: object().shape({
      document: string().notRequired(),
    }),
    insurance: object().shape({
      document: string().notRequired(),
    }),
    partnerId: string().notRequired(),
  });
};

export const INITIAL_VALUES: UnitPayloadType = {
  plates: "123-ABC",
  model: "",
  brand: "",
  status: UnitStatusEnum.PENDING,
  year: 2024,
  image: "",
  driver: "",
  insurance: {
    document: "",
    expirationDate: "",
    status: VerificationStatusEnum.PENDING,
  },
  circulationCard: {
    document: "",
    expirationDate: "",
    status: VerificationStatusEnum.PENDING,
  },
  controlCard: {
    document: "",
    expirationDate: "",
    status: VerificationStatusEnum.PENDING,
  },
  subscriptionId: "",
  clientUnitId: "",
  partnerId: "",
};
