import { IoCalendarOutline } from "react-icons/io5";
import { format, parseISO, set } from "date-fns";
import { isNil } from "lodash/fp";
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";

interface IDateFieldProps extends BoxProps {
  /**
   * Handler function triggered when date selected.
   */
  onDateChange: (date: Date) => void;
  /**
   * Current value of the date.
   */
  dateValue?: Date;
  /**
   * Height to be set in the show/hide button.
   */
  buttonHeight?: string;
  /**
   * Flag to indicate weather disabling the button or not.
   */
  disableButton?: boolean;
  /**
   * Color to be used within the button.
   */
  buttonColor?: string;
}

export const DateField: React.FC<IDateFieldProps> = (props) => {
  const {
    onDateChange,
    dateValue = undefined,
    buttonHeight = undefined,
    buttonColor = "gray.100",
    ...rest
  } = props;

  const [show, setShow] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [hasSetTimeOnce, setHasSetTimeOnce] = useState<boolean>(false);

  const toggleShow = (): void => {
    setShow(!show);
  };

  return (
    <Box {...rest}>
      <Box
        _disabled={{ opacity: 0.8 }}
        alignItems="center"
        bg={buttonColor}
        flex={1}
        display="flex"
        flexDir="row"
        h={!isNil(buttonHeight) ? buttonHeight : "100%"}
        justifyContent="center"
        onClick={toggleShow}
        w="100%"
        my={1}
        rounded="md"
        cursor="pointer"
      >
        <HStack w="100%" rounded="md">
          <Box
            justifyContent="center"
            display="flex"
            bg="gray.300"
            w="10%"
            h="100%"
            py={1}
            rounded="md"
          >
            <IoCalendarOutline
              color="black"
              name="calendar-outline"
              size={24}
            />
          </Box>
          <Text color="gray.400" pl={2}>
            {format(isNil(dateValue) ? date : dateValue, "dd-MM-yyyy HH:mm")}
          </Text>
        </HStack>
      </Box>
      <Modal isOpen={show} onClose={toggleShow}>
        <ModalOverlay />
        <ModalContent alignSelf="center">
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems="flex-start" spacing={3}>
              <Text fontWeight="bold">Fecha:</Text>
              <input
                type="date"
                value={format(date, "yyyy-MM-dd")}
                onChange={(e) => {
                  const parsedDate = parseISO(e.target.value);
                  setDate(
                    set(date, {
                      year: parsedDate.getFullYear(),
                      month: parsedDate.getMonth(),
                      date: parsedDate.getDate(),
                    })
                  );
                }}
              />
              <Text fontWeight="bold">Hora:</Text>
              <input
                type="time"
                onChange={(e) => {
                  const hours = Number(e.target.value.substring(0, 2));
                  const minutes = Number(e.target.value.substring(3, 5));
                  setDate(set(date, { hours, minutes }));
                  setHasSetTimeOnce(true);
                }}
              />
              <Button
                onClick={() => {
                  onDateChange(date);
                  setShow(false);
                }}
                isDisabled={!hasSetTimeOnce}
              >
                Confirmar
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

DateField.defaultProps = {
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDir: "row",
  w: "100%",
};
