import { StackProps, VStack } from "@chakra-ui/react";
import { DriverForm } from "components/forms";
import {
  IDriverWithRequestAndUnit,
  IDriverWithRequestAndUnitsPayload,
} from "services/resources-recipes/types.d";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { IUnit } from "services/resources/firebase/unit/types.d";

interface IDriverDetailUIProps extends StackProps {
  handleOnSubmit: (payload: IDriverWithRequestAndUnitsPayload) => Promise<void>;
  /**
   * Initial values for the form.
   */
  previousValues?: IDriverWithRequestAndUnit;
  /**
   * Units that the backoffice user can asign to the driver.
   */
  availableUnits: IUnit[];
  isCreating?: boolean;
  existingPartners: IPartner[];
}

export const DriverDetailUI: React.FC<IDriverDetailUIProps> = (
  props
): JSX.Element => {
  const {
    isCreating = false,
    availableUnits,
    handleOnSubmit,
    previousValues,
    existingPartners,
    ...rest
  } = props;

  return (
    <VStack p={5} w="95%" alignSelf="center" borderRadius={10} {...rest}>
      <DriverForm
        handleOnSubmit={handleOnSubmit}
        previousValues={previousValues}
        availableUnits={availableUnits}
        isCreating={isCreating}
        existingPartners={existingPartners}
      />
    </VStack>
  );
};
