import { emailRegex } from "helpers/data-helpers/regex-helpers";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { object, string } from "yup";

export const UserFormValidationSchema = (validateAuth: boolean) => {
  return object().shape({
    email: validateAuth
      ? string()
          .matches(emailRegex, "Debes usar un correo válido.")
          .required("Este campo es requerido.")
      : string().notRequired(),
    fullName: string().required("Este campo es requerido."),
    password: validateAuth
      ? string()
          .min(6, "La contraseña debe contener al menos 6 caracteres.")
          .required("Este campo es requerido.")
      : string().notRequired(),
  });
};

export const INITIAL_VALUES = {
  email: "",
  fullName: "",
  mobilePhoneNumber: "",
  organization: "",
  password: "",
  score: {
    cumulativeSum: 10,
    ratingCount: 2,
  },
  type: UserTypeEnum.PARTNER,
};
