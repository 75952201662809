import {
  Button,
  HStack,
  Select,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useState } from "react";
import { endOfDay, startOfDay } from "date-fns";

import {
  INITIAL_VALUES,
  CouponFormValidationSchema,
} from "./helpers/form-helpers";
import { isEmpty } from "lodash/fp";
import { zubeColors } from "styles/colors";
import { CouponPayloadType } from "services/resources/firebase/coupon/types.d";
import { InputField } from "components/inputs";
import { DateTimePicker } from "components/elements";

interface ICouponFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (
    payload: CouponPayloadType,
    discountType: string
  ) => Promise<void>;
  /**
   * Discount type dropdown handler for previous values.
   */
  initialDiscountType: string;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: CouponPayloadType;
}

export const CouponForm: React.FC<ICouponFormProps> = (props) => {
  const {
    initialDiscountType,
    handleOnSubmit,
    onScreenChange,
    previousValues,
    ...rest
  } = props;
  const [discountType, setDiscountType] = useState<string>(initialDiscountType);

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={(p) => handleOnSubmit(p, discountType)}
      validationSchema={CouponFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <VStack {...rest}>
          <InputField
            error={errors.code}
            label="Código"
            onChangeText={handleChange("code")}
            type="text"
            value={values.code}
          />
          <HStack alignItems="center" w="100%">
            <InputField
              label="Descuento"
              onChangeText={(t) => {
                // set both since discount type flag will filter the payload
                setFieldValue("discountInPesos", t.replace(/\D+/g, ""));
                setFieldValue("discountPercentage", t.replace(/\D+/g, ""));
              }}
              type="string"
              value={
                discountType === "mxn"
                  ? values.discountInPesos
                  : values.discountPercentage
              }
            />
            <VStack w="50%">
              <Text
                alignSelf="flex-start"
                fontSize={14}
                fontWeight="bold"
                mb={1}
              >
                Tipo
              </Text>
              <Select
                mb={4}
                onChange={(e) => {
                  setDiscountType(e.target.selectedOptions[0].value);
                }}
                value={discountType}
              >
                <option value="mxn">MXN</option>
                <option value="percentage">%</option>
              </Select>
            </VStack>
          </HStack>
          <InputField
            error={errors.redeems?.total}
            label="Usos"
            onChangeText={(t) => {
              setFieldValue("redeems.total", t.replace(/\D+/g, ""));
            }}
            type="string"
            value={values.redeems.total}
          />
          <HStack alignItems="center" w="100%">
            <VStack>
              <Text
                alignSelf="flex-start"
                fontSize={14}
                fontWeight="bold"
                mb={1}
              >
                Inicio
              </Text>
              <DateTimePicker
                onSelectDate={(e) =>
                  setFieldValue("beginDate", startOfDay(e as Date))
                }
                selected={values.beginDate as Date}
              />
            </VStack>
            <VStack>
              <Text
                alignSelf="flex-start"
                fontSize={14}
                fontWeight="bold"
                mb={1}
              >
                Fin
              </Text>
              <DateTimePicker
                onSelectDate={(e) =>
                  setFieldValue("endDate", endOfDay(e as Date))
                }
                selected={values.endDate as Date}
              />
            </VStack>
          </HStack>
          <HStack w="90%" mb={5}>
            <Button
              bg="white"
              borderRadius="full"
              borderColor={zubeColors.zubeOrange.light}
              borderWidth={1}
              onClick={onScreenChange}
              w="100%"
              color={zubeColors.zubeOrange.light}
            >
              Cancelar
            </Button>
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={!isEmpty(errors)}
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="100%"
              color="white"
            >
              Guardar
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};

CouponForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
