import { StackProps } from "@chakra-ui/react";
import { IDriverWithMostJourneys } from "services/resources-recipes/types.d";
import { DriverWithMostJourneys } from "./DriverWithMostJourneys";
import { DashboardPanel } from "components/elements";

interface IDriversWithMostJourneysProps extends StackProps {
  driversWithJourneyCountsAndProfits: IDriverWithMostJourneys[];
}

export const DriversWithMostJourneys: React.FC<
  IDriversWithMostJourneysProps
> = (props): JSX.Element => {
  const { driversWithJourneyCountsAndProfits, ...rest } = props;

  return (
    <DashboardPanel
      label="Conductores con más viajes"
      alignItems="flex-start"
      {...rest}
    >
      {driversWithJourneyCountsAndProfits.map(
        (driverWithJourneydCountAndProfit, index) => (
          <DriverWithMostJourneys
            driverWithMostJourneys={driverWithJourneydCountAndProfit}
            index={index}
            key={driverWithJourneydCountAndProfit.driver}
          />
        )
      )}
    </DashboardPanel>
  );
};
