import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { isEmpty } from "lodash/fp";

import { IInputFieldProps } from "../types.d";

export const InputField: React.FC<IInputFieldProps> = (props): JSX.Element => {
  const {
    label = "",
    error = "",
    customPlaceholder = "",
    onChangeText,
    description = "",
    ...rest
  } = props;

  return (
    <FormControl minH="45px" isInvalid={!isEmpty(error)} mb={4} w="100%">
      {!isEmpty(label) ? (
        <Text fontSize={14} pb={3} fontWeight="bold">
          {label}
        </Text>
      ) : null}
      <Input
        onChange={({ target: { value: v } }) => {
          onChangeText(v);
        }}
        placeholder={customPlaceholder}
        size="md"
        {...rest}
      />
      {!isEmpty(description) ? (
        <Text pt={1} fontSize={12} color="gray">
          {description}
        </Text>
      ) : null}
      {!isEmpty(error) ? (
        <HStack justifyContent="center">
          <FormErrorMessage>
            <FormErrorIcon color="salmon" h={4} ml={3} />
            {error}
          </FormErrorMessage>
        </HStack>
      ) : null}
    </FormControl>
  );
};
