import { HStack, Select, StackProps, Text, VStack } from "@chakra-ui/react";
import { InputField, PasswordField } from "components/inputs";
import { FormikErrors } from "formik";
import { useState } from "react";
import { IDriverWithRequestAndUnitsPayload } from "services/resources-recipes/types.d";
import { IUnit } from "services/resources/firebase/unit/types.d";
import { SelectedUnits } from "./SelectedUnits";
import { isNil, isEmpty } from "lodash/fp";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { getDriverTypeLabel } from "helpers/data-helpers/string-helpers";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { ConfirmationModal } from "components/elements";

interface IDriverDataProps extends StackProps {
  driverWithRequestData: IDriverWithRequestAndUnitsPayload;
  /**
   * Units that the backoffice user can asign to the driver.
   */
  availableUnits: IUnit[];
  /**
   * Set value of form field directly
   */
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<IDriverWithRequestAndUnitsPayload>>;
  /**
   * Whether the form is being used for creation or
   * update.
   */
  isCreating: boolean;
  existingPartners: IPartner[];
}

export const DriverData: React.FC<IDriverDataProps> = (props): JSX.Element => {
  const {
    isCreating,
    setFieldValue,
    driverWithRequestData,
    availableUnits,
    existingPartners,
    ...rest
  } = props;

  const { type, isAdmin: isUserAdminOnDb } = useSelector(getUser);

  const [selectedUnits, setSelectedUnits] = useState<IUnit[]>(
    driverWithRequestData.units
  );
  const [latestSelectedUnit, setLatestSelectedUnit] = useState<
    IUnit | undefined
  >(undefined);
  const [confirmationModalVisible, setConfirmationVisible] =
    useState<boolean>(false);
  const [unitToBeDeleted, setUnitToBeDeleted] = useState<string | undefined>(
    undefined
  );

  const isAdmin = type === UserTypeEnum.ADMIN || isUserAdminOnDb;

  const handleOnAddUnit = (unit: IUnit): void => {
    const selectedUnitsSnapshot = [...selectedUnits];
    const existingMatch = selectedUnitsSnapshot.find(
      (selectedUnit) => selectedUnit.id === unit.id
    );
    if (isNil(existingMatch)) {
      setLatestSelectedUnit(unit);
      selectedUnitsSnapshot.push(unit);
      setSelectedUnits(selectedUnitsSnapshot);
      setFieldValue("units", selectedUnitsSnapshot);
    }
  };

  const handleOnUnitRemoveModalOpen = (unitId: string): void => {
    setUnitToBeDeleted(unitId);
    setConfirmationVisible(true);
  };

  const handleOnUnitRemoveConfirmation = (): void => {
    const selectedUnitsSnapshot = [...selectedUnits];
    let matchIndex: number | undefined = undefined;
    const existingMatch = selectedUnitsSnapshot.find((selectedUnit, index) => {
      if (selectedUnit.id === unitToBeDeleted) {
        matchIndex = index;
      }
      return selectedUnit.id === unitToBeDeleted;
    });
    if (!isNil(existingMatch) && !isNil(matchIndex)) {
      selectedUnitsSnapshot.splice(matchIndex, 1);
      setSelectedUnits(selectedUnitsSnapshot);
      setFieldValue("units", selectedUnitsSnapshot);
    }
    setConfirmationVisible(false);
  };

  return (
    <HStack alignItems="flex-start" w="100%" {...rest}>
      <ConfirmationModal
        onAccept={handleOnUnitRemoveConfirmation}
        onDecline={() => {
          setUnitToBeDeleted(undefined);
          setConfirmationVisible(false);
        }}
        label={`¿Estás seguro de querer remover la unidad seleccionada (${
          availableUnits.find((unit) => unit.id === unitToBeDeleted)?.plates
        }) al conductor ${
          driverWithRequestData.driver.fullName
        }? Esta acción impactará el funcionamiento de la aplicación de conductor para dicho usuario.`}
        isOpen={confirmationModalVisible}
        onClose={() => setConfirmationVisible(false)}
      />
      <VStack alignItems="flex-start" w="50%">
        <Text fontWeight="bold" pl={1}>
          Nombre *
        </Text>
        <InputField
          onChangeText={(text) => setFieldValue("driver.fullName", text)}
          value={driverWithRequestData.driver.fullName}
        />
        <Text fontWeight="bold" pl={1}>
          Email *
        </Text>
        <InputField
          onChangeText={(text) => setFieldValue("driver.email", text)}
          value={driverWithRequestData.driver.email}
        />
        <Text fontWeight="bold" pl={1}>
          {`Contraseña ${isCreating ? "*" : ""}`}
        </Text>
        <PasswordField
          onChangeText={(text) => setFieldValue("driver.password", text)}
          value={driverWithRequestData.driver.password}
          label=""
        />
        {isAdmin ? (
          <>
            <Text fontWeight="bold" pl={1}>
              Tipo de conductor
            </Text>
            <Select
              mb={4}
              onChange={(e) =>
                setFieldValue("driver.type", e.target.selectedOptions[0].value)
              }
              placeholder="tipo de conductor"
              value={driverWithRequestData.driver.type}
            >
              {Object.entries(UserTypeEnum)
                .filter(([_key, value]) =>
                  [
                    UserTypeEnum.PRIVATE_DRIVER,
                    UserTypeEnum.PUBLIC_DRIVER,
                  ].includes(value)
                )
                .map(([key, value]) => (
                  <option key={key} value={value}>
                    {getDriverTypeLabel(value)}
                  </option>
                ))}
            </Select>
          </>
        ) : null}
        {driverWithRequestData.driver.type === UserTypeEnum.PUBLIC_DRIVER &&
        isAdmin ? (
          <>
            <Text fontWeight="bold" pl={1}>
              Organización
            </Text>
            <Select
              mb={4}
              onChange={(e) =>
                setFieldValue(
                  "driver.organization",
                  e.target.selectedOptions[0].value
                )
              }
              placeholder="organización"
              value={driverWithRequestData.driver.organization}
            >
              {existingPartners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name}
                </option>
              ))}
            </Select>
          </>
        ) : null}
      </VStack>
      <VStack alignItems="flex-start" w="50%">
        <Text fontWeight="bold" pl={1}>
          Teléfono *
        </Text>
        <InputField
          onChangeText={(text) =>
            setFieldValue("driver.mobilePhoneNumber", text)
          }
          value={driverWithRequestData.driver.mobilePhoneNumber}
        />
        <Text fontWeight="bold" pl={1}>
          Vehículo
        </Text>
        <Select
          mb={4}
          onChange={(e) =>
            handleOnAddUnit(
              availableUnits.find(
                (unit) => unit.id === e.target.selectedOptions[0].value
              ) as IUnit
            )
          }
          placeholder="unidad"
          value={!isNil(latestSelectedUnit) ? latestSelectedUnit.id : "unidad"}
        >
          {availableUnits.map((unit) => (
            <option key={unit.id} value={unit.id}>
              {`${unit.plates} ${
                isAdmin &&
                availableUnits.filter(
                  (unit) => !isNil(unit.partnerId) || !isEmpty(unit.partnerId)
                ).length > 0
                  ? `(${
                      existingPartners.find(
                        (partner) => unit.partnerId === partner.id
                      )?.name
                    })`
                  : `${isAdmin ? "(sin partner)" : ""}`
              }`}
            </option>
          ))}
        </Select>
        <Text fontWeight="bold">Unidades seleccionadas:</Text>
        {selectedUnits.length > 0 ? (
          <SelectedUnits
            selectedUnits={selectedUnits}
            handleOnUnitRemove={handleOnUnitRemoveModalOpen}
            maxW="100%"
          />
        ) : (
          <Text pl={1}>Sin unidades asignadas.</Text>
        )}
      </VStack>
    </HStack>
  );
};
