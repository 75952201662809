import { StackProps, Text, VStack } from "@chakra-ui/react";

export const Footer: React.FC<StackProps> = (): JSX.Element => {
  return (
    <VStack
      bg="white"
      bottom={0}
      justifyContent="center"
      minH="8vh"
      position="absolute"
      w="100%"
    >
      <Text fontSize={16}>Derechos reservados © Zube</Text>
    </VStack>
  );
};
