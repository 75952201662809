import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { IUnitWithDriver } from "services/resources-recipes/types.d";
import { getDriversUnitsWithDrivers } from "services/resources-recipes/units";
import {
  IUnit,
  UnitPayloadType,
  UnitStatusEnum,
} from "services/resources/firebase/unit/types.d";
import { zubeColors } from "styles/colors";
import { UnitUI } from "./components/UnitsUI";
import { useNavigate, useParams } from "react-router-dom";
import { isNil } from "lodash/fp";
import { UnitDetailUI } from "./components/UnitDetailUI";
import { unitResources } from "services/resources/firebase/unit";
import { getUnitStatus } from "helpers/data-helpers/string-helpers";
import { handleUnitImagesUpload } from "services/resources-recipes/storage";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { partnerResources } from "services/resources/firebase/partner";

export const Units: React.FC = (): JSX.Element => {
  const {
    token,
    type,
    organization,
    isAdmin: isUserAdminOnDb,
  } = useSelector(getUser);
  const { unitId } = useParams();
  const navigate = useNavigate();

  const isPartner = type === UserTypeEnum.PARTNER;
  const isAdmin = type === UserTypeEnum.ADMIN || isUserAdminOnDb;

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [unitsWithDrivers, setUnitsWithDrivers] = useState<IUnitWithDriver[]>(
    []
  );
  const [selectedUnit, setSelectedUnit] = useState<IUnit | undefined>();
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [isCreatingUnit, setIsCreatingUnit] = useState<boolean>(false);

  const retrieveDriversUnits = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const allDriversUnits = await getDriversUnitsWithDrivers(
        token as string,
        isPartner ? organization : undefined
      );
      setUnitsWithDrivers(
        allDriversUnits.sort((a, b) => b.updated._seconds - a.updated._seconds)
      );
    } catch (e) {
      console.error("Error-retrieveDriversUnits: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeleteUnit = async (unitId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await unitResources.delete(unitId, token as string);
      retrieveDriversUnits();
    } catch (e) {
      console.error("Error-handleOnDeleteUnit: ", e);
      window.alert("Ocurrió un error al eliminar el vehículo.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnEditUnitRedirect = (id: string): void => {
    navigate(`${id}`);
  };

  const fetchExistingUnit = async (): Promise<void> => {
    try {
      const { data } = await unitResources.getByUnitId(
        unitId as string,
        token as string
      );
      if (!isNil(data)) {
        setSelectedUnit(data);
      }
    } catch (e) {
      console.error("fetchExistingUnit: there was an issue fetching data");
    }
  };

  const handleOnUnitEdit = async (
    id: string,
    payload: UnitPayloadType
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { image, controlCard, circulationCard, insurance } =
        await handleUnitImagesUpload(id, payload, selectedUnit as IUnit);
      const imagesUpdatedPayload = {
        ...payload,
        image,
        controlCard,
        circulationCard,
        insurance,
      };
      await unitResources.patchUnit(id, imagesUpdatedPayload, token as string);
      fetchExistingUnit();
    } catch (e) {
      console.error("handleOnUnitEdit: ", e);
      window.alert("Ocurrió un error al intentar editar el vehículo.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnFetchPartners = async () => {
    setLoadingModalVisible(true);
    try {
      const { data } = await partnerResources.getAll(token as string);
      if (!isNil(data)) {
        setPartners(data);
      }
    } catch (e) {
      console.error("handleOnFetchPartners: there was issue fetching partners");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnCreateUnit = async (
    payload: UnitPayloadType
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await unitResources.create(
        {
          ...payload,
          partnerId: isPartner ? organization : payload.partnerId,
          status: UnitStatusEnum.ACTIVE,
        },
        token as string
      );
    } catch (e) {
      console.error(
        "handleOnCreateUnit: there was an issue creating the unit."
      );
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnUnitApprove = async (): Promise<void> => {
    try {
      const { id, created, updated, ...payload } = selectedUnit as IUnit;
      await unitResources.patchUnit(
        selectedUnit?.id as string,
        {
          ...payload,
          status: UnitStatusEnum.ACTIVE,
        },
        token as string
      );
    } catch (e) {
      console.error(
        "handleOnUnitApprove: there was an issue approving the given unit."
      );
    }
  };

  const columns: ITypedListTableColumn<IUnitWithDriver>[] = [
    {
      accessorKey: "clientUnitId",
      header: "Número de unidad",
      cell: ({ row }) => <Text>{row.original.clientUnitId}</Text>,
    },
    {
      accessorKey: "partner",
      header: "Socio Administrador",
      cell: ({ row }) => (
        <Text>
          {partners.find(
            (partner) => partner.id === row.original.driver.organization
          )?.name ||
            partners.find((partner) => partner.id === row.original.partnerId)
              ?.name ||
            "Sin socio"}
        </Text>
      ),
    },
    {
      accessorKey: "driver",
      header: "Conductor Actual",
      cell: ({ row }) => <Text>{row.original.driver.fullName}</Text>,
    },
    {
      accessorKey: "status",
      header: "Estatus",
      cell: ({ row }) => (
        <Text
          bg={getUnitStatus(row.original.status).color}
          borderRadius={16}
          color="white"
          textAlign="center"
          px={2}
          py={2}
        >
          {getUnitStatus(row.original.status).label}
        </Text>
      ),
    },
    {
      accessorKey: "vehicleData",
      header: "Detalles",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <HStack>
            <Text fontWeight="bold">Modelo: </Text>
            <Text>{row.original.model}</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold">Fabricante: </Text>
            <Text>{row.original.brand}</Text>
          </HStack>
        </VStack>
      ),
    },
    {
      accessorKey: "plates",
      header: "Placas",
      cell: ({ row }) => <Text>{row.original.plates}</Text>,
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            rounded="full"
            fontSize={12}
            onClick={() => handleOnEditUnitRedirect(row.original.id)}
          >
            Editar
          </Button>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeleteUnit(row.original.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </VStack>
      ),
    },
  ];

  const isEditingUnit = !isNil(unitId) && !isNil(selectedUnit);

  useEffect(() => {
    if (!isNil(unitId)) {
      fetchExistingUnit();
    }
    if (isAdmin) {
      handleOnFetchPartners();
    }
    retrieveDriversUnits();
    return () => setUnitsWithDrivers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  return (
    <PageContainer
      pageHeader="Vehículos"
      loadingModalVisible={loadingModalVisible}
    >
      {isEditingUnit || isCreatingUnit ? (
        <UnitDetailUI
          handleOnSubmit={(payload) =>
            isEditingUnit
              ? handleOnUnitEdit(unitId, payload)
              : handleOnCreateUnit(payload)
          }
          handleOnUnitApprove={handleOnUnitApprove}
          previousValues={isEditingUnit ? selectedUnit : ({} as IUnit)}
          partners={partners}
        />
      ) : (
        <UnitUI
          columns={
            isAdmin
              ? columns
              : columns.filter((column) => column.accessorKey !== "partner")
          }
          handleOnDeleteUnit={handleOnDeleteUnit}
          getStatus={getUnitStatus}
          unitsWithDrivers={unitsWithDrivers}
          handleOnEditUnit={handleOnDeleteUnit}
          handleOnUnitCreateShow={() => setIsCreatingUnit(true)}
        />
      )}
    </PageContainer>
  );
};
