import { Button, Text } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { IJourneyWithUsers } from "services/resources-recipes/types.d";
import { zubeColors } from "styles/colors";
import { JourneysUI } from "./components/JourneysUI";
import { isNil } from "lodash/fp";
import { JourneyDetailsUI } from "./components/JourneyDetailsUI";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { format } from "date-fns";
import { getJourneysWithUsers } from "services/resources-recipes/journeys";
import { journeyResources } from "services/resources/firebase/journey";
import { IJourneyReport } from "services/resources/firebase/journey/types.d";

export const Journeys: React.FC = (): JSX.Element => {
  const { organization, token, type } = useSelector(getUser);
  const isPartner = type === UserTypeEnum.PARTNER;
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [journeysWithUsers, setJourneysWithUsers] = useState<
    IJourneyWithUsers[]
  >([]);
  const [selectedJourney, setSelectedJourney] = useState<
    IJourneyWithUsers | undefined
  >();

  const getStatus = (status: string): { color: string; label: string } => {
    switch (status) {
      case "created":
        return {
          color: zubeColors.zubeOrange.light,
          label: "Cancelado por tiempo",
        };
      case "canceled":
        return { color: "red", label: "Cancelado" };
      case "completed":
        return { color: "green", label: "Completado" };
      default:
        return { color: "", label: "" };
    }
  };

  const retrieveJourneys = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const journeys = await getJourneysWithUsers(token as string);
      if (isPartner) {
        setJourneysWithUsers(
          journeys
            .filter((journey) => journey.driver.organization === organization)
            .sort(
              (a, b) => b.journey.updated._seconds - a.journey.updated._seconds
            )
        );
      } else
        setJourneysWithUsers(
          journeys.sort(
            (a, b) => b.journey.updated._seconds - a.journey.updated._seconds
          )
        );
    } catch (e) {
      console.error("Error-retrieveJourneys: ", e);
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnResolveReport = async (
    newStatus: boolean,
    resolvedComments: string
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await journeyResources.patchReport(
        selectedJourney?.journey.id as string,
        {
          ...(selectedJourney?.journey.report as IJourneyReport),
          resolved: newStatus,
          resolvedComments,
        },
        token as string
      );
      window.alert("Reporte actualizado con éxito.");
    } catch (e) {
      console.error("handleOnResolveReport", e);
      window.alert("Ocurrió un error al actualizar el reporte de viaje.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const columns: ITypedListTableColumn<IJourneyWithUsers>[] = [
    {
      accessorKey: "id",
      header: "ID Viaje",
      cell: ({ row }) => <Text>{row.original.journey.id}</Text>,
    },
    {
      accessorKey: "date",
      header: "Fecha",
      cell: ({ row }) => (
        <Text>{format(row.original.journey.created._seconds * 1000, "p")}</Text>
      ),
    },
    {
      accessorKey: "origin",
      header: "Origen",
      cell: ({ row }) => (
        <Text title={row.original.journey.roundTripAddresses.origin}>
          {row.original.journey.roundTripAddresses.origin}
        </Text>
      ),
    },
    {
      accessorKey: "destination",
      header: "Destino",
      cell: ({ row }) => (
        <Text title={row.original.journey.roundTripAddresses.destination}>
          {row.original.journey.roundTripAddresses.destination}
        </Text>
      ),
    },
    {
      accessorKey: "driver",
      header: "Conductor",
      cell: ({ row }) => <Text>{row.original.driver.fullName}</Text>,
    },
    {
      accessorKey: "passenger",
      header: "Pasajero",
      cell: ({ row }) => <Text>{row.original.passenger.fullName}</Text>,
    },
    {
      accessorKey: "status",
      header: "Estatus",
      cell: ({ row }) => (
        <Text
          bg={getStatus(row.original.journey.status).color}
          borderRadius={16}
          color="white"
          textAlign="center"
          px={2}
          py={2}
        >
          {getStatus(row.original.journey.status).label}
        </Text>
      ),
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <Button
          color="white"
          bg={zubeColors.zubeGray.dark}
          rounded="full"
          fontSize={12}
          onClick={() => setSelectedJourney(row.original)}
        >
          Detalles
        </Button>
      ),
    },
  ];

  useEffect(() => {
    retrieveJourneys();
    return () => setJourneysWithUsers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Viajes"
      loadingModalVisible={loadingModalVisible}
    >
      {!isNil(selectedJourney) ? (
        <JourneyDetailsUI
          getStatus={getStatus}
          handleOnReturn={() => setSelectedJourney(undefined)}
          handleOnSubmit={(status, comment) =>
            handleOnResolveReport(status, comment)
          }
          journeyWithUsers={selectedJourney}
        />
      ) : (
        <JourneysUI
          columns={columns}
          getStatus={getStatus}
          journeys={journeysWithUsers}
          setSelectedJourney={setSelectedJourney}
        />
      )}
    </PageContainer>
  );
};
