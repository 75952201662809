import {
  IDriverRequest,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { FareType } from "services/resources/firebase/fare/types.d";
import { UnitStatusEnum } from "services/resources/firebase/unit/types.d";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { zubeColors } from "styles/colors";

export const formatMoneyAmount = (number: number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

export const getHeaderName = (): string => {
  const path = window.location.pathname;
  if (path.includes("user")) return "Usuarios del sistema";
  if (path.includes("profile")) return "Perfil";
  if (path.includes("driver")) return "Conductores";
  if (path.includes("partners")) return "Partners";
  if (path.includes("requests") || path.includes("review-request"))
    return "Solicitudes";
  if (path.includes("fares")) return "Tarifas";
  if (path.includes("plans")) return "Planes";
  if (path.includes("coupons")) return "Cupones";
  if (path.includes("journeys")) return "Viajes";
  if (path.includes("reports")) return "Reportes";
  if (path.includes("dashboard")) return "Dashboard";
  return "Dashboard";
};

export const getDriverRequestDocumentName = (
  driverRequestKey: keyof IDriverRequest
): { readableDocumentName: string; gender: "masculine" | "femenine" } => {
  switch (driverRequestKey) {
    case "idCard":
      return {
        readableDocumentName: "Credencial de Elector",
        gender: "femenine",
      };
    case "driverLicense":
      return {
        readableDocumentName: "Licencia de Conducir",
        gender: "femenine",
      };
    case "insurance":
      return { readableDocumentName: "Seguro de Auto", gender: "masculine" };
    case "circulationCard":
      return {
        readableDocumentName: "Tarjeta de Circulación",
        gender: "femenine",
      };
    case "controlCard":
      return { readableDocumentName: "Tarjeta de Control", gender: "femenine" };
    default:
      return { readableDocumentName: "", gender: "femenine" };
  }
};

export const getUnitStatus = (
  status: UnitStatusEnum
): { color: string; label: string } => {
  switch (status) {
    case UnitStatusEnum.PENDING:
      return { color: zubeColors.zubeGray.dark, label: "Pendiente" };
    case UnitStatusEnum.ACTIVE:
      return { color: "green", label: "Aprobado" };
    case UnitStatusEnum.CANCELLED:
      return { color: "red", label: "Rechazado" };
    default:
      return { color: "", label: "" };
  }
};

export const getVerificationStatus = (
  status: VerificationStatusEnum
): { color: string; label: string } => {
  switch (status) {
    case VerificationStatusEnum.PENDING:
      return { color: zubeColors.zubeGray.dark, label: "Pendiente" };
    case VerificationStatusEnum.APPROVED:
      return { color: "green.500", label: "Aprobado" };
    case VerificationStatusEnum.COMPLETED:
      return { color: "green", label: "Aceptado" };
    case VerificationStatusEnum.REJECTED:
      return { color: "red", label: "Rechazado" };
    default:
      return { color: "", label: "" };
  }
};

export const getFareTypeLabel = (type: FareType): string => {
  switch (type) {
    case "foreign":
      return "fóranea";
    case "fixed":
      return "fija";
    case "dynamic":
      return "dinámica";
    default:
      return "";
  }
};

export const getDriverTypeLabel = (driverType: UserTypeEnum): string => {
  switch (driverType) {
    case UserTypeEnum.PRIVATE_DRIVER:
      return "privado";
    case UserTypeEnum.PUBLIC_DRIVER:
      return "público";
    default:
      return "privado";
  }
};

/**
 * Function that returns the encoded version of a nonAlphanumeric input.
 * @param inputString
 * @returns
 */
export const encodeNonAlphanumeric = (inputString: string): string => {
  // Use a regular expression to match non-alphanumeric characters
  const regex = /[^a-zA-Z0-9]/g;

  // Replace non-alphanumeric characters with their encoded versions
  const encodedString = inputString.replace(regex, (match) => {
    return "%" + match.charCodeAt(0).toString(16).toUpperCase();
  });

  return encodedString;
};
