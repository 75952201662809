import { firebaseInstance } from "services/resources/firebase/instance";
import { type IAxiosResponse } from "services/types.d";

import { IAdminNotification } from "./types.d";

const NOTIFICATIONS_API_URL = "/adminNotificationsAPI";

export const adminNotificationsResources = {
  updateReadStatus: (id: string, token: string): Promise<void> =>
    firebaseInstance.patch(
      `${NOTIFICATIONS_API_URL}/read`,
      {},
      {
        headers: { authorization: token },
        params: { id },
      }
    ),

  getAllPending: (
    token: string,
    partnerId?: string
  ): Promise<IAxiosResponse<IAdminNotification[]>> =>
    firebaseInstance.get(`${NOTIFICATIONS_API_URL}/pending`, {
      headers: { authorization: token },
      params: { partnerId },
    }),
};
