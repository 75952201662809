/**
 * Function that from given `previousValues` and
 * a `payload` return the difference in them. Useful
 * for not patching a whole resource, but only needed
 * fields.
 * @param previousValues
 * @param payload
 * @returns
 */
export const getFormDifferences = (
  previousValues: Record<string, any>,
  payload: Record<string, any>
): Record<string, any> => {
  const differences: Record<string, any> = {};

  function compareObjects(
    prev: Record<string, any>,
    current: Record<string, any>,
    parentKey: string = ""
  ): void {
    for (const key in current) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (
        typeof current[key] === "object" &&
        !Array.isArray(current[key]) &&
        current[key] !== null
      ) {
        compareObjects(prev[key] || {}, current[key], fullKey);
      } else if (current[key] !== prev[key]) {
        differences[fullKey] = current[key];
      }
    }
  }

  compareObjects(previousValues, payload);
  return differences;
};

const convertToNumberIfNeeded = (value: any): any => {
  if (typeof value === "string" && !isNaN(Number(value))) {
    return Number(value);
  }
  return value;
};

export const convertPayloadToMatchType = <T extends AnyObject>(
  payload: T
): T => {
  const result: AnyObject = {};

  const recurse = (p: AnyObject, res: AnyObject) => {
    for (const key in p) {
      if (
        typeof p[key] === "object" &&
        p[key] !== null &&
        !Array.isArray(p[key])
      ) {
        res[key] = {};
        recurse(p[key], res[key]);
      } else {
        res[key] = convertToNumberIfNeeded(p[key]);
      }
    }
  };

  recurse(payload, result);
  return result as T;
};
