import { StackProps, Text, VStack } from "@chakra-ui/react";
import { IDoubledSidedPicture } from "services/resources/firebase/driver-request/types.d";
import { DriverDataPicture } from "./DriverDataPicture";

interface IDoubleSidedDocumentProps extends StackProps {
  document: IDoubledSidedPicture;
  handleOnDocumentUpdate: (payload: IDoubledSidedPicture) => void;
  label: string;
  showExpirationInput?: boolean;
  handleOnDocumentExpiryDateChange?: (expiryDate: string) => void;
}

export const DoubleSidedDocument: React.FC<IDoubleSidedDocumentProps> = (
  props
) => {
  const {
    showExpirationInput = false,
    document,
    label,
    handleOnDocumentUpdate,
    handleOnDocumentExpiryDateChange = () => {},
    ...rest
  } = props;

  return (
    <VStack {...rest}>
      <Text fontWeight="bold">{label}</Text>
      <DriverDataPicture
        pictureURL={document.front}
        id={`${label.replace(/ /g, "-").toLowerCase()}-front`}
        handleOnImageSelect={(uri) =>
          handleOnDocumentUpdate({ ...document, front: uri })
        }
        label={`${label} frontal *`}
        w="100%"
      />
      <DriverDataPicture
        pictureURL={document.back}
        id={`${label.replace(/ /g, "-").toLowerCase()}-back`}
        handleOnImageSelect={(uri) =>
          handleOnDocumentUpdate({ ...document, back: uri })
        }
        label={`${label} reverso *`}
        w="100%"
      />
      {showExpirationInput ? (
        <VStack alignItems="flex-start">
          <Text fontWeight="bold">{`Fecha de caducidad de ${label.toLowerCase()} *`}</Text>
          <input
            type="date"
            onChange={(e) => handleOnDocumentExpiryDateChange(e.target.value)}
          />
        </VStack>
      ) : null}
    </VStack>
  );
};
