import { Divider, HStack, StackProps, Text, VStack } from "@chakra-ui/react";

interface IAppointmentFieldProps extends StackProps {
  /**
   * What is the field about.
   */
  label: string;
  /**
   * Actual value of the field.
   */
  value: string;
}

export const AppointmentField: React.FC<IAppointmentFieldProps> = (
  props
): JSX.Element => {
  const { label, value, ...rest } = props;

  return (
    <VStack w="100%" {...rest}>
      <HStack w="100%">
        <Text fontWeight="bold">{label}</Text>
        <Text>{value}</Text>
      </HStack>
      <Divider h={1} w="100%" />
    </VStack>
  );
};
