import {
  Image,
  StackProps,
  Text,
  VStack,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { FileField } from "components/inputs";
import { DOCUMENT_FALLBACK_URL } from "helpers/constants/url-constants";
import { getVerificationStatus } from "helpers/data-helpers/string-helpers";
import { isNil } from "lodash/fp";
import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";

interface IUnitPictureProps extends StackProps {
  unitPictureURL?: string;
  label: string;
  showStatusDropdown?: boolean;
  dropdownLabel?: string;
  handleOnStatusChange?: (newStatus: VerificationStatusEnum) => void;
  statusValue?: VerificationStatusEnum;
  handleOnImageSelect: (uri: string) => void;
  id: string;
  handleOnRejectCommentChange?: (text: string) => void;
  rejectCommentValue?: string;
}

export const UnitPicture: React.FC<IUnitPictureProps> = (
  props
): JSX.Element => {
  const {
    label,
    unitPictureURL = undefined,
    showStatusDropdown = false,
    dropdownLabel = undefined,
    handleOnStatusChange = () => {},
    statusValue = undefined,
    handleOnImageSelect,
    handleOnRejectCommentChange = () => {},
    rejectCommentValue = "",
    id,
    ...rest
  } = props;

  return (
    <VStack
      alignItems="flex-start"
      mt={3}
      bg="white"
      borderRadius={10}
      w="50%"
      h="100%"
      p={5}
      {...rest}
    >
      <Text fontWeight="bold" py={1}>
        {label}
      </Text>
      <FileField id={id} onFileSelect={handleOnImageSelect} />
      <Image
        mt={3}
        alignSelf="flex-start"
        borderRadius={10}
        src={unitPictureURL}
        h={!isNil(unitPictureURL) ? "350px" : "200px"}
        objectFit="contain"
        fallback={
          <Image
            mt={3}
            alignSelf="flex-start"
            borderRadius={10}
            src={DOCUMENT_FALLBACK_URL}
            h={"200px"}
            objectFit="contain"
          />
        }
      />
      {showStatusDropdown ? (
        <VStack spacing={3} pt={5}>
          <Text fontWeight="bold">{dropdownLabel}</Text>
          <Select
            mb={4}
            onChange={(e) => {
              handleOnStatusChange(
                e.target.selectedOptions[0].value as VerificationStatusEnum
              );
            }}
            placeholder="Selecciona el estatus"
            value={statusValue}
          >
            {Object.values(VerificationStatusEnum).map((status) =>
              [
                VerificationStatusEnum.APPROVED,
                VerificationStatusEnum.REJECTED,
                VerificationStatusEnum.PENDING,
              ].includes(status) ? (
                <option key={status} value={status}>
                  {getVerificationStatus(status).label}
                </option>
              ) : null
            )}
          </Select>
        </VStack>
      ) : null}
      {statusValue === VerificationStatusEnum.REJECTED ? (
        <VStack alignItems="flex-start" w="100%">
          <Text fontWeight="bold">Motivo del rechazo:</Text>
          <Textarea
            value={rejectCommentValue}
            resize="none"
            onChange={(e) => handleOnRejectCommentChange(e.target.value)}
          />
        </VStack>
      ) : null}
    </VStack>
  );
};
