import { Button, Center, HStack, Image, Text } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { DashboardTable, PageContainer } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { isNil } from "lodash/fp";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { IReport } from "services/resources/firebase/report/types";
import { reportResources } from "services/resources/firebase/report";
import { format } from "date-fns";
import { es } from "date-fns/locale/es";
import { AxiosError } from "axios";

export const Reports: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [reports, setReports] = useState<IReport[]>([]);

  const getReportAttachment = (report: IReport): ReactNode => {
    if (!report.fileType) {
      return <Text>El usuario no adjuntó evidencia</Text>;
    } else {
      switch (report.fileType) {
        case "image":
          return <Image src={report.attachment} w="70%" />;
        case "video":
          return <video controls src={report.attachment} width="70%" />;
        default:
          return <></>;
      }
    }
  };

  const handleOnRetrieveReports = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await reportResources.getAll(token as string);
      console.log(data);
      if (!isNil(data)) {
        if (data.length === 0) {
          setReports([]);
        } else {
          setReports(data);
        }
      }
    } catch (e) {
      console.error("Error-handleOnRetrieveReports: ", e);
      // handle deleting last existing report
      // TODO: from now on empty collections should not
      // return 404 but rather 204, since 404 is used when
      // the endpoint could not be reached.
      if ((e as AxiosError).response?.status === 404) setReports([]);
    }
    setLoadingModalVisible(false);
  };

  const handleOnDeleteReport = async (reportId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await reportResources.delete(reportId, token as string);
    } catch (e) {
      console.error("Error-handleOnDeleteReport: ", e);
      window.alert("Ocurrió un error al eliminar el reporte.");
    } finally {
      handleOnRetrieveReports();
      setLoadingModalVisible(false);
    }
  };

  const columns: ITypedListTableColumn<IReport>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => <Text>{row.original.id}</Text>,
    },
    {
      header: "Fecha",
      cell: ({ row }) => (
        <Text>
          {format(row.original.created._seconds * 1000, "mmmm dd, yyyy", {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      header: "Descripción",
      cell: ({ row }) => <Text>{row.original.description}</Text>,
    },
    {
      header: "Evidencia",
      cell: ({ row }) => <Text>{getReportAttachment(row.original)}</Text>,
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <HStack>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeleteReport(row.original.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </HStack>
      ),
    },
  ];

  useEffect(() => {
    handleOnRetrieveReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Reportes"
      loadingModalVisible={loadingModalVisible}
    >
      <Center bg="white" w="95%" h="95%" borderRadius={5}>
        {columns.length > 0 && reports.length > 0 ? (
          <DashboardTable
            data={reports}
            columns={columns as ColumnDef<object>[]}
            w="95%"
            h="95%"
            py={5}
          />
        ) : (
          <Text textAlign="center">No hay reportes</Text>
        )}
      </Center>
    </PageContainer>
  );
};
