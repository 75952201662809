import { Image as ChakraUIImage, VStack, Text } from "@chakra-ui/react";
import { IZubeModalProps, ZubeModal } from "components/elements";
import { useEffect, useState } from "react";
import { IDoubledSidedPicture } from "services/resources/firebase/driver-request/types.d";

interface ILicenseModalProps
  extends Omit<IZubeModalProps, "children" | "title"> {
  imageSources: IDoubledSidedPicture;
}

export const LicenseModal: React.FC<ILicenseModalProps> = (props) => {
  const { imageSources, ...rest } = props;

  const [shouldFrontRotate, setShouldFrontRotate] = useState<boolean>(false);
  const [shouldBackRotate, setShouldBackRotate] = useState<boolean>(false);

  useEffect(() => {
    const frontImg = new Image();
    const backImg = new Image();
    frontImg.src = imageSources.front;
    backImg.src = imageSources.back;
    frontImg.onload = () => {
      if (frontImg.height > frontImg.width) {
        setShouldFrontRotate(true);
      }
    };
    backImg.onload = () => {
      if (backImg.height > backImg.width) {
        setShouldBackRotate(true);
      }
    };
  }, [imageSources]);

  return (
    <ZubeModal
      contentStyle={{ minW: "80vw", maxW: "80vw" }}
      title="Licencia de conducir"
      isCentered={false}
      {...rest}
    >
      <VStack alignItems="center" w="100%" maxW="100%" p={5}>
        <Text fontWeight="bold">Frente</Text>
        <ChakraUIImage
          src={imageSources.front}
          transform={shouldFrontRotate ? "rotate(-90deg)" : "none"}
          maxW="70%"
          objectFit="contain"
          marginTop={shouldFrontRotate ? "-20%" : undefined}
        />
        <Text
          fontWeight="bold"
          marginTop={shouldFrontRotate ? "-20%" : undefined}
        >
          Reverso
        </Text>
        <ChakraUIImage
          src={imageSources.back}
          transform={shouldBackRotate ? "rotate(-90deg)" : "none"}
          maxW="70%"
          objectFit="contain"
          marginTop={shouldFrontRotate ? "-20%" : undefined}
        />
      </VStack>
    </ZubeModal>
  );
};
