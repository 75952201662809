import { Button, Text } from "@chakra-ui/react";
import { EmailField, PasswordField } from "components/inputs";
import { Formik } from "formik";
import { StackProps, VStack } from "@chakra-ui/react";
import React from "react";
import { type IBaseAuthentication } from "services/resources/firebase/auth/types.d";

import {
  INITIAL_VALUES,
  LoginFormValidationSchema,
} from "./helpers/form-helpers";
import { isEmpty } from "lodash/fp";
import { useNavigate } from "react-router-dom";
import { zubeColors } from "styles/colors";

interface ILoginFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (payload: IBaseAuthentication) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
}

export const LoginForm: React.FC<ILoginFormProps> = (props) => {
  const { handleOnSubmit, ...rest } = props;

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={LoginFormValidationSchema}
    >
      {({ errors, handleChange, isValid, handleSubmit, values }) => (
        <VStack {...rest}>
          <Text>Ingrese su correo y contraseña para iniciar sesión.</Text>
          <EmailField
            error={errors.email}
            onChangeText={handleChange("email")}
            label="Email:"
            w="100%"
          />
          <PasswordField
            error={errors.password}
            onChangeText={handleChange("password")}
            label="Contraseña:"
            w="100%"
          />
          <Text
            alignSelf="flex-start"
            fontSize={12}
            _hover={{ color: "#b5291f", cursor: "pointer" }}
            onClick={() => navigate("/password-reset")}
          >
            ¿Olvidaste tu contraseña?
          </Text>
          <Button
            bg={zubeColors.zubePurple.light}
            _hover={{ bg: zubeColors.zubePurple.dark }}
            isDisabled={
              !isEmpty(errors) ||
              isEmpty(values.email) ||
              isEmpty(values.password)
            }
            justifyContent="center"
            onClick={() => {
              if (isValid) handleSubmit();
            }}
            w="100%"
            color="white"
          >
            Iniciar Sesión
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

LoginForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
