import { ILocation } from "services/resources/firebase/journey/types.d";

export enum MapDriverTypeEnum {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  UNACTIVE = "UNACTIVE",
  IN_JOURNEY = "IN_JOURNEY",
}

export interface IMapSettings {
  center: ILocation;
  zoom: number;
}
