import { decode } from "base-64";
import fp from "lodash/fp";
import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import { FarePayloadType, type IFare, type VehicleType } from "./types.d";

const FARES_API_URL = "/faresAPI";

export const fareResources = {
  getById: async (
    id: string,
    token: string
  ): Promise<{ data: IFare | undefined }> => {
    let data;
    const { data: axiosData } = await firebaseInstance.get(
      `${FARES_API_URL}/by-id`,
      {
        headers: { Authorization: token },
        params: { id },
      }
    );
    if (!fp.isNil(axiosData)) data = JSON.parse(decode(axiosData));
    return { data };
  },

  getByVehicleType: async (
    vehicleType: VehicleType,
    token: string
  ): Promise<{ data: IFare | undefined }> => {
    let data;
    const { data: axiosData } = await firebaseInstance.get(
      `${FARES_API_URL}/by-vehicle-type`,
      {
        headers: { Authorization: token },
        params: { vehicleType },
      }
    );
    if (!fp.isNil(axiosData)) data = JSON.parse(decode(axiosData));
    return { data };
  },

  getCalculatedRideFare: (
    id: string,
    distanceInMeters: number,
    timeInMinutes: number,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.get(`${FARES_API_URL}/calculated-ride-fare`, {
      headers: { Authorization: token },
      params: { distanceInMeters, id, timeInMinutes },
    }),

  patchFare: (
    id: string,
    fare: Partial<FarePayloadType>,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${FARES_API_URL}`,
      { fare },
      {
        headers: { Authorization: token },
        params: { id },
      }
    ),
};
