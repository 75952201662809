import { isNil } from "lodash/fp";
import { notificationsResources } from "services/resources/firebase/notification";
import { NotificationPayloadType } from "services/resources/firebase/notification/types.d";
import { IUser } from "services/resources/firebase/user/types.d";

export const sendAppointmentNotification = async (
  driver: IUser,
  zubeAdminUserId: string,
  requestId: string,
  appointmentDate: string
): Promise<void> => {
  try {
    if (isNil(driver.expoDeviceId)) {
      throw new Error("Device id is required to send a notification");
    }
    const notification: NotificationPayloadType = {
      body: `Se ha programado una cita en el día y hora: ${appointmentDate} para continuar con tu proceso de solicitud.`,
      data: {
        collection: "driversRequests",
        resourceId: requestId,
        silent: false,
        target: driver.id,
        targetScreen: "driverVerification",
        triggeredBy: zubeAdminUserId,
      },
      priority: "high",
      sound: "default",
      title: "Cita confirmada",
      to: driver.expoDeviceId,
    };
    await notificationsResources.create(notification);
  } catch (e) {
    console.error(
      "sendAppointmentNotification: There was an issue sending appointment notification."
    );
  }
};
