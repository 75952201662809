import { IRequestWithDriver } from "services/resources-recipes/types.d";
import {
  DriverRequestDocumentTypeEnum,
  DriverTypeEnum,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { IDataItem } from "../types.d";

export const getDocTypeByOrder = (
  index: number
): { label: string; type: DriverRequestDocumentTypeEnum } => {
  switch (index) {
    case 0:
      return { label: "INE", type: DriverRequestDocumentTypeEnum.idCard };
    case 1:
      return {
        label: "Licencia de Manejo",
        type: DriverRequestDocumentTypeEnum.driverLicense,
      };
    case 2:
      return {
        label: "Póliza de Seguro",
        type: DriverRequestDocumentTypeEnum.insurance,
      };
    case 3:
      return {
        label: "Tarjeta de Circulación",
        type: DriverRequestDocumentTypeEnum.circulationCard,
      };
    case 4:
      return {
        label: "Tarjeta de Control",
        type: DriverRequestDocumentTypeEnum.controlCard,
      };
    // case 5 (vehicle form) is manually handled since grid item layout differs
    default:
      return { label: "", type: DriverRequestDocumentTypeEnum.idCard };
  }
};

export const getStatusLabel = (documentStatus: VerificationStatusEnum) => {
  switch (documentStatus) {
    case VerificationStatusEnum.PENDING:
      return "Pendiente";
    case VerificationStatusEnum.SUBMITTED:
      return "Pendiente";
    case VerificationStatusEnum.REJECTED:
      return "Rechazado";
    case VerificationStatusEnum.APPROVED:
      return "Aprobado";
    default:
      return "Selecciona el estatus";
  }
};

export const getDocList = (requestWithDriver: IRequestWithDriver) => {
  switch (requestWithDriver.request.driverType.field) {
    case DriverTypeEnum.PUBLIC:
      return [
        requestWithDriver.request.idCard,
        requestWithDriver.request.driverLicense,
        requestWithDriver.request.insurance,
        requestWithDriver.request.circulationCard,
        requestWithDriver.request.controlCard,
      ];
    case DriverTypeEnum.PRIVATE:
      return [
        requestWithDriver.request.idCard,
        requestWithDriver.request.driverLicense,
        requestWithDriver.request.insurance,
      ];
    default:
      return [];
  }
};

export const getVehicleData = (
  requestWithDriver: IRequestWithDriver
): IDataItem[] => {
  return [
    {
      label: "Modelo",
      value: requestWithDriver.request.vehicleInfo.form.model,
    },
    {
      label: "Fabricante",
      value: requestWithDriver.request.vehicleInfo.form.brand,
    },
    {
      label: "Año",
      value: requestWithDriver.request.vehicleInfo.form.year,
    },
    {
      label: "Placas",
      value: requestWithDriver.request.vehicleInfo.form.plates,
    },
  ];
};

export const getGeneralData = (
  requestWithDriver: IRequestWithDriver
): IDataItem[] => {
  return [
    {
      label: "Nombre",
      value: requestWithDriver.driver.fullName,
    },
    {
      label: "Nombre corto",
      value: "",
    },
    {
      label: "Email",
      value: requestWithDriver.driver.email,
    },
    {
      label: "Teléfono",
      value: requestWithDriver.driver.mobilePhoneNumber,
    },
    {
      label: "Términos y condiciones",
      value: "check",
    },
  ];
};
