import {
  Button,
  HStack,
  StackProps,
  Text,
  VStack,
  Select,
} from "@chakra-ui/react";
import { Formik } from "formik";
import {
  INITIAL_VALUES,
  FareFormValidationSchema,
} from "./helpers/form-helpers";
import { isEmpty } from "lodash/fp";
import { zubeColors } from "styles/colors";
import { InputField, MapRangeInputField } from "components/inputs";
import {
  FarePayloadType,
  FareType,
  IFare,
} from "services/resources/firebase/fare/types.d";
import { getFareTypeLabel } from "helpers/data-helpers/string-helpers";

interface IFareFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (payload: Partial<FarePayloadType>) => Promise<void>;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: IFare;
}

export const FareForm: React.FC<IFareFormProps> = (props) => {
  const { handleOnSubmit, previousValues, ...rest } = props;

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={FareFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        values,
        setFieldValue,
      }) => (
        <VStack
          alignItems="flex-start"
          bg="white"
          borderRadius="xl"
          p={5}
          {...rest}
        >
          <InputField
            error={errors.baseFare}
            label="Tarifa base*"
            onChangeText={handleChange("baseFare")}
            type="text"
            value={values.baseFare}
            description="Tarifa de donde se empieza a cobrar."
          />
          <InputField
            error={errors.minimumFare}
            label="Tarifa mínima *"
            onChangeText={handleChange("minimumFare")}
            type="text"
            value={values.minimumFare}
            description="El costo mínimo del viaje."
          />
          <InputField
            error={errors.appUsageFare}
            label="Tarifa de solicitud *"
            onChangeText={handleChange("appUsageFare")}
            type="text"
            value={values.appUsageFare}
            description="Se cobra por el uso de la aplicación."
          />
          <InputField
            error={errors.cancellationFare}
            label="Tarifa de cancelación *"
            onChangeText={handleChange("cancellationFare")}
            type="text"
            value={values.cancellationFare}
            description="En caso de cancelar el viaje se le queda guardado en el historial al pasajero."
          />
          <InputField
            error={errors.foreignKilometerFare}
            label="Tarifa por KM foráneo *"
            onChangeText={handleChange("foreignKilometerFare")}
            type="text"
            value={values.foreignKilometerFare}
            description="Costo del kilometro cuando la tarifa es foránea."
          />
          <MapRangeInputField
            error={errors.rangePerDistance}
            label="Rango de distancia *"
            onChangeText={handleChange("rangePerDistance")}
            type="text"
            value={values.rangePerDistance}
            description="Determina el número de METROS para cobrar la tarifa por distancia."
            colorHelper={zubeColors.zubeGreen}
          />
          <VStack pb={3} alignItems="flex-start">
            <Text fontSize={14} pb={3} fontWeight="bold">
              Tipo de tarifa*
            </Text>
            <Select
              mb={4}
              onChange={(e) => {
                setFieldValue("fareType", e.target.selectedOptions[0].value);
              }}
              placeholder="tipo de tarifa"
              value={values.fareType}
            >
              {["fixed", "foreign", "dynamic"].map((fareType) => (
                <option key={fareType} value={fareType}>
                  {getFareTypeLabel(fareType as FareType)}
                </option>
              ))}
            </Select>
            <Text fontSize={12} color="gray">
              Determina la forma en que se calcula la tarifa.
            </Text>
          </VStack>
          <InputField
            error={errors.distanceRate}
            label="Tarifa por rango de distancia *"
            onChangeText={handleChange("distanceRate")}
            type="text"
            value={values.distanceRate}
            description="Costo por cada rango de distancia."
          />
          <InputField
            error={errors.farePerMinute}
            label="Tarifa por minuto *"
            onChangeText={handleChange("farePerMinute")}
            type="text"
            value={values.farePerMinute}
            description="Costo por cada minuto registrado."
          />
          <MapRangeInputField
            error={errors.minimumRangeForSendingNotifications}
            label="Rango mínimo de busqueda de conductores *"
            onChangeText={handleChange("minimumRangeForSendingNotifications")}
            type="text"
            value={values.minimumRangeForSendingNotifications}
            description="Determina el rango mínimo para empezar buscar conductores cerca del punto de partida."
            colorHelper={zubeColors.zubeOrange.dark}
          />
          <MapRangeInputField
            error={errors.maximumRangeForSendingNotifications}
            label="Rango máximo de busqueda de conductores *"
            onChangeText={handleChange("maximumRangeForSendingNotifications")}
            type="text"
            value={values.maximumRangeForSendingNotifications}
            description="Determina el rango máximo en el que se deben buscar conductores alrededor del punto de partida."
            colorHelper={zubeColors.zubeOrange.light}
          />
          <InputField
            error={errors.amountOfDriversToBeNotified}
            label="Conductores a notificar *"
            onChangeText={handleChange("amountOfDriversToBeNotified")}
            type="text"
            value={values.amountOfDriversToBeNotified}
            description="Determina a cuantos conductores como mínimo se debe buscar para notificar de un nuevo viaje dentro de cada rango."
          />
          <MapRangeInputField
            error={errors.searchDriversRange}
            label="Intervalo de busqueda de conductores *"
            onChangeText={handleChange("searchDriversRange")}
            type="text"
            value={values.searchDriversRange}
            description="Determina cuantos KM se aumenta al siguiente rango de búsqueda."
            colorHelper={zubeColors.zubePurple.light}
          />
          <InputField
            error={errors.newSearchTime}
            label="Segundos de Intervalo de busqueda de conductores *"
            onChangeText={handleChange("newSearchTime")}
            type="text"
            value={values.newSearchTime}
            description="Determina cuantos segundos debe esperar la app antes de volver a notificar a los conductores del nuevo viaje."
          />
          <HStack w="90%" mb={5}>
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={
                !isEmpty(errors) ||
                !isValid ||
                JSON.stringify(previousValues) === JSON.stringify(values)
              }
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="100%"
              color="white"
            >
              Guardar
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};

FareForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
