import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale/es";

registerLocale("es", es);

interface IDatePickerProps {
  onSelectDate: (d: Date | null) => void;
  selected: Date;
}

export const DateTimePicker: React.FC<IDatePickerProps> = (
  props
): JSX.Element => {
  const { onSelectDate, selected, ...rest } = props;
  return (
    <DatePicker
      dateFormat="dd MMM yyyy"
      locale="es"
      onChange={onSelectDate}
      selected={selected}
      {...rest}
    />
  );
};
