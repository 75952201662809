import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import {
  IAutocompleteLocationResponse,
  IPlaceDetailsResponse,
} from "./types.d";

const GOOGLE_MAPS_API_URL = "/googleMapsAPI";

export const googleMapsResources = {
  getPlaceAutocompletePredictions: async (
    encodedInput: string,
    token: string
  ): Promise<IAxiosResponse<IAutocompleteLocationResponse>> =>
    firebaseInstance.get(`${GOOGLE_MAPS_API_URL}/predict-autocomplete`, {
      headers: { Authorization: token },
      params: { input: encodedInput },
    }),

  getPlaceLocation: (
    placeId: string,
    token: string
  ): Promise<IAxiosResponse<IPlaceDetailsResponse>> =>
    firebaseInstance.get(`${GOOGLE_MAPS_API_URL}/place-location`, {
      headers: { Authorization: token },
      params: { placeId },
    }),
};
