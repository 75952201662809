import { HStack, Select, StackProps, Text, VStack } from "@chakra-ui/react";
import { InputField } from "components/inputs";
import { FormikErrors } from "formik";
import {
  CarBrandEnum,
  getBrandModels,
} from "helpers/constants/car-brands-constants";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { UnitPayloadType } from "services/resources/firebase/unit/types.d";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";

interface IUnitDataProps extends StackProps {
  unitData: Partial<UnitPayloadType>;
  /**
   * Set value of form field directly
   */
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<UnitPayloadType>>;
  partners: IPartner[];
}

export const UnitData: React.FC<IUnitDataProps> = (props): JSX.Element => {
  const { unitData, setFieldValue, partners, ...rest } = props;

  const { isAdmin: isDbAdmin, type } = useSelector(getUser);

  const isAdmin = isDbAdmin || type === UserTypeEnum.ADMIN;

  const getLastTenYears = (): number[] => {
    const currentYear = new Date().getFullYear();
    const years = [currentYear];
    for (let i = 1; i < 11; i++) {
      years.push(currentYear - i);
    }
    return years;
  };

  return (
    <HStack
      alignItems="flex-start"
      bg="white"
      p={5}
      borderRadius={10}
      w="100%"
      {...rest}
    >
      <VStack alignItems="flex-start" w="50%">
        <Text fontWeight="bold" pl={1}>
          Número de unidad
        </Text>
        <InputField
          onChangeText={(text) => setFieldValue("clientUnitId", text)}
          value={unitData.clientUnitId}
        />
        <Text fontWeight="bold" pl={1}>
          Modelo
        </Text>
        <Select
          mb={4}
          onChange={(e) => {
            setFieldValue("model", e.target.selectedOptions[0].value);
          }}
          placeholder={unitData.model}
          value={unitData.model}
        >
          {getBrandModels(unitData.brand as CarBrandEnum).map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </Select>
        <Text fontWeight="bold" pl={1}>
          Año
        </Text>
        <Select
          mb={4}
          onChange={(e) => {
            setFieldValue("year", Number(e.target.selectedOptions[0].value));
          }}
          placeholder={unitData.year?.toString()}
          value={unitData.year}
        >
          {getLastTenYears().map((year) => (
            <option key={year} value={`${year}`}>
              {`${year}`}
            </option>
          ))}
        </Select>
      </VStack>
      <VStack alignItems="flex-start" w="50%">
        <Text fontWeight="bold" pl={1}>
          Fabricante
        </Text>
        <Select
          mb={4}
          onChange={(e) => {
            setFieldValue("brand", e.target.selectedOptions[0].value);
          }}
          placeholder={unitData.brand}
          value={unitData.brand}
        >
          {Object.values(CarBrandEnum).map((brand) => (
            <option key={brand} value={brand}>
              {brand}
            </option>
          ))}
        </Select>
        <Text fontWeight="bold" pl={1}>
          Placas
        </Text>
        <InputField
          onChangeText={(text) => setFieldValue("plates", text)}
          value={unitData.plates}
        />
        {isAdmin ? (
          <>
            <Text alignSelf="flex-start" fontSize={14} fontWeight="bold">
              Organización
            </Text>
            <Select
              mb={4}
              onChange={(e) => {
                setFieldValue("partnerId", e.target.selectedOptions[0].value);
              }}
              placeholder="Selecciona una organización"
              value={unitData.partnerId}
            >
              {partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name}
                </option>
              ))}
            </Select>
          </>
        ) : null}
      </VStack>
    </HStack>
  );
};
