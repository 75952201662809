import { Circle, HStack, StackProps, Text } from "@chakra-ui/react";

interface IDriverTypeButtonProps extends StackProps {
  circleColor: string;
  label: string;
  count: number;
}

export const DriverTypeButton: React.FC<IDriverTypeButtonProps> = (props) => {
  const { circleColor, label, count, ...rest } = props;

  return (
    <HStack alignItems="center" _hover={{ cursor: "pointer" }} {...rest}>
      <Circle size="20px" bg={circleColor} />
      <Text fontWeight="semibold">{label}</Text>
      <Text fontWeight="semibold">{`(${count})`}</Text>
    </HStack>
  );
};
