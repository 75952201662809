import { numberRegex } from "helpers/data-helpers/regex-helpers";
import { object, string } from "yup";

export const PlanFormValidationSchema = object().shape({
  price: string()
    .matches(numberRegex, "Ingresa un valor numérico.")
    .required("Este campo es requerido."),
  name: string().required("Este campo es requerido."),
  units: string()
    .matches(numberRegex, "Ingresa un valor numérico.")
    .required("Este campo es requerido."),
});

export const INITIAL_VALUES = {
  price: 0,
  name: "",
  units: 0,
};
