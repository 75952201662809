import { Button, Image, StackProps, Text, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { isEmpty } from "lodash/fp";
import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { zubeColors } from "styles/colors";
import { FileField, InputField } from "components/inputs";
import {
  IUserSignupPayload,
  UserTypeEnum,
} from "services/resources/firebase/user/types.d";
import {
  INITIAL_VALUES,
  ProfileFormValidationSchema,
} from "./helpers/form-helpers";

interface IProfileFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (
    payload: Pick<
      IUserSignupPayload,
      "email" | "fullName" | "img" | "password" | "type"
    >
  ) => Promise<void>;
  /**
   * Initial values for the form.
   */
  previousValues: Pick<
    IUserSignupPayload,
    "email" | "fullName" | "img" | "password" | "type"
  >;
}

export const ProfileForm: React.FC<IProfileFormProps> = (props) => {
  const { handleOnSubmit, previousValues, ...rest } = props;
  const [newPassword, setNewPassword] = useState<string>("");

  const getRole = (role: UserTypeEnum): string => {
    switch (role) {
      case UserTypeEnum.ADMIN:
        return "Administrador";
      case UserTypeEnum.PARTNER:
        return "Partner";
      default:
        return "";
    }
  };

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={ProfileFormValidationSchema(!isEmpty(newPassword))}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <VStack {...rest}>
          <InputField
            error={errors.fullName}
            label="Nombre"
            onChangeText={handleChange("fullName")}
            type="text"
            value={values.fullName}
          />
          <InputField
            error={errors.email}
            label="Correo electrónico"
            onChangeText={handleChange("email")}
            type="email"
            value={values.email}
          />
          <InputField
            error={errors.password}
            label="Contraseña"
            onChangeText={(t) => {
              setNewPassword(t);
              setFieldValue("password", t);
            }}
            type="password"
            value={values.password}
          />
          <InputField
            isDisabled
            label="Rol"
            onChangeText={() => {}}
            value={getRole(values.type)}
          />
          <Text
            alignSelf="flex-start"
            display="flex"
            fontSize={14}
            fontWeight="bold"
            gap={3}
          >
            Imagen de perfil
          </Text>
          <FileField
            id="profile-picture"
            onFileSelect={(uri) => setFieldValue("img", uri)}
          />
          {!isEmpty(values.img) ? (
            <Image maxW={60} my={5} src={values.img} />
          ) : (
            <CgProfile size={100} />
          )}
          <Button
            alignSelf="flex-end"
            bg={zubeColors.zubeGreen}
            borderRadius="full"
            isDisabled={!isValid}
            justifyContent="center"
            mb={5}
            onClick={() => {
              if (isValid) handleSubmit();
            }}
            w="30%"
            color="white"
          >
            Guardar
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

ProfileForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
