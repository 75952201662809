import { Circle, Divider, StackProps, VStack } from "@chakra-ui/react";

interface IDriverLineWithCircleProps extends StackProps {
  circleColor: string;
}

export const DriverLineWithCircle: React.FC<IDriverLineWithCircleProps> = (
  props
): JSX.Element => {
  const { circleColor, ...rest } = props;

  return (
    <VStack spacing={0} alignItems="center" {...rest}>
      <Circle size='10px' bg={circleColor} />
      <Divider orientation="vertical" w="1px" bg="gray.500" />
    </VStack>
  );
};
